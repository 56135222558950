import { useRecordContext, useResourceContext } from 'react-admin';
import { entityNames } from '../../activity/constants';
import { RESOURCE_ACTIVITY } from '../../constants';

const useResourceTitleClarification = (): string => {
  const resource = useResourceContext();
  const record = useRecordContext();
  if (!record || !resource) return '';

  switch (resource) {
    case RESOURCE_ACTIVITY: {
      const { category } = record;
      {
        return entityNames[category] || '';
      }
    }
    default:
      return '';
  }
};

export default useResourceTitleClarification;
