import React, { Fragment, ReactElement } from 'react';
import { BooleanInput, usePermissions } from 'react-admin';
import AssignedWithCreateInput from '../common/Assigned/AssignedWithCreateInput';
import {
  hasFieldEditAccess,
  useDictionaryLabelsWithResource,
} from '../../utils/UtilityFunctions';
import PreservationSeriesInput from '../common/PreservationSeriesInput';
import { ContactCategories } from '../common/contactInput/constants';
import PreservationItemInput from '../common/PreservationItemInput';
import ContactInput from '../common/contactInput/ContactInput';
import CustomNumberInput from '../common/CustomNumberInput';
import CustomDateInput from '../common/CustomDateInput';
import ComponentInput from '../common/ComponentInput';
import SignatureInput from '../common/SignatureInput';
import LocationInput from '../common/LocationInput';
import commonStyles from '../common/commonStyles';
import TestInput from '../common/TestInput';
import TextInput from '../common/TextInput';
import { RESOURCE_PRESERVATION_ITEM_ACTIVITY } from '../constants';
import BoxWithCheckAccess from '../common/BoxWithCheckAccess';
import CompletedInput from './components/CompletedInput';
import { getValidateRequiredFunc } from './validators';

export interface PreservationActivityFormType {
  disableFieldsWithoutPermissionRules?: boolean;
}

const PreservationActivityForm: React.FC<PreservationActivityFormType> = ({
  disableFieldsWithoutPermissionRules = false,
}): ReactElement => {
  const { labels } = useDictionaryLabelsWithResource();
  const { permissions } = usePermissions();

  return (
    <Fragment>
      <BoxWithCheckAccess sx={commonStyles.flexBox}>
        <PreservationItemInput
          sx={commonStyles.flexBoxItem}
          label={labels['preservationItemId']}
          disabled
        />
        <PreservationSeriesInput
          sx={commonStyles.flexBoxItem}
          label={labels['preservationSeriesId']}
          disabled
        />
        <TestInput
          sx={commonStyles.flexBoxItem}
          label={labels['testId']}
          name="testFormNo_inc"
          optionText="testFormNo"
          disabled
        />
      </BoxWithCheckAccess>
      <BoxWithCheckAccess sx={commonStyles.flexBox}>
        <ComponentInput sx={commonStyles.flexBoxItem} disabled />
        <LocationInput
          sx={commonStyles.flexBoxItem}
          label={labels['locationId']}
          disabled
        />
        <BooleanInput
          source="isClosed"
          label={labels['isClosed'] || 'Closed'}
          defaultValue={false}
          sx={commonStyles.flexBoxItem}
        />
      </BoxWithCheckAccess>
      <BoxWithCheckAccess sx={commonStyles.flexBox}>
        <TextInput
          source="sequence"
          label={labels['sequence'] || 'Sequence'}
          sx={commonStyles.flexBoxItem}
          disabled
        />
        <CustomNumberInput
          source="estimatedManHours"
          label={labels['estimatedManHours'] || 'Estimated Man Hours'}
          sx={commonStyles.flexBoxItem}
          disabled
        />
        <CustomDateInput
          source="onSiteDate"
          label={labels['onSiteDate'] || 'On Site Date'}
          sx={commonStyles.flexBoxItem}
          disabled
        />
        <BooleanInput
          source="isMobile"
          label={labels['isMobile'] || 'Mobile'}
          defaultValue={false}
          sx={commonStyles.flexBoxItem}
          readOnly={disableFieldsWithoutPermissionRules}
        />
      </BoxWithCheckAccess>
      <BoxWithCheckAccess sx={commonStyles.flexBox}>
        <AssignedWithCreateInput
          sx={commonStyles.flexBoxItem}
          category={ContactCategories.preservationEmpWorkGrp}
          label={labels['assigned'] || 'Responsible'}
          disabled={disableFieldsWithoutPermissionRules}
        />
        <CustomNumberInput
          source="actualManHours"
          label={labels['actualManHours'] || 'Actual Man Hours'}
          sx={commonStyles.flexBoxItem}
          readOnly={disableFieldsWithoutPermissionRules}
        />
        <CustomNumberInput
          source="adminManHours"
          label={labels['adminManHours'] || 'Admin Man Hours'}
          sx={commonStyles.flexBoxItem}
          readOnly={disableFieldsWithoutPermissionRules}
        />
      </BoxWithCheckAccess>
      <BoxWithCheckAccess sx={commonStyles.flexBox}>
        <SignatureInput
          source="inspector"
          label={labels['inspector'] || 'Inspector: Signature'}
          sx={[commonStyles.flexBoxItem, commonStyles.bottomMargin]}
        >
          <ContactInput
            source="inspector"
            label={labels['inspector'] || 'Inspector: Signature'}
            sx={commonStyles.flexBoxItem}
            category={ContactCategories.preservationEmpWorkGrp}
            readOnly={
              !hasFieldEditAccess(
                permissions,
                `${RESOURCE_PRESERVATION_ITEM_ACTIVITY}.inspector`
              )
            }
          />
        </SignatureInput>
        <CustomDateInput
          source="signOffDate"
          label={labels['signOffDate'] || 'Sign Off Date'}
          sx={commonStyles.flexBoxItem}
        />
        <CompletedInput
          source="completed"
          label={labels['completed'] || 'Status'}
          sx={commonStyles.flexBoxItem}
        />
      </BoxWithCheckAccess>
      <BoxWithCheckAccess sx={commonStyles.flexBox}>
        <SignatureInput
          source="verifiedBy"
          label={labels['verifiedBy'] || 'Verified By: Signature'}
          sx={[commonStyles.flexBoxItem, commonStyles.bottomMargin]}
          disabled={
            !hasFieldEditAccess(
              permissions,
              `${RESOURCE_PRESERVATION_ITEM_ACTIVITY}.verifiedBy`
            )
          }
        >
          <ContactInput
            source="verifiedBy"
            label={labels['verifiedBy'] || 'Verified By: Signature'}
            sx={commonStyles.flexBoxItem}
            validate={[
              getValidateRequiredFunc('verifiedDate', 'Verified By: Date'),
            ]}
            category={ContactCategories.preservationEmpWorkGrp}
          />
        </SignatureInput>
        <CustomDateInput
          source="verifiedDate"
          label={labels['verifiedDate'] || 'Verified By: Date'}
          sx={commonStyles.flexBoxItem}
          validate={[
            getValidateRequiredFunc('verifiedBy', 'Verified By: Signature'),
          ]}
        />
      </BoxWithCheckAccess>
      <BoxWithCheckAccess sx={commonStyles.createBoxHalfWidth}>
        <TextInput
          source="reviewComments"
          label={labels['reviewComments'] || 'Review Comments'}
          fullWidth
          multiline
          readOnly={disableFieldsWithoutPermissionRules}
        />
      </BoxWithCheckAccess>
      <BoxWithCheckAccess sx={commonStyles.flexBox}>
        <CustomDateInput
          source="startDate"
          label={labels['startDate'] || 'Start Date'}
          sx={commonStyles.flexBoxItem}
          disabled
        />
        <CustomDateInput
          source="endDate"
          label={labels['endDate'] || 'End Date'}
          sx={commonStyles.flexBoxItem}
          disabled
        />
        <CustomNumberInput
          source="frequency"
          label={labels['frequency'] || 'Frequency'}
          sx={commonStyles.flexBoxItem}
          disabled
        />
      </BoxWithCheckAccess>
      <BoxWithCheckAccess sx={commonStyles.flexBox}>
        <CustomDateInput
          source="dueDate"
          label={labels['dueDate'] || 'Due Date'}
          sx={commonStyles.flexBoxItem}
          disabled
        />
        <CustomDateInput
          source="lateDate"
          label={labels['lateDate'] || 'Late Date'}
          sx={commonStyles.flexBoxItem}
          disabled
        />
        <CustomNumberInput
          source="plusDate"
          label={labels['plusDate'] || 'Plus Date'}
          sx={commonStyles.flexBoxItem}
          disabled
        />
      </BoxWithCheckAccess>
      <BoxWithCheckAccess sx={commonStyles.flexBox}>
        <TextInput
          source="printedBy"
          label={labels['printedBy'] || 'Printed By'}
          sx={commonStyles.flexBoxItem}
          disabled
        />
        <CustomDateInput
          source="printDate"
          label={labels['printDate'] || 'Printed Date'}
          sx={commonStyles.flexBoxItem}
          disabled
        />
      </BoxWithCheckAccess>
      <BoxWithCheckAccess sx={commonStyles.createBoxHalfWidth}>
        <TextInput
          source="storagePreservation"
          label={labels['storagePreservation'] || 'Storage Preservation'}
          fullWidth
          multiline
          disabled
        />
      </BoxWithCheckAccess>
    </Fragment>
  );
};

export default PreservationActivityForm;
