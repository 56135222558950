import React, { ReactElement, Fragment } from 'react';
import { TextField, useRecordContext } from 'react-admin';
import { SxProps, Theme } from '@mui/material/styles';
import AutocompleteReferenceInput from '../../common/AutocompleteReferenceInput';
import SmartLinkField from '../../common/SmartLinkField';
import { RESOURCE_ACTIVITY } from '../../constants';
import { FieldPropsWithOptionalSource } from '../../common/types';

interface ActivityFieldProps extends FieldPropsWithOptionalSource {
  label: string;
  sx?: SxProps<Theme>;
  isInput?: boolean;
  disabled?: boolean;
  alwaysOn?: boolean;
  source?: string;
}

const ActivityField: React.FC<ActivityFieldProps> = ({
  label,
  sx,
  isInput,
  ...rest
}): ReactElement => {
  const record = useRecordContext();
  let resource, source, sortBy, optionText;
  if (record?.activityId) {
    resource = RESOURCE_ACTIVITY;
    source = 'activityId';
    sortBy = 'activity.activity';
    optionText = 'activity';
  }

  return (
    <Fragment>
      {resource &&
        (isInput ? (
          <AutocompleteReferenceInput
            {...rest}
            resource={resource}
            source={source}
            name={`${optionText}_inc`}
            optionText={optionText}
            sx={sx}
            includeFields={['id', optionText]}
          />
        ) : (
          <SmartLinkField
            {...rest}
            label={label}
            source={source}
            resource={resource}
            sortBy={sortBy}
          >
            <TextField source={sortBy} sx={sx} />
          </SmartLinkField>
        ))}
    </Fragment>
  );
};

export default ActivityField;
