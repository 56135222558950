import dayjs from 'dayjs';
import isEmptyObject from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import { useEffect, useMemo } from 'react';
import {
  required as requiredRA,
  useResourceContext,
  useStore,
} from 'react-admin';
import { canAccess, Permission } from '@react-admin/ra-rbac';
import { LOCAL_STORE_VERSION } from '../AppContent';
import getUISettings from '../components/common/helpers/getUISettings';
import { api } from '../provider/api';
import {
  DictionaryContext,
  getDictionaryContext,
  setDictionaryContext,
} from '../provider/dictionaryContext';
import { getContext, UserContext } from '../provider/userContext';
import { FieldPermissionActions } from '../components/common/constants';

const development =
  !process.env.NODE_ENV || process.env.NODE_ENV === 'development';

export function isDev() {
  return development;
}

export function sleep(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export function tryJsonParse(value: string) {
  try {
    return JSON.parse(value);
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log('tryJsonParse failed.', e);
    return value;
  }
}

export function capitalizeFirstLetter(str: string) {
  return isEmpty(str) ? '' : str.charAt(0).toUpperCase() + str.slice(1);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isEmpty(value: any) {
  return value === null || value === undefined || String(value).length === 0;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isNotEmpty(value: any) {
  return !isEmpty(value);
}

export function truncateLongMessage(
  msg: string,
  maxLength: number,
  postfix = '...'
) {
  if (isEmpty(msg)) return '';

  if (msg.length >= maxLength) {
    return `${msg.substring(0, maxLength - 3)}${postfix}`;
  }

  return msg;
}

export const getLocale = () => Intl.DateTimeFormat().resolvedOptions().locale;

export const getTimeZone = () =>
  Intl.DateTimeFormat().resolvedOptions().timeZone;

export const getTimestamp = () => dayjs().format('YYYY-MM-DD-HH-mm-ss');

export const downloadFile = (
  resourceName: string,
  data: BlobPart,
  contentType: string,
  fileExtension = 'xlsx',
  addTimestamp = true
) => {
  const blob = new Blob([data], {
    type: contentType,
  });
  const link = document.createElement('a');
  link.href = window.URL.createObjectURL(blob);
  link.download = `${resourceName}${
    addTimestamp ? '_' + getTimestamp() : ''
  }.${fileExtension}`;
  link.click();
};

export const downloadFileByLink = (link: string) => {
  const a = document.createElement('a');
  a.target = '_blank';
  a.href = link;
  a.target = 'download-frame';
  a.click();
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function getErrorMessage(err: any, defaultErrorMsg = '') {
  let msg = '';

  if (typeof err === 'string') {
    msg = err;
  } else if (
    err?.response?.data?.message ||
    err?.response?.data?.error ||
    err?.message
  ) {
    msg =
      err?.response?.data?.message ||
      err?.response?.data?.error ||
      err?.message?.title ||
      err?.message;
  } else {
    msg = defaultErrorMsg || '' + err;
  }

  // eslint-disable-next-line no-console
  console.error(err);
  return msg.slice(0, 100);
}

const skipLabelUpdate = (resource, item, dict) =>
  resource === 'punch' &&
  item.source?.startsWith('cfText') &&
  !dict[item.source]?.label;

export const useDictionaryLabelsWithResource = (_resource?: string) => {
  const resource: string = useResourceContext({ resource: _resource });
  const ctx: DictionaryContext = getDictionaryContext();
  const dict = useMemo(() => ctx?.[resource] || {}, [ctx, resource]);
  const preferenceKey = `${resource}.dict`;
  const newColumns = useMemo(() => [], []);

  const [availableColumns] = useStore(
    `preferences.${resource}.datagrid.availableColumns`,
    []
  );

  const [, setCustomAvailableColumns] = useStore(
    `preferences.${preferenceKey}.availableColumns`,
    []
  );

  const [columns] = useStore(`preferences.${resource}.datagrid.columns`);

  const customColumns = useMemo(
    () =>
      Object.keys(dict).map((key, index) => ({
        index: String(availableColumns.length + index + 1),
        label: dict[key].label,
        source: key,
      })),
    [dict, availableColumns]
  );

  const labels = useMemo(() => {
    return [...availableColumns, ...customColumns].reduce(
      (prev, item, index) => {
        const label = dict[item.source]?.label || item.label;
        newColumns[index] = {
          ...item,
          label,
        };

        return skipLabelUpdate(resource, item, dict)
          ? { ...prev }
          : {
              ...prev,
              [item.source]: label,
            };
      },
      {}
    );
  }, [availableColumns, customColumns, dict, newColumns, resource]);

  useEffect(() => {
    if (!isEqual(availableColumns, newColumns)) {
      setCustomAvailableColumns(newColumns);
    }
  }, [newColumns, availableColumns, setCustomAvailableColumns]);

  useEffect(() => {
    if (isEmptyObject(dict)) {
      localStorage.removeItem(`RaStore.preferences.${preferenceKey}.columns`);
      localStorage.removeItem(`RaStore.preferences.${preferenceKey}.omit`);
      localStorage.removeItem(
        `RaStore.preferences.${preferenceKey}.availableColumns`
      );

      if (columns && columns.length === 0) {
        localStorage.removeItem(
          `RaStore.preferences.${resource}.datagrid.columns`
        );
      }
    }
  }, [columns, dict, preferenceKey, resource]);

  return {
    labels,
    preferenceKey:
      !isEqual(availableColumns, newColumns) && !isEmptyObject(dict)
        ? preferenceKey
        : `${resource}.datagrid`,
  };
};

export async function updateDictionaryContext() {
  try {
    const ctx: UserContext = getContext();
    const response = await api.dictionary.getJson(ctx.defaultDivisionId);

    if (response.data) {
      setDictionaryContext(response.data);
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
  }
}

export const getLocaleDateFormat = () => {
  const LOCALE_DIGIT_0 = Intl.NumberFormat().format(0);
  const LOCALE_DIGIT_1 = Intl.NumberFormat().format(1);
  const LOCALE_DIGIT_2 = Intl.NumberFormat().format(2);
  const DECODING_OBJECT = {
    YYYY: `${LOCALE_DIGIT_2}${LOCALE_DIGIT_1}${LOCALE_DIGIT_2}${LOCALE_DIGIT_2}`,
    YY: `${LOCALE_DIGIT_2}${LOCALE_DIGIT_2}`,
    MM: `${LOCALE_DIGIT_0}${LOCALE_DIGIT_1}`,
    M: `${LOCALE_DIGIT_1}`,
    DD: `${LOCALE_DIGIT_0}${LOCALE_DIGIT_2}`,
    D: `${LOCALE_DIGIT_2}`,
  };

  let dateFormat = new Date(2122, 0, 2).toLocaleDateString();

  for (const key in DECODING_OBJECT) {
    dateFormat = dateFormat.replace(DECODING_OBJECT[key], key);
  }

  return dateFormat;
};

export function clearLocalStorageSettings() {
  const keysToRemove = Object.keys(localStorage).filter((key) =>
    key.startsWith('RaStore.')
  );

  for (const key in keysToRemove) {
    localStorage.removeItem(keysToRemove[key]);
  }

  setTimeout(() => window.location.reload(), 200);
}

export function clearLocalStorageListParamsFilter() {
  for (const key of Object.keys(localStorage)) {
    if (key.endsWith('listParams')) {
      localStorage.removeItem(key);
    }
  }
}

export function clearLocalStorageSelectedIds() {
  for (const key of Object.keys(localStorage)) {
    if (key.endsWith('selectedIds')) {
      localStorage.removeItem(key);
    }
  }
}

export function updateLocalStorageListParamsFilter(
  filterName: string,
  value: string
) {
  const keysToUpdate = Object.keys(localStorage).filter((key) =>
    key.endsWith('listParams')
  );

  for (const key in keysToUpdate) {
    const item = JSON.parse(localStorage.getItem(keysToUpdate[key]));

    if (item?.filter?.[filterName]) {
      item.filter[filterName] = value;
      localStorage.setItem(keysToUpdate[key], JSON.stringify(item));
    }
  }

  const search = new URLSearchParams(window.location.search);

  if (search.has('filter')) {
    const filter = JSON.parse(search.get('filter'));

    if (filter[filterName]) {
      window.location.href = window.location.pathname;
      return;
    }
  }

  setTimeout(() => window.location.reload(), 200);
}

export const required = (message = 'ra.validation.required') =>
  Object.assign(
    (value, values) => {
      if (typeof value === 'string') {
        value = value.trim();
      }

      return requiredRA(message)(value, values);
    },
    { isRequired: true }
  );

export const atLeastOneRequired =
  (sources: string[], message = 'ra.validation.required') =>
  (value, values) => {
    if (
      sources.every((source) =>
        isEmpty(
          typeof values[source] === 'string'
            ? values[source].trim()
            : values[source]
        )
      )
    ) {
      return message;
    }
    return undefined;
  };

export const integer =
  (message = 'Must be an integer') =>
  (value) =>
    !isEmpty(value) && !Number.isInteger(value) ? message : undefined;

export const updateLocalUISettings = (
  settings: string,
  skipVersionCheck?: boolean
) => {
  try {
    const uiSettings = JSON.parse(settings);
    const currentVersion = LOCAL_STORE_VERSION;
    const storedVersion = uiSettings['RaStore.version'];

    if (skipVersionCheck || currentVersion === storedVersion) {
      const currentUISettings = getUISettings();

      if (!isEmptyObject(currentUISettings)) {
        for (const key of Object.keys(currentUISettings)) {
          if (!key.endsWith('version') && !key.endsWith('autoSaveEnabled')) {
            localStorage.removeItem(key);
          }
        }
      }

      for (const key of Object.keys(uiSettings)) {
        localStorage.setItem(key, uiSettings[key]);
      }
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(getErrorMessage(error, 'Update UI settings error'));
  }
};

export const removeNullObjectValues = (object: object) => {
  if (object && typeof object === 'object') {
    Object.keys(object).forEach((key) => {
      object[key] === null
        ? delete object[key]
        : removeNullObjectValues(object[key]);
    });
  }
};

export const removeEmptyObjects = (object: object) => {
  if (object && typeof object === 'object') {
    Object.keys(object).forEach((key) => {
      if (typeof object[key] === 'object') {
        removeEmptyObjects(object[key]);

        if (Object.keys(object[key]).length === 0) {
          delete object[key];
        }
      }
    });
  }
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function ensureArray(value: any): any[] {
  if (isEmpty(value)) {
    return [];
  }

  return Array.isArray(value) ? value : [value];
}

export function compareObjects(obj1, obj2) {
  if (obj2 && typeof obj2 === 'object') {
    if (obj1 && typeof obj1 === 'object') {
      Object.keys(obj2).forEach((key) => {
        if (obj1.hasOwnProperty(key)) {
          obj1[key] = compareObjects(obj1[key], obj2[key]);
        } else {
          obj1[key] = obj2[key];
        }
      });
    }
  } else {
    return isEqual(obj1, obj2) ? obj1 : null;
  }

  return obj1;
}

export function getLocalStorageItem(key: string): string | null {
  const item = localStorage.getItem(key);

  if (isEmpty(item)) return null;

  const itemData = tryJsonParse(item);

  if (isEmpty(itemData)) return null;

  if (itemData.exp > 0 && Date.now() >= itemData.exp) {
    localStorage.removeItem(key);
    return null;
  }

  return itemData.value;
}

export function popLocalStorageItem(key: string): string {
  const value = getLocalStorageItem(key);
  localStorage.removeItem(key);
  return value;
}

export function setLocalStorageItem(
  key: string,
  value: string,
  expiresInSec?: number
) {
  const itemData = { value, exp: 0 };

  if (isNotEmpty(expiresInSec) && expiresInSec > 0) {
    const now = new Date();
    itemData.exp = now.setSeconds(now.getSeconds() + expiresInSec);
  }

  localStorage.setItem(key, JSON.stringify(itemData));
}

export const isImage = (str) => {
  return str?.includes('data:image/');
};

export const isSignatureField = (key: string) => {
  return key.match(/^(sigc|sigreview|sig(\d*)_signature)*/g)[0].length > 0;
};

export const isPrintNameField = (key: string) => {
  return (
    key.match(/^(CompletedByPrintName|pnreview|sig(\d*)_name)*/g)[0].length > 0
  );
};

export const isDateField = (key: string) => {
  return key.match(/^(CompletedByDate|dreview|sig(\d*)_date)*/g)[0].length > 0;
};

export const emptyToZero = (val) => (isEmpty(val) ? 0 : val);

export const hasFieldEditAccess = (
  permissions: Permission[],
  fieldResource: string
) =>
  canAccess({
    permissions,
    action: FieldPermissionActions.edit,
    resource: fieldResource,
  });

export const hasFieldShowAccess = (
  permissions: Permission[],
  fieldResource: string
) =>
  canAccess({
    permissions,
    action: FieldPermissionActions.show,
    resource: fieldResource,
  });

export const hasEditOrShowFieldAccess = (
  permissions: Permission[],
  fieldResource: string
) =>
  hasFieldEditAccess(permissions, fieldResource) ||
  hasFieldShowAccess(permissions, fieldResource);
