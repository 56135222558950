import React, { ReactElement } from 'react';
import { TextField, useRecordContext } from 'react-admin';
import { getEntityType } from '../helpers';

interface CategoryDescriptionFieldProps {
  sx?: object;
  label?: string;
}

const CategoryDescriptionField: React.FC<CategoryDescriptionFieldProps> = ({
  sx,
  label,
}): ReactElement => {
  const record = useRecordContext();
  const entityType = getEntityType(record);

  return (
    <TextField label={label} sx={sx} source={entityTextSource[entityType]} />
  );
};

CategoryDescriptionField.defaultProps = {
  label: 'Category Description',
};

const entityTextSource = {
  SYSTEM: 'system.description',
  SUBSYSTEM: 'subsystem.description',
  COMPONENT: 'component.tagDescription',
};

export default CategoryDescriptionField;
