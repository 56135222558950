import React, { ReactElement } from 'react';
import { useFormContext } from 'react-hook-form';
import CustomDateInput, {
  CustomDateInputProps,
} from '../../common/CustomDateInput';
import { getCurrentDate } from '../../common/JSONForm/helpers';

export const CurrentDateInput: React.FC<CustomDateInputProps> = ({
  source,
  ...props
}): ReactElement => {
  const { watch, setValue } = useFormContext();
  const currentDate = getCurrentDate();
  const value = watch(source);

  const handleClick = () => {
    if (!value) {
      setValue(source, currentDate, { shouldDirty: true });
    }
  };

  return <CustomDateInput source={source} {...props} onClick={handleClick} />;
};
