import React, { ReactElement, useEffect, useMemo } from 'react';
import { useListContext } from 'react-admin';
import { Gantt, Props as GanttProps } from './components/gantt/Gantt';
import { mapActivityToTask } from './helpers';

interface Props extends Omit<GanttProps, 'tasks'> {
  onDataLoaded: (data) => void;
}

const GanttChartTasks: React.FC<Props> = ({
  onDataLoaded,
  ...props
}): ReactElement => {
  const { data } = useListContext();
  useEffect(() => {
    onDataLoaded(data);
  }, [onDataLoaded, data]);

  const tasks = useMemo(() => (data || []).map(mapActivityToTask), [data]);
  return <Gantt tasks={tasks} {...props} />;
};

export default GanttChartTasks;
