export const ACTIVITY_EXPORT_BUTTONS = [
  {
    label: 'Export',
    fileName: 'activity',
    columns: [
      { source: 'systemId', label: 'System' },
      { source: 'subsystemId', label: 'Subsystem' },
      { source: 'componentId', label: 'Component' },
    ],
  },
  {
    label: 'Export All',
    fileName: 'activity_all',
    columns: [
      { source: 'systemId', label: 'System' },
      { source: 'subsystemId', label: 'Subsystem' },
      { source: 'componentId', label: 'Component' },
    ],
    includeAllColumns: true,
  },
];

export const entityNames = {
  SYSTEM: 'System',
  SUBSYSTEM: 'Subsystem',
  COMPONENT: 'Component',
};
