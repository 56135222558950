import React, { ReactElement } from 'react';
import {
  Labeled,
  LabeledProps,
  usePermissions,
  useResourceContext,
} from 'react-admin';
import { hasFieldShowAccess } from '../../utils/UtilityFunctions';

interface LabeledWithCheckAccessProps extends LabeledProps {
  children: ReactElement;
}

const LabeledWithCheckAccess: React.FC<LabeledWithCheckAccessProps> = ({
  children,
  ...rest
}): ReactElement => {
  const resource = useResourceContext();
  const { permissions } = usePermissions();
  const hasFieldAccess = hasFieldShowAccess(
    permissions,
    `${resource}.${children?.props?.source}`
  );

  return hasFieldAccess ? <Labeled {...rest}>{children}</Labeled> : null;
};

export default LabeledWithCheckAccess;
