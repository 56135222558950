import { useDataProvider, useNotify, useResourceContext } from 'react-admin';
import { useMutation } from '@tanstack/react-query';
import { CustomDataProvider } from '../../../provider/dataProvider/types';
import { getErrorMessage } from '../../../utils/UtilityFunctions';
import useInvalidateResourceQueryCache from './useInvalidateResourceQueryCache';

type Props = {
  resource?: string;
  onSuccess?: () => void;
};

const useCreateMany = ({ resource: resourceFromProps, onSuccess }: Props) => {
  const resourceFromContext = useResourceContext();
  const resource = resourceFromProps || resourceFromContext;
  const invalidateQueries = useInvalidateResourceQueryCache(resource, true);
  const notify = useNotify();
  const dataProvider = useDataProvider<CustomDataProvider>();

  const { mutate: createMany, isPending: inProgress } = useMutation({
    mutationFn: async (data: Array<Record<string, never>>) => {
      return await dataProvider.createMany(resource, { data });
    },
    onSuccess: async () => {
      if (onSuccess) {
        onSuccess();
      }
      await invalidateQueries();
      notify('Elements created', {
        type: 'success',
      });
    },
    onError: (error) => {
      notify(getErrorMessage(error), {
        type: 'error',
        undoable: false,
      });
    },
  });

  return { createMany, inProgress };
};

export default useCreateMany;
