export const getCertDetailsSystemSource = (record) => {
  switch (record?.certTemplate.category) {
    case 'COMPONENT':
      return {
        source: 'component.subsystem.system.id',
        textSource: 'component.subsystem.system.systemName',
      };
    case 'SUBSYSTEM':
      return {
        source: 'subsystem.system.id',
        textSource: 'subsystem.system.systemName',
      };
    default:
      return { source: undefined, textSource: undefined };
  }
};

export const getCertDetailsSubsystemSource = (record) => {
  switch (record?.certTemplate.category) {
    case 'COMPONENT':
      return {
        source: 'component.subsystem.id',
        textSource: 'component.subsystem.subsystem',
      };
    default:
      return { source: undefined, textSource: undefined };
  }
};
