import React, {
  useCallback,
  useState,
  useEffect,
  Fragment,
  ReactElement,
} from 'react';
import { useGetList, useRedirect } from 'react-admin';
import { Autocomplete, TextField } from '@mui/material';
import commonStyles from '../common/commonStyles';
import { initNotificationContext } from '../../provider/notificationContext';
import {
  AUTH_CONTEXT_KEY,
  getContext,
  UserContext,
} from '../../provider/userContext';
import { RESOURCE_PROJECT } from '../constants';
import { LOOKUP_PAGE_SIZE } from '../common/constants';
import ProjectSelection from './projectSelection/ProjectSelection';
import { Project } from './types';
import useChangeProject from './hooks/useChangeProject';

interface ProjectSwitchProps {
  openProjectSelection: boolean;
  setOpenProjectSelection: (value) => void;
}

const ProjectSwitch: React.FC<ProjectSwitchProps> = ({
  openProjectSelection,
  setOpenProjectSelection,
}): ReactElement => {
  const ctx = getContext();
  const redirect = useRedirect();

  const getFilter = (ctx) => {
    if (!ctx?.company) return {};
    return {
      isActive: true,
      id: ctx?.companies?.[ctx?.company]?.projectIds,
      divisionId: ctx?.companies?.[ctx?.company]?.defaultDivisionId,
    };
  };

  const [filter, setFilter] = useState(getFilter(ctx));
  const [currentProject, setCurrentProject] = useState<Project | null>(null);
  const [contextProjectId, setContextProjectId] = useState(ctx?.projectId);

  const changeProject = useChangeProject({ skipRefreshCompanyContext: true });

  const { data: projects, total: totalProjects } = useGetList(
    RESOURCE_PROJECT,
    {
      filter,
      pagination: {
        page: 1,
        perPage: 5 * LOOKUP_PAGE_SIZE,
      },
      sort: {
        field: 'project',
        order: 'ASC',
      },
    }
  );

  useEffect(() => {
    if (Array.isArray(projects) && contextProjectId) {
      projects.map((item) => {
        if (item.id === contextProjectId) setCurrentProject(item);
      });
    }
  }, [contextProjectId, projects]);

  useEffect(() => {
    const listenContextChanged = async () => {
      const newCtx: UserContext = getContext();
      if (!newCtx?.company) return;
      setFilter(getFilter(newCtx));
      setContextProjectId(newCtx.projectId);
    };

    window.addEventListener(AUTH_CONTEXT_KEY, listenContextChanged);

    return () => {
      window.removeEventListener(AUTH_CONTEXT_KEY, listenContextChanged);
    };
  }, []);

  const handleSwitchChange = useCallback(
    async (e, value: Project) => {
      await changeProject(Number(value.id));
      redirect('/');
    },
    [changeProject, redirect]
  );

  const handleClose = (
    event?: object,
    reason?: 'backdropClick' | 'escapeKeyDown'
  ) => {
    if (reason === 'backdropClick') return;
    initNotificationContext(ctx?.ablyToken, ctx?.company, ctx?.id);
    setOpenProjectSelection(false);
  };

  useEffect(() => {
    if (!ctx?.projectId) setOpenProjectSelection(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Fragment>
      {projects?.length && currentProject && (
        <Autocomplete
          value={currentProject}
          onChange={handleSwitchChange}
          options={projects}
          getOptionLabel={(option) => option.project}
          isOptionEqualToValue={(option, value) =>
            option.project === value.project
          }
          renderInput={(params) => <TextField {...params} />}
          disableClearable
          sx={{
            ...commonStyles.flexBoxItem,
            '& button, & input': { color: 'white' },
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: 'white !important',
            },
          }}
        />
      )}
      {openProjectSelection && (
        <ProjectSelection
          title={`Select Project${
            totalProjects ? ` (${totalProjects} Total)` : ''
          }`}
          open={openProjectSelection}
          handleClose={handleClose}
        />
      )}
    </Fragment>
  );
};

export default ProjectSwitch;
