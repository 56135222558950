import { GREEN, GREY, RED } from '../../../theme/colors';
import { activityCategory, activityStatusOverall } from '../constants';
import { ActivityCategory } from '../types';

export const getActivityFieldTargetId = (
  category: ActivityCategory
): string => {
  switch (category) {
    case activityCategory.system:
      return 'systemId';
    case activityCategory.subSystem:
      return 'subsystemId';
    case activityCategory.component:
    default:
      return 'componentId';
  }
};

export const getDotColor = (status: string): string => {
  switch (status) {
    case activityStatusOverall.ready:
      return GREEN;
    case activityStatusOverall.notReady:
      return RED;
    case activityStatusOverall.noCert:
    default:
      return GREY;
  }
};
