import React, { ReactElement } from 'react';
import { Button, Filter } from 'react-admin';
import isEmpty from 'lodash/isEmpty';
import { Card, CardContent } from '@mui/material';
import AutocompleteInput from '../../common/AutocompleteInput';
import PunchPriorityInput from '../../common/PunchPriorityInput';
import useSidebarFilters from '../../common/hooks/useSidebarFilters';
import DisciplineInput from '../../common/DisciplineInput';
import SubsystemInput from '../../common/SubsystemInput';
import PunchCategoryInput from '../../common/PunchCategoryInput';
import ComponentInput from '../../common/ComponentInput';
import LocationInput from '../../common/LocationInput';
import TextInput from '../../common/TextInput';
import sideFilterStyles from '../../common/sideFilterStyles';
import { SideFilter } from '../../common/types';
import { RESOURCE_SMART_PUNCH_REVIEW } from '../../constants';
import { originators } from '../../submitPunch/constants';
import { getFilterStatuses } from '../constants';

const PendingPunchSidebarFilters: React.FC<SideFilter> = ({
  defaultFilter,
}): ReactElement => {
  const { data, isLoading, filterValues, handleClearFilters } =
    useSidebarFilters(RESOURCE_SMART_PUNCH_REVIEW, defaultFilter);

  if (!data || (!data.length && isEmpty(filterValues)) || isLoading) {
    return null;
  }

  return (
    <Card sx={sideFilterStyles.sideFiltersCard}>
      <CardContent sx={sideFilterStyles.sideFiltersCardContainer}>
        <Filter>
          <TextInput
            source="description"
            name="description_inc"
            label="Description"
            alwaysOn
            sx={sideFilterStyles.sideFiltersSelectInput}
          />
          <SubsystemInput alwaysOn multiple />
          <ComponentInput
            source="componentId"
            label="Tag"
            alwaysOn
            multiple
            sx={sideFilterStyles.sideFiltersSelectInput}
          />
          <PunchCategoryInput source="punchCatId" alwaysOn multiple />
          <AutocompleteInput
            label="Status"
            source="status"
            fullWidth
            alwaysOn
            sx={sideFilterStyles.sideFiltersSelectInput}
            choices={getFilterStatuses(defaultFilter['status'])}
            disableClearable
          />
          <AutocompleteInput
            label="Originator"
            source="originator"
            alwaysOn
            sx={sideFilterStyles.sideFiltersSelectInput}
            choices={originators}
            multiple
          />
          <DisciplineInput alwaysOn multiple />
          <PunchPriorityInput source="priorityId" alwaysOn multiple />
          <LocationInput
            alwaysOn
            multiple
            sx={sideFilterStyles.sideFiltersSelectInput}
          />
        </Filter>
        <Button label="Clear filters" onClick={handleClearFilters} />
      </CardContent>
    </Card>
  );
};

export default PendingPunchSidebarFilters;
