import React, { ReactElement, useMemo } from 'react';
import { useRecordContext } from 'react-admin';
import AdminAttachmentMoveToTrashButton from '../../attachment/admin/components/AdminAttachmentMoveToTrashButton';
import useInvalidateResourceQueryCache from '../../common/hooks/useInvalidateResourceQueryCache';
import { RESOURCE_ATTACHMENT, RESOURCE_QR_SCANNER } from '../../constants';
import { QrScanList } from '../types';

const DeleteQrListButton: React.FC = (): ReactElement => {
  const record = useRecordContext<QrScanList>();
  const invalidateQueries =
    useInvalidateResourceQueryCache(RESOURCE_QR_SCANNER);

  const confirmTitle = useMemo(
    () => `Delete QR scanner record #${record?.id}`,
    [record?.id]
  );

  const confirmContent = useMemo(
    () =>
      `File "${record?.fileName}" and all files related to this QR(#${record?.id}) will be permanently deleted. Are you sure?`,
    [record?.fileName, record?.id]
  );

  return (
    <AdminAttachmentMoveToTrashButton
      confirmTitle={confirmTitle}
      confirmContent={confirmContent}
      resource={RESOURCE_ATTACHMENT}
      afterDelete={invalidateQueries}
    />
  );
};

export default DeleteQrListButton;
