import React, { ReactElement } from 'react';
import AttachmentFormTab from '../common/AttachmentFormTab';
import commonStyles from '../common/commonStyles';
import { ATTACHMENT_TYPE_ENUMS } from '../common/enums';
import HistoryTab from '../common/HistoryTab';
import LazyFormTab from '../common/LazyFormTab';
import MetaDataInputs from '../common/MetaDataInputs';
import TabbedEditForm from '../common/TabbedEditForm';
import { EditPropsWithLocation } from '../common/types';
import { RESOURCE_TEST_EQUIPMENT_CHECKOUT } from '../constants';
import TestEquipmentCheckoutForm from './components/TestEquipmentCheckoutForm';
import { TestEquipmentCheckout } from './types';

const TestEquipmentCheckoutEdit: React.FC<
  EditPropsWithLocation<TestEquipmentCheckout>
> = (props): ReactElement => (
  <TabbedEditForm {...props}>
    <LazyFormTab
      label="Test Equipment Checkout"
      id="test_equipment_checkout_tab"
      sx={commonStyles.formTabHeader}
    >
      <TestEquipmentCheckoutForm />
      <MetaDataInputs />
    </LazyFormTab>
    <LazyFormTab
      label="Attachment"
      id="attachment_tab"
      sx={commonStyles.formTabHeader}
    >
      <AttachmentFormTab
        targetId="testEquipmentCheckoutId"
        attachmentType={[ATTACHMENT_TYPE_ENUMS.GENERAL]}
        resourceName={RESOURCE_TEST_EQUIPMENT_CHECKOUT}
        simpleView
      />
    </LazyFormTab>
    <HistoryTab tableName="tbl_test_equipment_checkout" />
  </TabbedEditForm>
);

export default TestEquipmentCheckoutEdit;
