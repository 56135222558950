export const AUTH_CONTEXT_KEY = 'userContextV1';

export interface Permission {
  /**
   * @default 'allow'
   */
  type?: 'allow' | 'deny';
  action: string | string[];
  resource: string;
  record?: Record<string, unknown>;
}

export interface ProjectPermission {
  userGroups: number[]; //user-groups
  roles: number[]; // security-roleIds
  groups: number[]; // security-groupIds
  effectiveRoles: number[];
  effectivePermissions: Permission[];
  effectiveSecurityLevel?: number;
  positionId?: number;
}

export interface DynamoUserCompany {
  name: string;
  longName?: string;
  isPreferred: boolean;
  defaultDivisionId: number;
  favoriteProjects?: number[];
  projectIds: number[];
  projects: Record<number, ProjectPermission>; // <projectId, ProjectPermission>
  isCompanyAccessDisabled?: boolean;
  uiSettings: string;
}

export interface DynamoUserCompanyWithArray extends DynamoUserCompany {
  projects: Array<ProjectPermission>;
}

export interface UserContext {
  id?: string;
  company?: string;
  projectId?: number;
  defaultDivisionId?: number;
  token?: string;
  tokenExp?: number;
  companies?: Record<string, DynamoUserCompany>;
  fullName?: string;
  smartITRUser?: string;
  ablyToken?: string;
  fpmsApiKey?: string;
  isRev1Admin?: boolean;
  isTermsAndPrivacyPolicyAccepted?: boolean;
}

let cacheContext: UserContext = undefined;

export const getContext = (): UserContext | undefined => {
  if (cacheContext) return cacheContext;
  try {
    const contextStr = localStorage.getItem(AUTH_CONTEXT_KEY);
    const ctx = contextStr
      ? JSON.parse(localStorage.getItem(AUTH_CONTEXT_KEY))
      : undefined;
    if (ctx) {
      cacheContext = ctx as UserContext;
      return ctx;
    }
    // eslint-disable-next-line no-empty
  } catch (_) {
    cacheContext = undefined;
  }
  return {};
};

export const setContext = (ctx: UserContext) => {
  if (ctx) {
    localStorage.setItem(
      AUTH_CONTEXT_KEY,
      JSON.stringify({
        defaultDivisionId:
          ctx?.companies?.[ctx.company]?.defaultDivisionId || 1,
        ...ctx,
      })
    );
  } else {
    localStorage.removeItem(AUTH_CONTEXT_KEY);
  }
  cacheContext = undefined;
  window.dispatchEvent(new Event(AUTH_CONTEXT_KEY));
};

export function updateContext(newCtx: UserContext) {
  const ctx: UserContext = getContext() || {};
  setContext({ ...ctx, ...newCtx });
}

export const getCurrentProjectPermissions = ():
  | ProjectPermission
  | undefined => {
  const ctx: UserContext = getContext();
  return ctx?.companies?.[ctx?.company]?.projects?.[ctx.projectId];
};
