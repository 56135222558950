import React, { ReactElement } from 'react';
import { DateField, TextField, useStore } from 'react-admin';
import AssignedField from '../common/Assigned/AssignedField';
import ColumnActions from '../common/ColumnActions';
import CustomBooleanField from '../common/CustomBooleanField';
import FreeTextSearchFilter from '../common/FreeTextSearchFilter';
import renderMetaDataFields from '../common/helpers/renderMetaDataFields';
import { getSideFiltersKey } from '../common/helpers/storeKeys';
import useDefaultFilter from '../common/hooks/useDefaultFilter';
import ListActions from '../common/ListActions';
import ListWithTitle from '../common/ListWithTitle';
import DatagridConfigurableRbac from '../common/DatagridConfigurableRbac';
import TestEquipmentTypeField from '../common/TestEquipmentTypeField';
import VendorField from '../common/VendorField';
import { RESOURCE_TEST_EQUIPMENT_CHECKOUT } from '../constants';
import BulkActionButtons from './components/BulkActionButtons';
import TestEquipmentCheckoutSidebarFilters from './components/TestEquipmentCheckoutSidebarFilters';
import TestEquipmentCheckoutEdit from './TestEquipmentCheckoutEdit';
import TestEquipmentCheckoutShow from './TestEquipmentCheckoutShow';

const TestEquipmentCheckoutList: React.FC = (): ReactElement => {
  const sideFilter = getSideFiltersKey(RESOURCE_TEST_EQUIPMENT_CHECKOUT);
  const [showSideFilter] = useStore(sideFilter, false);
  const defaultFilter = useDefaultFilter('project');

  return (
    <ListWithTitle
      resource={RESOURCE_TEST_EQUIPMENT_CHECKOUT}
      filters={<FreeTextSearchFilter />}
      filterDefaultValues={defaultFilter}
      actions={
        <ListActions
          sideFilter={sideFilter}
          defaultFilter={defaultFilter}
          showAction={{ import: false }}
        />
      }
      sort={{ field: 'id', order: 'ASC' }}
      aside={
        showSideFilter && (
          <TestEquipmentCheckoutSidebarFilters defaultFilter={defaultFilter} />
        )
      }
    >
      <DatagridConfigurableRbac
        ShowComponent={<TestEquipmentCheckoutShow withoutActions />}
        EditComponent={<TestEquipmentCheckoutEdit syncWithLocation={false} />}
        bulkActionButtons={<BulkActionButtons />}
        defaultVisibleColumns={[
          'id',
          'testEquipment.testEquipment',
          'testEquipment.manufacturer',
          'testEquipment.model',
          'testEquipment.serialNumber',
          'testEquipment.rent',
          'assignedEmail',
          'checkOutDate',
          'checkInDate',
          'testEquipment.calibrationExpirationDate',
          'updatedAt',
        ]}
      >
        <TextField label="ID" source="id" />
        <TextField
          label="Test Equipment name"
          source="testEquipment.testEquipment"
          sortBy="`testEquipment.testEquipment`"
        />
        <TextField
          label="Manufacturer"
          source="testEquipment.manufacturer"
          sortBy="`testEquipment.manufacturer`"
        />
        <TextField
          label="Model"
          source="testEquipment.model"
          sortBy="`testEquipment.model`"
        />
        <TextField
          label="Serial Number"
          source="testEquipment.serialNumber"
          sortBy="`testEquipment.serialNumber`"
        />
        <CustomBooleanField
          label="Rent"
          source="testEquipment.rent"
          sortBy="`testEquipment.rent`"
        />
        <TextField
          label="Cost"
          source="testEquipment.cost"
          sortBy="`testEquipment.cost`"
        />
        <AssignedField label="Responsible" sortable={false} />
        <DateField label="Check Out Date" source="checkOutDate" />
        <DateField label="Check In Date" source="checkInDate" />
        <DateField
          label="Calibration Expiration Date"
          source="testEquipment.calibrationExpirationDate"
          sortBy="`testEquipment.calibrationExpirationDate`"
        />
        <DateField
          label="Calibration Date"
          source="testEquipment.calibrationDate"
          sortBy="`testEquipment.calibrationDate`"
        />
        <VendorField
          label="Vendor"
          source="testEquipment.vendorId"
          textSource="testEquipment.vendor.vendor"
          sortBy="`testEquipment.vendor.vendor`"
        />
        <TestEquipmentTypeField
          source="testEquipment.testEquipmentTypeId"
          textSource="testEquipment.testEquipmentType.type"
          sortBy="`testEquipment.testEquipmentType.type`"
        />
        {renderMetaDataFields()}
        <ColumnActions label="Actions" />
      </DatagridConfigurableRbac>
    </ListWithTitle>
  );
};

export default TestEquipmentCheckoutList;
