import React, { ReactElement } from 'react';
import { maxLength, SimpleForm } from 'react-admin';
import { getContext, UserContext } from '../../provider/userContext';
import CustomNumberInput from '../common/CustomNumberInput';
import { validateLessThanValue } from '../common/validators';
import commonStyles from '../common/commonStyles';
import FooterToolbar from '../common/FooterToolbar';
import DisciplineInput from '../common/DisciplineInput';
import CreateWithTitle from '../common/CreateWithTitle';
import TextInput from '../common/TextInput';
import { required } from '../../utils/UtilityFunctions';
import BoxWithCheckAccess from '../common/BoxWithCheckAccess';
import { Position } from './types';
import CurrencyInput from './components/CurrencyInput';

const PositionCreate: React.FC = (): ReactElement => {
  const ctx: UserContext = getContext();

  const transform = (data: Position): Position => ({
    ...data,
    projectId: ctx?.projectId,
  });

  return (
    <CreateWithTitle redirect="list" transform={transform}>
      <SimpleForm toolbar={<FooterToolbar />}>
        <BoxWithCheckAccess sx={commonStyles.createBoxHalfWidth}>
          <TextInput
            source="position"
            label="Position"
            validate={[required(), maxLength(55)]}
            sx={commonStyles.flexBoxItem}
          />
          <TextInput
            source="description"
            label="Description"
            validate={[maxLength(100)]}
            sx={commonStyles.flexBoxItem}
            fullWidth
            multiline
          />
        </BoxWithCheckAccess>
        <BoxWithCheckAccess sx={commonStyles.createBoxHalfWidth}>
          <DisciplineInput />
        </BoxWithCheckAccess>
        <BoxWithCheckAccess sx={commonStyles.createBoxHalfWidth}>
          <CustomNumberInput
            source="billRate"
            label="Bill Rate"
            fractionDigits={2}
            isPositive
            sx={commonStyles.flexBoxItem}
            validate={[validateLessThanValue(1000)]} // billRate - DECIMAL(5,2)
          />
          <CurrencyInput />
        </BoxWithCheckAccess>
      </SimpleForm>
    </CreateWithTitle>
  );
};

export default PositionCreate;
