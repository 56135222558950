import React, { ReactElement } from 'react';
import {
  ListView,
  Datagrid,
  ListContextProvider,
  ListControllerResult,
} from 'react-admin';
import { ProjectPermission } from '../../../provider/userContext';
import UserProjectGroupField from '../../common/UserProjectGroupField';
import UserProjectPositionField from '../../common/UserProjectPositionField';
import UserProjectRoleField from '../../common/UserProjectRoleField';
import UserProjectUserGroupField from '../../common/UserProjectUserGroupField';
import commonStyles from '../../common/commonStyles';
import { RESOURCE_PROJECT } from '../../constants';
import UserProjectNameField from './UserProjectNameField';

interface ProjectFieldProps {
  projects: ProjectPermission[];
}

const ProjectTabField: React.FC<ProjectFieldProps> = ({
  projects,
}): ReactElement => {
  const projectList = Object.keys(projects).map((key) => {
    return {
      id: key,
      ...projects[key],
    };
  });
  const listCtx: ListControllerResult = {
    data: projectList,
    sort: { field: 'resource', order: 'ASC' },
    perPage: projectList.length,
    total: projectList.length,
    page: 0,
    displayedFilters: {},
    filterValues: {},
    selectedIds: [],
    resource: '',
    isFetching: false,
    isPending: false,
    hasNextPage: false,
    hasPreviousPage: false,
    exporter: false,
    error: null,
    onSelect: () => {},
    onToggleItem: () => {},
    onUnselectItems: () => {},
    setPerPage: () => {},
    setPage: () => {},
    setFilters: () => {},
    refetch: () => {},
    hideFilter: () => {},
    showFilter: () => {},
    setSort: () => {},
  };

  return (
    <ListContextProvider value={listCtx}>
      <ListView actions={null}>
        <Datagrid bulkActionButtons={false} rowClick={false}>
          <UserProjectNameField
            label="Project Name"
            source="id"
            reference={RESOURCE_PROJECT}
            sx={commonStyles.flexBoxItem}
            sortable={false}
            textSource="project"
          />
          <UserProjectGroupField
            label="Groups"
            source="groups"
            sortable={false}
          />
          <UserProjectRoleField label="Roles" source="roles" sortable={false} />
          <UserProjectUserGroupField
            label="User Groups"
            source="userGroups"
            sortable={false}
          />
          <UserProjectPositionField sortable={false} />
        </Datagrid>
      </ListView>
    </ListContextProvider>
  );
};

export default ProjectTabField;
