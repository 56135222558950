import { updateLocalUISettings } from '../../../utils/UtilityFunctions';
import { initNotificationContext } from '../../notificationContext';
import { getContext, updateContext, UserContext } from '../../userContext';

const setCtx = (data) => {
  if (data.companies) {
    const ctx: UserContext = getContext();
    ctx.companies = data.companies;
    ctx.id = data.id;
    ctx.fullName = `${data.firstName} ${data.lastName}`;
    let selectedCompany = Object.keys(ctx.companies).find(
      (company) =>
        ctx.companies[company].isPreferred &&
        !ctx.companies[company].isCompanyAccessDisabled
    );
    if (!selectedCompany) {
      selectedCompany = Object.keys(ctx.companies).find(
        (company) => !ctx.companies[company].isCompanyAccessDisabled
      );
    }
    if (!selectedCompany) {
      return Promise.reject({
        logoutOnFailure: false,
        message: "Couldn't find preferred company!",
      });
    }
    ctx.company = selectedCompany;
    ctx.defaultDivisionId = ctx.companies[selectedCompany].defaultDivisionId;
    ctx.ablyToken = data.ablyToken;
    ctx.fpmsApiKey = data.fpmsApiKey;
    ctx.isRev1Admin = data.isRev1Admin;
    ctx.isTermsAndPrivacyPolicyAccepted = data.isTermsAndPrivacyPolicyAccepted;
    updateContext(ctx);
    initNotificationContext(ctx.ablyToken, ctx.company, ctx.id);

    const uiSettings = data.companies[selectedCompany]?.uiSettings;

    if (uiSettings) {
      updateLocalUISettings(uiSettings);
    }
  }
};

export default setCtx;
