import React, { ReactElement, useMemo } from 'react';
import { setPageTitle } from '../common/helpers/setPageTitle';
import { getContext, UserContext } from '../../provider/userContext';
// import DashTelerikView from './DashTelerikView';
import { SimpleTabbedLayout } from '../common/SimpleTabbedLayout';
import LucidEmbedView from '../lucidDiagram/components/LucidEmbedView';
import useDiagramForDashboard from '../common/hooks/useDiagramForDashboard';
import DashPowerBIView from './DashPowerBIView';

export const Dashboard: React.FC = (): ReactElement => {
  const ctx: UserContext = getContext();
  setPageTitle('Dashboard');

  const diagram = useDiagramForDashboard();

  const tabs = useMemo(
    () => [
      {
        label: 'Dashboard',
        component: <DashPowerBIView />,
        // component: <DashTelerikView reportFileName="Dashboard.trdp" />,
      },
      {
        label: 'Diagram',
        component: (
          <LucidEmbedView record={diagram} className="lucid-embed--dashboard" />
        ),
        visible: !!diagram,
      },
    ],
    [diagram]
  );

  return ctx?.projectId ? <SimpleTabbedLayout tabs={tabs} /> : null;
};
