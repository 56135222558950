import { AutocompleteInputChangeReason } from '@mui/base/useAutocomplete/useAutocomplete';
import { Autocomplete, TextField } from '@mui/material';
import React, {
  ChangeEvent,
  ReactElement,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { filterOptions } from '../../preservationItemActivity/helpers';
import useDebounce from '../hooks/useDebounce';
import useInputs from './hooks/useInputs';
import useOptionList from './hooks/useOptionList';
import { AssignedInputProps, OptionType } from './types';
import { GroupHeader, GroupItems } from './styles';

const AssignedInput: React.FC<AssignedInputProps> = ({
  label,
  category,
  sx,
}): ReactElement => {
  const [filters, setFilters] = useState({ q: '' });
  const options = useOptionList(category, filters);

  const {
    contactId,
    // assignedGroupId,
    assignedUserGroupId,
    assignedEmail,
  } = useInputs();
  const [value, setValue] = useState<OptionType | null>(null);

  useEffect(() => {
    const newValue = [
      contactId.value,
      // assignedGroupId.value,
      assignedUserGroupId.value,
      assignedEmail.value,
    ].find((it) => !!it);
    const newOption = options?.find(
      (option) => option.option.value === newValue
    );

    if (newOption?.option.value !== value?.option.value) {
      setValue(newOption);
    }
  }, [
    contactId.value,
    // assignedGroupId.value,
    assignedUserGroupId.value,
    assignedEmail.value,
    options,
    value,
  ]);

  const handleChange = useCallback(
    (_, value) => {
      const assignedType = value?.option.assignedType ?? null;

      const newValue = value?.option.value;
      let newEmail = null;
      // let newGroup = null;
      let newUserGroup = null;
      let newContact = null;

      switch (assignedType) {
        case 'assignedEmail':
          newEmail = newValue;
          break;
        // case 'assignedGroupId':
        //   newGroup = newValue;
        //   break;
        case 'assignedUserGroupId':
          newUserGroup = newValue;
          break;
        case 'assignedContactId':
          newContact = newValue;
          break;
      }

      assignedEmail.onChange(newEmail);
      // assignedGroupId.onChange(newGroup);
      assignedUserGroupId.onChange(newUserGroup);
      contactId.onChange(newContact);
      setValue(value);
    },
    [
      assignedEmail,
      // assignedGroupId,
      assignedUserGroupId,
      contactId,
    ]
  );
  const debounce = useDebounce();
  const handleDebouncedInputChange = (
    event: ChangeEvent<HTMLInputElement>,
    value: string,
    reason: AutocompleteInputChangeReason
  ) => {
    debounce(() => {
      if (event && reason === 'input') {
        setFilters({ q: event?.target?.value });
      }
    });
  };

  return (
    <Autocomplete
      id="assignedInput"
      options={options}
      groupBy={(item) => item.group}
      getOptionLabel={(item) => item.option.title}
      onInputChange={handleDebouncedInputChange}
      onChange={handleChange}
      filterOptions={filterOptions()}
      value={value}
      sx={sx}
      selectOnFocus
      clearOnBlur
      isOptionEqualToValue={(option, value) => option.id === value?.id}
      renderInput={(params) => <TextField {...params} label={label} />}
      renderGroup={(params) => (
        <li key={params.key}>
          <GroupHeader>{params.group}</GroupHeader>
          <GroupItems>{params.children}</GroupItems>
        </li>
      )}
    />
  );
};

AssignedInput.defaultProps = {
  label: 'Assigned',
};

export default AssignedInput;
