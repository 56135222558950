import { EditDialog } from '@react-admin/ra-form-layout';
import React, { Fragment, ReactElement, useCallback } from 'react';
import { Button, SimpleForm, useNotify, WithRecord } from 'react-admin';
import useDialogState from '../../../common/hooks/useDialogState';
import SaveOnlyToolbar from '../../../common/SaveOnlyToolbar';
import ActivityForm from '../../../activity/components/ActivityForm';

interface Props {
  recordId?: number;
  onSuccess: () => void;
}

const EditButton: React.FC<Props> = ({
  recordId,
  onSuccess: onSuccessProps,
}): ReactElement => {
  const { isVisible, show, hide } = useDialogState(false);
  const notify = useNotify();
  const onSuccess = useCallback(() => {
    onSuccessProps();
    notify('Element updated');
  }, [notify, onSuccessProps]);

  if (!recordId) return null;

  return (
    <Fragment>
      <Button label="Edit" onClick={show} />
      <EditDialog
        title="Activity Edit"
        id={recordId}
        fullWidth
        maxWidth="md"
        isOpen={isVisible}
        close={hide}
        mutationMode="pessimistic"
        mutationOptions={{ onSuccess }}
      >
        <WithRecord
          render={(record) => (
            <SimpleForm record={record} toolbar={<SaveOnlyToolbar />}>
              <ActivityForm />
            </SimpleForm>
          )}
        />
      </EditDialog>
    </Fragment>
  );
};

export default EditButton;
