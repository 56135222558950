import React, { ReactElement } from 'react';
import { GetListParams } from 'react-admin';
import { SxProps, Theme } from '@mui/material/styles';
import { required } from '../../utils/UtilityFunctions';
import { RESOURCE_PROJECT } from '../constants';
import { Project } from '../project/types';
import AutocompleteReferenceInput from './AutocompleteReferenceInput';

interface ProjectInputProps {
  label?: string;
  source?: string;
  name?: string;
  optionText?: string;
  isRequired?: boolean;
  alwaysOn?: boolean;
  multiple?: boolean;
  defaultValue?: number;
  sx?: SxProps<Theme>;
  requestParams?: Partial<GetListParams>;
  getOptionDisabled?: (option: Project) => boolean;
  includeFields?: string[];
  disabled?: boolean;
}

const ProjectInput: React.FC<ProjectInputProps> = ({
  isRequired,
  getOptionDisabled,
  includeFields,
  ...rest
}): ReactElement => (
  <AutocompleteReferenceInput
    {...rest}
    resource={RESOURCE_PROJECT}
    validate={isRequired ? required() : undefined}
    includeFields={includeFields}
    additionalParams={{ skipListAdditionalData: true }}
    getOptionDisabled={getOptionDisabled}
  />
);

ProjectInput.defaultProps = {
  label: 'Project Name',
  source: 'projectId',
  optionText: 'project',
  name: 'project_inc',
  includeFields: ['id', 'project'],
};

export default ProjectInput;
