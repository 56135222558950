import React, { Fragment } from 'react';
import CommentList from '../comment/CommentList';
import CommentCreate from '../comment/CommentCreate';

export interface CommentFormTabProps {
  targetId: string;
  commentType: string;
  resource: string;
  withActions: boolean;
  transformFunc?: (data, recordId) => object;
}

const CommentFormTab: React.FC<CommentFormTabProps> = ({
  targetId,
  commentType,
  resource,
  withActions,
  transformFunc,
}) => {
  return (
    <Fragment>
      <CommentList
        resource={resource}
        targetId={targetId}
        commentType={commentType}
        withActions={withActions}
      />
      {withActions && (
        <CommentCreate resource={resource} transformFunc={transformFunc} />
      )}
    </Fragment>
  );
};

export default CommentFormTab;
