import React, { ReactElement, useEffect } from 'react';
import { Button, Filter, NullableBooleanInput } from 'react-admin';
import { Card, CardContent } from '@mui/material';
import isEmpty from 'lodash/isEmpty';
import AssignedInput from '../../common/Assigned/AssignedInput';
import { ContactCategories } from '../../common/contactInput/constants';
import useSidebarFilters from '../../common/hooks/useSidebarFilters';
import NumberRangeFilter from '../../common/NumberRangeFilter';
import TestEquipmentTypeInput from '../../common/TestEquipmentTypeInput';
import TextInput from '../../common/TextInput';
import { SideFilter } from '../../common/types';
import sideFilterStyles from '../../common/sideFilterStyles';
import DateRangeFilter from '../../common/DateRangeFilter';
import VendorInput from '../../common/VendorInput/VendorInput';
import { RESOURCE_TEST_EQUIPMENT_CHECKOUT } from '../../constants';

const TestEquipmentCheckoutSidebarFilters: React.FC<SideFilter> = ({
  defaultFilter,
}): ReactElement => {
  const {
    data,
    isLoading,
    filterValues,
    setFilters,
    handleClearFilters,
    dateFrom,
    dateTo,
    handleDateFromChange,
    handleDateToChange,
    numberFrom,
    numberTo,
    handleNumberFromChange,
    handleNumberToChange,
  } = useSidebarFilters(RESOURCE_TEST_EQUIPMENT_CHECKOUT, defaultFilter);

  useEffect(() => {
    if (filterValues) {
      setFilters({ ...defaultFilter, ...filterValues }, []);
    }
    // eslint-disable-next-line
  }, [JSON.stringify(filterValues), setFilters, JSON.stringify(defaultFilter)]);

  if (!data || (!data.length && isEmpty(filterValues)) || isLoading) {
    return null;
  }

  return (
    <Card sx={sideFilterStyles.sideFiltersCard} style={{ minWidth: 310 }}>
      <CardContent sx={sideFilterStyles.sideFiltersCardContainer}>
        <Filter>
          <TextInput
            label="Test Equipment name"
            source="testEquipment.testEquipment"
            name="testEquipment->testEquipment_inc"
            alwaysOn
            fullWidth
          />
          <TextInput
            label="Manufacturer"
            source="testEquipment.manufacturer"
            name="testEquipment->manufacturer_inc"
            alwaysOn
            fullWidth
          />
          <TextInput
            label="Model"
            source="testEquipment.model"
            name="testEquipment->model_inc"
            alwaysOn
            fullWidth
          />
          <TextInput
            label="Serial Number"
            source="testEquipment.serialNumber"
            name="testEquipment->serialNumber_inc"
            alwaysOn
            fullWidth
          />
          <NullableBooleanInput
            label="Rent"
            source="testEquipment.rent"
            name="testEquipment->rent"
            trueLabel="True"
            falseLabel="False"
            nullLabel="None"
            alwaysOn
            fullWidth
          />
          <NumberRangeFilter
            label="Cost"
            source="testEquipment.cost"
            numberFrom={numberFrom}
            numberTo={numberTo}
            handleNumberFromChange={handleNumberFromChange}
            handleNumberToChange={handleNumberToChange}
            fullWidth
            alwaysOn
          />
          <AssignedInput
            label="Responsible"
            category={ContactCategories.itrEmpWorkGrp}
            alwaysOn
            sx={sideFilterStyles.sideFiltersSelectInput}
          />
          <DateRangeFilter
            label="Check Out Date"
            source="checkOutDate"
            dateFrom={dateFrom}
            dateTo={dateTo}
            handleDateFromChange={handleDateFromChange}
            handleDateToChange={handleDateToChange}
            sx={sideFilterStyles.sideFiltersSelectInput}
          />
          <DateRangeFilter
            label="Check In Date"
            source="checkInDate"
            dateFrom={dateFrom}
            dateTo={dateTo}
            handleDateFromChange={handleDateFromChange}
            handleDateToChange={handleDateToChange}
            sx={sideFilterStyles.sideFiltersSelectInput}
          />
          <DateRangeFilter
            label="Calibration Expiration Date"
            source="testEquipment->calibrationExpirationDate"
            dateFrom={dateFrom}
            dateTo={dateTo}
            handleDateFromChange={handleDateFromChange}
            handleDateToChange={handleDateToChange}
            sx={sideFilterStyles.sideFiltersSelectInput}
          />
          <DateRangeFilter
            label="Calibration Date"
            source="testEquipment->calibrationDate"
            dateFrom={dateFrom}
            dateTo={dateTo}
            handleDateFromChange={handleDateFromChange}
            handleDateToChange={handleDateToChange}
            sx={sideFilterStyles.sideFiltersSelectInput}
          />
          <VendorInput
            label="Vendor"
            source="testEquipment->vendorId"
            alwaysOn
            multiple
          />
          <TestEquipmentTypeInput
            source="testEquipment->testEquipmentTypeId"
            alwaysOn
            multiple
          />
        </Filter>
        <Button label="Clear filters" onClick={handleClearFilters} />
      </CardContent>
    </Card>
  );
};

export default TestEquipmentCheckoutSidebarFilters;
