import React, { ReactElement } from 'react';
import { ArrayField, ChipField } from 'react-admin';
import SmartSingleFieldList from '../../common/SmartSingleFieldList';

interface PredecessorActivityFieldProps {
  source?: string;
  label?: string;
  chipSource?: string;
  sortable?: boolean;
}

export const PredecessorActivityField: React.FC<
  PredecessorActivityFieldProps
> = ({ source, label, chipSource, sortable }): ReactElement => {
  return (
    <ArrayField source={source} label={label} sortable={sortable}>
      <SmartSingleFieldList>
        <ChipField source={chipSource} />
      </SmartSingleFieldList>
    </ArrayField>
  );
};

PredecessorActivityField.defaultProps = {
  source: 'predecessors',
  label: 'Predecessor Activities',
  chipSource: 'predecessor.activity',
};
