import React, { ReactElement, useMemo } from 'react';
import './style.css';
import { CircularProgress } from '@mui/material';
import { useGetList } from 'react-admin';
import { getContext } from '../../provider/userContext';
import { RESOURCE_SECURITY_ROLE } from '../constants';
import { fpmsLink } from '../../provider/api';
import { LOOKUP_PAGE_SIZE } from '../common/constants';

export const FPMS: React.FC = (): ReactElement => {
  const ctx = getContext();
  const { data: securityRoles, isLoading } = useGetList(
    RESOURCE_SECURITY_ROLE,
    { pagination: { page: 1, perPage: LOOKUP_PAGE_SIZE } }
  );

  const securityRoleByIdMap = useMemo(() => {
    return securityRoles?.reduce((map, securityRole) => {
      map[securityRole.id] = securityRole.role;
      return map;
    }, {});
  }, [securityRoles]);

  const iframeLink = useMemo(() => {
    if (!ctx || !securityRoleByIdMap) {
      return '';
    }

    const [firstName, lastName] = ctx.fullName.split(' ');
    const roles = ctx.companies[ctx.company].projects[ctx.projectId].roles.map(
      (roleId) => securityRoleByIdMap[roleId]
    );

    const searchParams = new URLSearchParams({
      project_id: String(ctx.projectId),
      email: ctx.id,
      first_name: firstName,
      last_name: lastName,
      security_token: ctx.fpmsApiKey,
      company: ctx.company,
      security_role: roles.join(','),
    });

    return `${fpmsLink}?${searchParams}`;
  }, [ctx, securityRoleByIdMap]);

  return (
    <div className="fpms-wrapper">
      {isLoading && (
        <div className="circular-wrapper">
          <CircularProgress />
        </div>
      )}
      {iframeLink && <iframe src={iframeLink} />}
    </div>
  );
};
