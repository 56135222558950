import {
  CUSTOM_ROUTE_BOOK_GENERATION,
  CUSTOM_ROUTE_QR_CODE_GENERATION,
  RESOURCE_ASSIGN_ITR,
  RESOURCE_POSITION,
  RESOURCE_PRESERVATION_ITEM_ACTIVITY,
  RESOURCE_PUNCH,
  RESOURCE_SMART_PRESERVATION_ITEM_ACTIVITY,
} from '../constants';

export const permissionTypeChoices = [
  { id: 'allow', name: 'Allow' },
  { id: 'deny', name: 'Deny' },
];

export const actionChoices = [
  { id: '*', name: 'All' },
  { id: 'list', name: 'List' },
  { id: 'create', name: 'Create' },
  { id: 'edit', name: 'Edit' },
  { id: 'show', name: 'Show' },
  { id: 'delete', name: 'Delete' },
  { id: 'forceDelete', name: 'Force Delete' },
  { id: 'export', name: 'Export' },
  { id: 'import', name: 'Import' },
  { id: 'certGen', name: 'Certificate Generation' },
  { id: 'certOverride', name: 'Certificate Override' },
  { id: 'closeout', name: 'Closeout' },
  { id: 'itrAttachment', name: 'ITR Attachment' },
  { id: 'templateUpdate', name: 'Template Update' },
  { id: 'pdfGen', name: 'PDF Generation' },
  { id: 'sendMobile', name: 'Send Mobile' },
];

export const actionFieldChoices = [
  { id: '*', name: 'All' },
  { id: 'edit', name: 'Edit' },
  { id: 'show', name: 'Show' },
];

export const customRouteResources = [
  {
    id: `${RESOURCE_ASSIGN_ITR}`,
    name: 'Assign ITRs',
  },
  {
    id: `${CUSTOM_ROUTE_BOOK_GENERATION}`,
    name: 'Book Generate',
  },
  {
    id: `${CUSTOM_ROUTE_QR_CODE_GENERATION}`,
    name: 'QR Code Generator',
  },
];

const positionFieldChoices = [
  {
    id: `${RESOURCE_POSITION}.id`,
    name: 'Position: Id',
  },
  {
    id: `${RESOURCE_POSITION}.position`,
    name: 'Position: Position',
  },
  {
    id: `${RESOURCE_POSITION}.description`,
    name: 'Position: Description',
  },
  {
    id: `${RESOURCE_POSITION}.disciplineId`,
    name: 'Position: Discipline',
  },
  {
    id: `${RESOURCE_POSITION}.billRate`,
    name: 'Position: Bill Rate',
  },
  {
    id: `${RESOURCE_POSITION}.currencyId`,
    name: 'Position: Currency',
  },
  {
    id: `${RESOURCE_POSITION}.updatedAt`,
    name: 'Position: Updated At',
  },
  {
    id: `${RESOURCE_POSITION}.updateBy`,
    name: 'Position: Updated By',
  },
  {
    id: `${RESOURCE_POSITION}.updatedByName`,
    name: 'Position: Updated By Name',
  },
];

const preservationItemActivityFieldChoices = [
  {
    id: `${RESOURCE_PRESERVATION_ITEM_ACTIVITY}.id`,
    name: 'Preservation Item Activity: Id',
  },
  {
    id: `${RESOURCE_PRESERVATION_ITEM_ACTIVITY}.preservationItemId`,
    name: 'Preservation Item Activity: Preservation Item',
  },
  {
    id: `${RESOURCE_PRESERVATION_ITEM_ACTIVITY}.preservationSeriesId`,
    name: 'Preservation Item Activity: Preservation Series',
  },
  {
    id: `${RESOURCE_PRESERVATION_ITEM_ACTIVITY}.componentId`,
    name: 'Preservation Item Activity: Component',
  },
  {
    id: `${RESOURCE_PRESERVATION_ITEM_ACTIVITY}.testId`,
    name: 'Preservation Item Activity: Test',
  },
  {
    id: `${RESOURCE_PRESERVATION_ITEM_ACTIVITY}.locationId`,
    name: 'Preservation Item Activity: Location',
  },
  {
    id: `${RESOURCE_PRESERVATION_ITEM_ACTIVITY}.estimatedManHours`,
    name: 'Preservation Item Activity: Estimated Man Hours',
  },
  {
    id: `${RESOURCE_PRESERVATION_ITEM_ACTIVITY}.actualManHours`,
    name: 'Preservation Item Activity: Actual Man Hours',
  },
  {
    id: `${RESOURCE_PRESERVATION_ITEM_ACTIVITY}.adminManHours`,
    name: 'Preservation Item Activity: Admin Man Hours',
  },
  {
    id: `${RESOURCE_PRESERVATION_ITEM_ACTIVITY}.onSiteDate`,
    name: 'Preservation Item Activity: On Site Date',
  },
  {
    id: `${RESOURCE_PRESERVATION_ITEM_ACTIVITY}.assigned`,
    name: 'Preservation Item Activity: Responsible',
  },
  {
    id: `${RESOURCE_PRESERVATION_ITEM_ACTIVITY}.completed`,
    name: 'Preservation Item Activity: Completed',
  },
  {
    id: `${RESOURCE_PRESERVATION_ITEM_ACTIVITY}.inspector`,
    name: 'Preservation Item Activity: Inspector: Signature',
  },
  {
    id: `${RESOURCE_PRESERVATION_ITEM_ACTIVITY}.signOffDate`,
    name: 'Preservation Item Activity: Sign Off Date',
  },
  {
    id: `${RESOURCE_PRESERVATION_ITEM_ACTIVITY}.verifiedBy`,
    name: 'Preservation Item Activity: Verified By: Signature',
  },
  {
    id: `${RESOURCE_PRESERVATION_ITEM_ACTIVITY}.verifiedCompany`,
    name: 'Preservation Item Activity: Verified By: Company',
  },
  {
    id: `${RESOURCE_PRESERVATION_ITEM_ACTIVITY}.verifiedDate`,
    name: 'Preservation Item Activity: Verified By: Date',
  },
  {
    id: `${RESOURCE_PRESERVATION_ITEM_ACTIVITY}.frequency`,
    name: 'Preservation Item Activity: Frequency',
  },
  {
    id: `${RESOURCE_PRESERVATION_ITEM_ACTIVITY}.dueDate`,
    name: 'Preservation Item Activity: Due Date',
  },
  {
    id: `${RESOURCE_PRESERVATION_ITEM_ACTIVITY}.lateDate`,
    name: 'Preservation Item Activity: Late Date',
  },
  {
    id: `${RESOURCE_PRESERVATION_ITEM_ACTIVITY}.sequence`,
    name: 'Preservation Item Activity: Sequence',
  },
  {
    id: `${RESOURCE_PRESERVATION_ITEM_ACTIVITY}.storagePreservation`,
    name: 'Preservation Item Activity: Storage Preservation',
  },
  {
    id: `${RESOURCE_PRESERVATION_ITEM_ACTIVITY}.isMobile`,
    name: 'Preservation Item Activity: Is Mobile',
  },
  {
    id: `${RESOURCE_PRESERVATION_ITEM_ACTIVITY}.isClosed`,
    name: 'Preservation Item Activity: Is Closed',
  },
  {
    id: `${RESOURCE_PRESERVATION_ITEM_ACTIVITY}.hasAttachment`,
    name: 'Preservation Item Activity: Has Attachment',
  },
  {
    id: `${RESOURCE_PRESERVATION_ITEM_ACTIVITY}.plusDate`,
    name: 'Preservation Item Activity: Plus Date',
  },
  {
    id: `${RESOURCE_PRESERVATION_ITEM_ACTIVITY}.printedBy`,
    name: 'Preservation Item Activity: Printed By',
  },
  {
    id: `${RESOURCE_PRESERVATION_ITEM_ACTIVITY}.printedDate`,
    name: 'Preservation Item Activity: Printed Date',
  },
  {
    id: `${RESOURCE_PRESERVATION_ITEM_ACTIVITY}.isDeleted`,
    name: 'Preservation Item Activity: Is Deleted Side Filter',
  },
  {
    id: `${RESOURCE_PRESERVATION_ITEM_ACTIVITY}.updatedAt`,
    name: 'Preservation Item Activity: Updated At',
  },
  {
    id: `${RESOURCE_PRESERVATION_ITEM_ACTIVITY}.updateBy`,
    name: 'Preservation Item Activity: Updated By',
  },
  {
    id: `${RESOURCE_PRESERVATION_ITEM_ACTIVITY}.updatedByName`,
    name: 'Preservation Item Activity: Updated By Name',
  },
];

const smartPreservationItemActivityFieldChoices = [
  {
    id: `${RESOURCE_SMART_PRESERVATION_ITEM_ACTIVITY}.id`,
    name: 'Smart Preservation Item Activity: Id',
  },
  {
    id: `${RESOURCE_SMART_PRESERVATION_ITEM_ACTIVITY}.preservationItemId`,
    name: 'Smart Preservation Item Activity: Preservation Item',
  },
  {
    id: `${RESOURCE_SMART_PRESERVATION_ITEM_ACTIVITY}.preservationSeriesId`,
    name: 'Smart Preservation Item Activity: Preservation Series',
  },
  {
    id: `${RESOURCE_SMART_PRESERVATION_ITEM_ACTIVITY}.componentId`,
    name: 'Smart Preservation Item Activity: Component',
  },
  {
    id: `${RESOURCE_SMART_PRESERVATION_ITEM_ACTIVITY}.testId`,
    name: 'Smart Preservation Item Activity: Test',
  },
  {
    id: `${RESOURCE_SMART_PRESERVATION_ITEM_ACTIVITY}.locationId`,
    name: 'Smart Preservation Item Activity: Location',
  },
  {
    id: `${RESOURCE_SMART_PRESERVATION_ITEM_ACTIVITY}.assigned`,
    name: 'Smart Preservation Item Activity: Responsible',
  },
  {
    id: `${RESOURCE_SMART_PRESERVATION_ITEM_ACTIVITY}.frequency`,
    name: 'Smart Preservation Item Activity: Frequency',
  },
  {
    id: `${RESOURCE_SMART_PRESERVATION_ITEM_ACTIVITY}.dueDate`,
    name: 'Smart Preservation Item Activity: Due Date',
  },
  {
    id: `${RESOURCE_SMART_PRESERVATION_ITEM_ACTIVITY}.lateDate`,
    name: 'Smart Preservation Item Activity: Late Date',
  },
  {
    id: `${RESOURCE_SMART_PRESERVATION_ITEM_ACTIVITY}.estimatedManHours`,
    name: 'Smart Preservation Item Activity: Estimated Man Hours',
  },
  {
    id: `${RESOURCE_SMART_PRESERVATION_ITEM_ACTIVITY}.actualManHours`,
    name: 'Smart Preservation Item Activity: Actual Man Hours',
  },
  {
    id: `${RESOURCE_SMART_PRESERVATION_ITEM_ACTIVITY}.adminManHours`,
    name: 'Smart Preservation Item Activity: Admin Man Hours',
  },
  {
    id: `${RESOURCE_SMART_PRESERVATION_ITEM_ACTIVITY}.onSiteDate`,
    name: 'Smart Preservation Item Activity: On Site Date',
  },
  {
    id: `${RESOURCE_SMART_PRESERVATION_ITEM_ACTIVITY}.inspector`,
    name: 'Smart Preservation Item Activity: Inspector: Signature',
  },
  {
    id: `${RESOURCE_SMART_PRESERVATION_ITEM_ACTIVITY}.inspectorPrintName`,
    name: 'Smart Preservation Item Activity: Inspector: Print Name',
  },
  {
    id: `${RESOURCE_SMART_PRESERVATION_ITEM_ACTIVITY}.inspectorCompany`,
    name: 'Smart Preservation Item Activity: Inspector: Company',
  },
  {
    id: `${RESOURCE_SMART_PRESERVATION_ITEM_ACTIVITY}.signOffDate`,
    name: 'Smart Preservation Item Activity: Sign Off Date',
  },
  {
    id: `${RESOURCE_SMART_PRESERVATION_ITEM_ACTIVITY}.printedBy`,
    name: 'Smart Preservation Item Activity: Printed By',
  },
  {
    id: `${RESOURCE_SMART_PRESERVATION_ITEM_ACTIVITY}.printedDate`,
    name: 'Smart Preservation Item Activity: Printed Date',
  },
  {
    id: `${RESOURCE_SMART_PRESERVATION_ITEM_ACTIVITY}.completed`,
    name: 'Smart Preservation Item Activity: Completed',
  },
  {
    id: `${RESOURCE_SMART_PRESERVATION_ITEM_ACTIVITY}.verifiedBy`,
    name: 'Smart Preservation Item Activity: Verified By: Signature',
  },
  {
    id: `${RESOURCE_SMART_PRESERVATION_ITEM_ACTIVITY}.verifiedCompany`,
    name: 'Smart Preservation Item Activity: Verified By: Company',
  },
  {
    id: `${RESOURCE_SMART_PRESERVATION_ITEM_ACTIVITY}.verifiedPrintName`,
    name: 'Smart Preservation Item Activity: Verified By: Print Name',
  },
  {
    id: `${RESOURCE_SMART_PRESERVATION_ITEM_ACTIVITY}.verifiedDate`,
    name: 'Smart Preservation Item Activity: Verified By: Date',
  },
  {
    id: `${RESOURCE_SMART_PRESERVATION_ITEM_ACTIVITY}.plusDate`,
    name: 'Smart Preservation Item Activity: Plus Date',
  },
];

const punchFieldChoices = [
  {
    id: `${RESOURCE_PUNCH}.id`,
    name: 'Punch: Id',
  },
  {
    id: `${RESOURCE_PUNCH}.punchNo`,
    name: 'Punch: Punch No',
  },
  {
    id: `${RESOURCE_PUNCH}.description`,
    name: 'Punch: Description',
  },
  {
    id: `${RESOURCE_PUNCH}.status`,
    name: 'Punch: Status',
  },
  {
    id: `${RESOURCE_PUNCH}.componentId`,
    name: 'Punch: Component',
  },
  {
    id: `${RESOURCE_PUNCH}.subsystemId`,
    name: 'Punch: Subsystem',
  },
  {
    id: `${RESOURCE_PUNCH}.subsystem.description`,
    name: 'Punch: Subsystem Description',
  },
  {
    id: `${RESOURCE_PUNCH}.subsystem.phase`,
    name: 'Punch: Subsystem Phase',
  },
  {
    id: `${RESOURCE_PUNCH}.subsystem.systemId`,
    name: 'Punch: System',
  },
  {
    id: `${RESOURCE_PUNCH}.subsystem.system.description`,
    name: 'Punch: System Description',
  },
  {
    id: `${RESOURCE_PUNCH}.priorityId`,
    name: 'Punch: Priority',
  },
  {
    id: `${RESOURCE_PUNCH}.raisedBy`,
    name: 'Punch: Raised By',
  },
  {
    id: `${RESOURCE_PUNCH}.raisedDate`,
    name: 'Punch: Raised Date',
  },
  {
    id: `${RESOURCE_PUNCH}.clearedBy`,
    name: 'Punch: Cleared By',
  },
  {
    id: `${RESOURCE_PUNCH}.clearedDate`,
    name: 'Punch: Cleared Date',
  },
  {
    id: `${RESOURCE_PUNCH}.acceptedBy`,
    name: 'Punch: Accepted By',
  },
  {
    id: `${RESOURCE_PUNCH}.acceptedDate`,
    name: 'Punch: Accepted Date',
  },
  {
    id: `${RESOURCE_PUNCH}.responsible`,
    name: 'Punch: Responsible',
  },
  {
    id: `${RESOURCE_PUNCH}.punchCatId`,
    name: 'Punch: Category',
  },
  {
    id: `${RESOURCE_PUNCH}.locationId`,
    name: 'Punch: Location',
  },
  {
    id: `${RESOURCE_PUNCH}.requiredDate`,
    name: 'Punch: Required Date',
  },
  {
    id: `${RESOURCE_PUNCH}.estimatedClearDate`,
    name: 'Punch: Estimated Clear Date',
  },
  {
    id: `${RESOURCE_PUNCH}.disciplineId`,
    name: 'Punch: Discipline',
  },
  {
    id: `${RESOURCE_PUNCH}.actionee`,
    name: 'Punch: Actionee',
  },
  {
    id: `${RESOURCE_PUNCH}.poRmaNo`,
    name: 'Punch: Po Rma No',
  },
  {
    id: `${RESOURCE_PUNCH}.vendorId`,
    name: 'Punch: Vendor',
  },
  {
    id: `${RESOURCE_PUNCH}.workpackOverride`,
    name: 'Punch: Workpack Override',
  },
  {
    id: `${RESOURCE_PUNCH}.component.equipTypeId`,
    name: 'Punch: Component Equipment Type Id',
  },
  {
    id: `${RESOURCE_PUNCH}.correctiveAction`,
    name: 'Punch: Corrective Action',
  },
  {
    id: `${RESOURCE_PUNCH}.cfText01`,
    name: 'Punch: Custom Field Text 1',
  },
  {
    id: `${RESOURCE_PUNCH}.cfText02`,
    name: 'Punch: Custom Field Text 2',
  },
  {
    id: `${RESOURCE_PUNCH}.cfText03`,
    name: 'Punch: Custom Field Text 3',
  },
  {
    id: `${RESOURCE_PUNCH}.cfText04`,
    name: 'Punch: Custom Field Text 4',
  },
  {
    id: `${RESOURCE_PUNCH}.cfText05`,
    name: 'Punch: Custom Field Text 5',
  },
];

export const fieldChoices = [
  ...preservationItemActivityFieldChoices,
  ...punchFieldChoices,
  ...positionFieldChoices,
  ...smartPreservationItemActivityFieldChoices,
];
