import React, { ReactElement, useMemo } from 'react';
import { AutocompleteInput } from 'react-admin';
import { getContext, UserContext } from '../../provider/userContext';
import { required } from '../../utils/UtilityFunctions';
import { RESOURCE_SYSTEM } from '../constants';
import AutocompleteReferenceInput from './AutocompleteReferenceInput';

export interface SystemInputProps
  extends React.ComponentProps<typeof AutocompleteInput> {
  isRequired?: boolean;
  alwaysOn?: boolean;
  multiple?: boolean;
  fullWidth?: boolean;
  sx?: object;
  source?: string;
  label?: string;
}

const SystemInput: React.FC<SystemInputProps> = ({
  isRequired,
  ...rest
}): ReactElement => {
  const ctx: UserContext = getContext();
  const requestParams = useMemo(() => {
    return {
      filter: {
        projectId: ctx?.projectId,
      },
      sort: { field: 'systemName', order: 'ASC' as const },
    };
  }, [ctx?.projectId]);

  return (
    <AutocompleteReferenceInput
      {...rest}
      resource={RESOURCE_SYSTEM}
      requestParams={requestParams}
      name="systemName_inc"
      optionText="systemName"
      validate={isRequired ? [required()] : []}
      includeFields={['id', 'systemName']}
    />
  );
};

SystemInput.defaultProps = { source: 'systemId', label: 'System' };

export default SystemInput;
