import React, { useCallback, useState, Fragment, ReactElement } from 'react';
import { Autocomplete, TextField } from '@mui/material';
import { getContext, UserContext } from '../../../provider/userContext';
import commonStyles from '../../common/commonStyles';
import useChangeCompany from '../hooks/useChangeCompany';

const CompanySwitch: React.FC = (): ReactElement => {
  const ctx: UserContext = getContext();
  const changeCompany = useChangeCompany();

  const companies = Object.keys(ctx?.companies || {})
    .map((company) => {
      if (!ctx?.companies?.[company]?.isCompanyAccessDisabled) {
        return {
          id: company,
          company:
            ctx?.companies[company].longName || ctx?.companies[company].name,
          uiSettings: ctx?.companies[company].uiSettings,
        };
      } else {
        return null;
      }
    })
    .filter((company) => company)
    .sort((a, b) => a.id.localeCompare(b.id));
  const [currentCompany, setCurrentCompany] = useState({
    id: ctx?.company,
    company:
      ctx?.companies[ctx.company].longName || ctx?.companies[ctx.company].name,
    uiSettings: ctx?.companies[ctx.company]?.uiSettings,
  });

  const handleSwitchChange = useCallback(
    async (_e, value) => {
      if (ctx?.company !== value.id) {
        setCurrentCompany(value);
        await changeCompany(value.id);
      }
    },
    [ctx, changeCompany]
  );

  return (
    <Fragment>
      {companies?.length > 1 && (
        <Autocomplete
          value={currentCompany}
          onChange={handleSwitchChange}
          options={companies}
          getOptionLabel={(option) => option.company}
          isOptionEqualToValue={(option, value) =>
            option.company === value.company
          }
          renderInput={(params) => (
            <TextField {...params} label="Select Company" />
          )}
          disableClearable
          sx={commonStyles.flexBoxLongItem}
        />
      )}
    </Fragment>
  );
};

export default CompanySwitch;
