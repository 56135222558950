import React from 'react';
import { TextInput, TextInputProps } from 'react-admin';
import { useFormContext } from 'react-hook-form';
import { isImage } from '../../utils/UtilityFunctions';
import { getContext } from '../../provider/userContext';
import SignatureField from './JSONForm/SignatureField';

interface SignatureTextInput extends TextInputProps {
  label: string;
}

const SignatureTextInput: React.FC<SignatureTextInput> = ({
  source,
  label,
  validate,
  ...props
}) => {
  const ctx = getContext();
  const { setValue, watch } = useFormContext();

  const value = watch(source);

  const handleClick = () => {
    if (!value) {
      setValue(source, ctx.fullName, { shouldDirty: true });
    }
  };

  const handleClear = () => {
    setValue(source, '');
  };

  if (isImage(value)) {
    return (
      <SignatureField value={value} label={label} onConfirm={handleClear} />
    );
  }

  return (
    <TextInput
      {...props}
      validate={validate}
      onClick={handleClick}
      source={source}
      label={label}
      value={value}
      InputProps={{ onChange: () => {} }}
      resettable
    />
  );
};

export default SignatureTextInput;
