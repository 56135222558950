import React, { ReactElement } from 'react';
import {
  ReferenceField,
  ReferenceFieldProps,
  TextField,
  useGetOne,
  useRecordContext,
} from 'react-admin';
import useGetLinkType from '../../common/hooks/useGetLinkType';

interface UserProjectNameFieldProps extends ReferenceFieldProps {
  textSource: string;
}

const UserProjectNameField: React.FC<UserProjectNameFieldProps> = ({
  reference,
  textSource,
  ...rest
}): ReactElement => {
  const record = useRecordContext();
  const linkType = useGetLinkType(reference);
  const { data: project } = useGetOne(reference, { id: record.id });
  const link = project?.isActive ? linkType : false;

  return (
    <ReferenceField reference={reference} link={link} {...rest}>
      <TextField source={textSource} />
    </ReferenceField>
  );
};

export default UserProjectNameField;
