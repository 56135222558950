import React, { useState, useCallback, ReactElement, useEffect } from 'react';
import { DatagridConfigurable } from 'react-admin';
import { Dialog, DialogContent, DialogTitle } from '@mui/material';
import {
  AUTH_CONTEXT_KEY,
  getContext,
  UserContext,
} from '../../../provider/userContext';
import FreeTextSearchFilter from '../../common/FreeTextSearchFilter';
import LogoField from '../../common/FileField';
import ListWithTitle from '../../common/ListWithTitle';
import { RESOURCE_PROJECT } from '../../constants';
import ProjectSideFilters from '../components/ProjectSideFilters';
import ProjectTextField from './ProjectTextField';
import FavoriteField from './FavoriteField';
import ColumnActions from './ColumnActions';
import CompanySwitch from './CompanySwitch';

interface ProjectSelectionProps {
  title: string;
  open: boolean;
  handleClose: (event?, reason?: string) => void;
}

//ToDo: When user clicks Select Button, we need to set company, project header and call /user/my/details end-point again.
// With company and project we'll get effective permissions and roles and update the context.
const ProjectSelection: React.FC<ProjectSelectionProps> = ({
  title,
  open,
  handleClose,
}): ReactElement => {
  const ctx: UserContext = getContext();
  const [filter, setFilter] = useState(null);

  const getDefaultFilter = (ctx) => {
    if (!ctx?.company) return {};
    return {
      isActive: true,
      id: ctx?.companies?.[ctx?.company]?.projectIds,
      divisionId: ctx?.companies?.[ctx?.company]?.defaultDivisionId,
    };
  };

  const getFavoriteProjects = (ctx) => {
    if (!ctx?.company) return [];
    return (ctx?.companies?.[ctx?.company]?.favoriteProjects || []).filter(
      (id) => ctx?.companies?.[ctx?.company]?.projectIds.includes(id)
    );
  };
  const [defaultFilter, setDefaultFilter] = useState(getDefaultFilter(ctx));
  const [favoriteProjects, setFavoriteProjects] = useState(
    getFavoriteProjects(ctx)
  );

  const handleFavorite = useCallback(
    (val) => {
      setFilter(val ? { id: favoriteProjects } : null);
    },
    [setFilter, favoriteProjects]
  );

  useEffect(() => {
    const listenContextChange = () => {
      const newCtx: UserContext = getContext();
      if (!newCtx?.company) return;

      setDefaultFilter(getDefaultFilter(newCtx));
      setFavoriteProjects(getFavoriteProjects(newCtx));
    };

    window.addEventListener(AUTH_CONTEXT_KEY, listenContextChange);
    return () => {
      window.removeEventListener(AUTH_CONTEXT_KEY, listenContextChange);
    };
  }, []);

  if (!ctx?.company) {
    return null;
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="xl"
      fullWidth
      disableEscapeKeyDown={true}
    >
      <DialogTitle
        sx={{
          background: '#2bab2b',
          color: 'white',
          paddingTop: '5px',
          paddingBottom: '5px',
        }}
      >
        {title}
      </DialogTitle>
      <DialogContent>
        <ListWithTitle
          resource={RESOURCE_PROJECT}
          actions={<CompanySwitch />}
          filters={<FreeTextSearchFilter />}
          filterDefaultValues={defaultFilter}
          filter={filter ? filter : {}}
          sort={{ field: 'project', order: 'ASC' }}
          storeKey="projectSelection"
          aside={
            <ProjectSideFilters
              isAdminList={false}
              handleFavorite={handleFavorite}
              defaultFilter={defaultFilter}
            />
          }
          sx={{ height: '100%' }}
        >
          <DatagridConfigurable
            preferenceKey="projectSelection"
            bulkActionButtons={false}
            rowClick={false}
          >
            <FavoriteField
              label="My Favorite"
              favoriteProjects={favoriteProjects}
            />
            <LogoField
              source="attachment"
              label="Logo"
              width={100}
              height={50}
              sortable={false}
            />
            <ProjectTextField label="Project" />
            <ColumnActions label="Actions" handleClose={handleClose} />
          </DatagridConfigurable>
        </ListWithTitle>
      </DialogContent>
    </Dialog>
  );
};

export default ProjectSelection;
