import { SvgIcon } from '@mui/material';
import { securityLevel } from '../common/constants';
import {
  RESOURCE_ACTIVITY_TYPE,
  RESOURCE_AUDIT,
  RESOURCE_DICTIONARY,
  RESOURCE_DISCIPLINE,
  RESOURCE_DIVISION,
  RESOURCE_DRAWING_TYPE,
  RESOURCE_EQUIPMENT_TYPE,
  RESOURCE_GROUP,
  RESOURCE_LOCATION,
  RESOURCE_MODULE,
  RESOURCE_POSITION,
  RESOURCE_PROJECT,
  RESOURCE_PUNCH_CATEGORY,
  RESOURCE_PUNCH_PRIORITY,
  RESOURCE_SECURITY_GROUP,
  RESOURCE_SECURITY_ROLE,
  RESOURCE_SECURITY_ROLE_PERMISSION,
  RESOURCE_STRUCTURE_ASSET,
  RESOURCE_STRUCTURE_ASSET_GROUP,
  RESOURCE_STRUCTURE_REGION,
  RESOURCE_SUBSYSTEM,
  RESOURCE_SYSTEM,
  RESOURCE_TEST_TYPE,
  RESOURCE_USER,
  RESOURCE_VENDOR,
  RESOURCE_WORK_PACK,
  RESOURCE_DISCIPLINE_TEST,
  RESOURCE_ATTACHMENT,
  RESOURCE_USER_GROUP,
  RESOURCE_COMPONENT,
  RESOURCE_DRAWING_DISCIPLINE,
  RESOURCE_DRAWING,
  RESOURCE_IMPORT,
  RESOURCE_CONTACT,
  RESOURCE_BOOK,
  RESOURCE_COMPONENT_TEST,
  RESOURCE_TEST,
  RESOURCE_PRESERVATION_ITEM,
  RESOURCE_MOC_TYPE,
  RESOURCE_PUNCH,
  RESOURCE_ACTIVITY_PHASE,
  RESOURCE_PRESERVATION_SERIES,
  RESOURCE_PRESERVATION_ITEM_ACTIVITY,
  RESOURCE_MOC,
  RESOURCE_CERT_TEMPLATE,
  RESOURCE_CERT_GATING,
  RESOURCE_DRAWING_PREFIX,
  RESOURCE_ASSIGN_ITR,
  RESOURCE_SMART_TAG_ITR_REVIEW,
  RESOURCE_CONFIGURATION,
  RESOURCE_SMART_TAG_ITR,
  CUSTOM_ROUTE_BOOK_GENERATION,
  RESOURCE_QR_SCANNER,
  RESOURCE_PRINTED_BOOK,
  CUSTOM_ROUTE_QR_CODE_GENERATION,
  RESOURCE_REPORT,
  RESOURCE_ATTACHMENT_TYPE,
  RESOURCE_PUNCH_TEMPLATE,
  RESOURCE_SMART_CERT_DETAIL,
  RESOURCE_SUBMIT_PUNCH,
  RESOURCE_LUCID_DIAGRAM,
  RESOURCE_SMART_PRESERVATION_ITEM_ACTIVITY,
  RESOURCE_MATERIAL_TYPE,
  RESOURCE_STORAGE,
  RESOURCE_MATERIAL,
  RESOURCE_MATERIAL_RELEASED,
  RESOURCE_MATERIAL_PURCHASE_ORDER,
  RESOURCE_TEST_EQUIPMENT_TYPE,
  RESOURCE_ACTIVITY,
  RESOURCE_TEST_EQUIPMENT,
  RESOURCE_TEST_EQUIPMENT_CHECKOUT,
  RESOURCE_SMART_PUNCH_REVIEW,
  RESOURCE_CERT_DETAIL,
  CUSTOM_ROUTE_GANTT_CHART,
  CUSTOM_ROUTE_FPMS,
} from '../constants';
import { ReactComponent as AdminIcon } from '../../assets/icons/navigationIcons/Admin.svg';
import { ReactComponent as CertificatesIcon } from '../../assets/icons/navigationIcons/Certificates.svg';
import { ReactComponent as DashboardIcon } from '../../assets/icons/navigationIcons/Dashboard.svg';
import { ReactComponent as DrawingsIcon } from '../../assets/icons/navigationIcons/Drawings.svg';
import { ReactComponent as ITRsIcon } from '../../assets/icons/navigationIcons/ITRs.svg';
import { ReactComponent as MOCIcon } from '../../assets/icons/navigationIcons/MOC.svg';
import { ReactComponent as PreservationIcon } from '../../assets/icons/navigationIcons/Preservation.svg';
import { ReactComponent as PunchListIcon } from '../../assets/icons/navigationIcons/PunchList.svg';
import { ReactComponent as SystemsTagsIcon } from '../../assets/icons/navigationIcons/SystemsTags.svg';
import { ReactComponent as TagITRIcon } from '../../assets/icons/navigationIcons/TagITR.svg';
import { ReactComponent as ActivitiesIcon } from '../../assets/icons/navigationIcons/Activities.svg';
import { ReactComponent as QRIcon } from '../../assets/icons/navigationIcons/QRCode.svg';
import { ReactComponent as ReportsIcon } from '../../assets/icons/navigationIcons/Reports.svg';
import { ReactComponent as SmartITRIcon } from '../../assets/icons/navigationIcons/SmartITR.svg';
import { ReactComponent as DiagramsIcon } from '../../assets/icons/navigationIcons/Diagrams.svg';
import { ReactComponent as MatManagementIcon } from '../../assets/icons/navigationIcons/MatManagement.svg';
import { ReactComponent as TestEquipmentIcon } from '../../assets/icons/navigationIcons/TestEquipment.svg';

export const menuItems = [
  {
    id: 'dashboard',
    name: 'dashboard',
    to: '/',
    label: 'Dashboard',
    icon: (
      <SvgIcon>
        <DashboardIcon />
      </SvgIcon>
    ),
  },
  {
    id: 'fpms',
    name: 'fpms',
    label: 'FPMS',
    icon: (
      <SvgIcon>
        <ITRsIcon />
      </SvgIcon>
    ),
    items: [
      {
        id: 'fpms',
        label: 'FPMS',
        to: '/fpms',
        resource: CUSTOM_ROUTE_FPMS,
      },
    ],
  },
  {
    id: 'activities',
    name: 'activities',
    label: 'Activities',
    icon: (
      <SvgIcon>
        <ActivitiesIcon />
      </SvgIcon>
    ),
    items: [
      {
        id: 'activity',
        label: 'Activities',
        to: `/${RESOURCE_ACTIVITY}`,
        resource: RESOURCE_ACTIVITY,
      },
      {
        id: 'gantt-chart',
        label: 'Gantt Chart',
        to: `/${CUSTOM_ROUTE_GANTT_CHART}`,
        resource: CUSTOM_ROUTE_GANTT_CHART,
      },
      {
        id: 'cert-detail',
        label: 'Certificate Details',
        to: `/${RESOURCE_CERT_DETAIL}`,
        resource: RESOURCE_CERT_DETAIL,
      },
    ],
  },
  {
    id: 'system_menu',
    name: 'systems',
    label: 'Systems & Tags',
    icon: (
      <SvgIcon>
        <SystemsTagsIcon />
      </SvgIcon>
    ),
    items: [
      {
        id: 'systems_menu_item',
        label: 'Systems',
        to: `/${RESOURCE_SYSTEM}`,
        resource: RESOURCE_SYSTEM,
      },
      {
        id: 'subsystems_menu_item',
        label: 'SubSystems',
        to: `/${RESOURCE_SUBSYSTEM}`,
        resource: RESOURCE_SUBSYSTEM,
      },
      {
        id: 'component_menu_item',
        label: 'Components',
        to: `/${RESOURCE_COMPONENT}`,
        resource: RESOURCE_COMPONENT,
      },
    ],
  },
  {
    id: 'drawings',
    name: 'drawings',
    label: 'Drawings',
    icon: (
      <SvgIcon>
        <DrawingsIcon />
      </SvgIcon>
    ),
    items: [
      {
        id: 'drawing_list',
        label: 'Drawing List',
        to: `/${RESOURCE_DRAWING}`,
        resource: RESOURCE_DRAWING,
      },
      {
        id: 'drawing_prefix',
        label: 'Drawing Prefix',
        to: `/${RESOURCE_DRAWING_PREFIX}`,
        resource: RESOURCE_DRAWING_PREFIX,
      },
    ],
  },
  {
    id: 'punch',
    name: 'punch',
    label: 'Punch',
    icon: (
      <SvgIcon>
        <PunchListIcon />
      </SvgIcon>
    ),
    items: [
      {
        id: 'punch_list',
        label: 'Punch List',
        to: `/${RESOURCE_PUNCH}`,
        resource: RESOURCE_PUNCH,
      },
      {
        id: 'submit_punch_list',
        label: 'Submit Punch',
        to: `/${RESOURCE_SUBMIT_PUNCH}`,
        resource: RESOURCE_SUBMIT_PUNCH,
      },
      {
        id: 'smart_punch_review',
        label: 'Smart Punch Review',
        to: `/${RESOURCE_SMART_PUNCH_REVIEW}`,
        resource: RESOURCE_SMART_PUNCH_REVIEW,
      },
      {
        id: 'punch_template',
        label: 'Punch Templates',
        to: `/${RESOURCE_PUNCH_TEMPLATE}`,
        resource: RESOURCE_PUNCH_TEMPLATE,
      },
    ],
  },
  {
    id: 'Preservation',
    name: 'preservation',
    label: 'Preservation',
    icon: (
      <SvgIcon>
        <PreservationIcon />
      </SvgIcon>
    ),
    items: [
      {
        id: 'preservationItem',
        label: 'Preservation Item',
        to: `/${RESOURCE_PRESERVATION_ITEM}`,
        resource: RESOURCE_PRESERVATION_ITEM,
      },
      {
        id: 'preservationSeries',
        label: 'Preservation Series',
        to: `/${RESOURCE_PRESERVATION_SERIES}`,
        resource: RESOURCE_PRESERVATION_SERIES,
      },
      {
        id: 'smartPreservationItemActivity',
        label: 'Smart Preservation',
        to: `/${RESOURCE_SMART_PRESERVATION_ITEM_ACTIVITY}`,
        resource: RESOURCE_SMART_PRESERVATION_ITEM_ACTIVITY,
      },
      {
        id: 'preservationItemActivity',
        label: 'Preservation Item Activity',
        to: `/${RESOURCE_PRESERVATION_ITEM_ACTIVITY}`,
        resource: RESOURCE_PRESERVATION_ITEM_ACTIVITY,
      },
    ],
  },
  {
    id: 'itrs',
    name: 'itrs',
    label: 'ITRs',
    icon: (
      <SvgIcon>
        <ITRsIcon />
      </SvgIcon>
    ),
    items: [
      {
        id: 'test',
        label: 'ITR Templates',
        to: `/${RESOURCE_TEST}`,
        resource: RESOURCE_TEST,
      },
      {
        id: 'equipment_types',
        label: 'Equipment Types',
        to: `/${RESOURCE_EQUIPMENT_TYPE}`,
        resource: RESOURCE_EQUIPMENT_TYPE,
      },
    ],
  },
  {
    id: 'tag_itr',
    name: 'tag_itr',
    label: 'Tag ITR',
    icon: (
      <SvgIcon>
        <TagITRIcon />
      </SvgIcon>
    ),
    items: [
      {
        id: 'component_test',
        label: 'Tag ITRs',
        to: `/${RESOURCE_COMPONENT_TEST}`,
        resource: RESOURCE_COMPONENT_TEST,
      },
      {
        id: 'assign_itr',
        label: 'Assign ITRs',
        to: `/${RESOURCE_ASSIGN_ITR}`,
        resource: RESOURCE_ASSIGN_ITR,
      },
    ],
  },
  {
    id: 'smart_tag_itr',
    name: 'smart_tag_itr',
    label: 'Smart Tag ITR',
    icon: (
      <SvgIcon>
        <SmartITRIcon />
      </SvgIcon>
    ),
    items: [
      {
        id: 'smart_tag_itr_page',
        label: 'Smart Tag ITRs',
        to: `/${RESOURCE_SMART_TAG_ITR}`,
        resource: RESOURCE_SMART_TAG_ITR,
      },
      {
        id: 'smart_tag_itr_review',
        label: 'Review Smart Tag ITRs',
        to: `/${RESOURCE_SMART_TAG_ITR_REVIEW}`,
        resource: RESOURCE_SMART_TAG_ITR_REVIEW,
      },
    ],
  },
  {
    id: 'test_equipments',
    name: 'test_equipments',
    label: 'Test Equipment',
    icon: (
      <SvgIcon>
        <TestEquipmentIcon />
      </SvgIcon>
    ),
    items: [
      {
        id: 'test_equipment_page',
        label: 'Test Equipment',
        to: `/${RESOURCE_TEST_EQUIPMENT}`,
        resource: RESOURCE_TEST_EQUIPMENT,
      },
      {
        id: 'test_equipment_checkout_page',
        label: 'Test Equipment Checkout',
        to: `/${RESOURCE_TEST_EQUIPMENT_CHECKOUT}`,
        resource: RESOURCE_TEST_EQUIPMENT_CHECKOUT,
      },
    ],
  },
  {
    id: 'qr_scanner',
    name: 'qr_scanner',
    label: 'QR Scanner',
    icon: (
      <SvgIcon>
        <QRIcon />
      </SvgIcon>
    ),
    items: [
      {
        id: 'qr_scanner_import',
        label: 'QR Import Scanner',
        to: `/${RESOURCE_QR_SCANNER}`,
        resource: RESOURCE_QR_SCANNER,
      },
    ],
  },
  {
    id: 'moc',
    name: 'moc',
    label: 'MoC',
    icon: (
      <SvgIcon>
        <MOCIcon />
      </SvgIcon>
    ),
    items: [
      {
        id: 'moc',
        label: 'MoC',
        to: `/${RESOURCE_MOC}`,
        resource: RESOURCE_MOC,
      },
      {
        id: 'moc-type',
        label: 'MoC Type',
        to: `/${RESOURCE_MOC_TYPE}`,
        resource: RESOURCE_MOC_TYPE,
      },
    ],
  },
  {
    id: 'material_management',
    name: 'material_management',
    label: 'Material Management',
    icon: (
      <SvgIcon>
        <MatManagementIcon />
      </SvgIcon>
    ),
    items: [
      {
        id: 'material_list',
        label: 'Material List',
        to: `/${RESOURCE_MATERIAL}`,
        resource: RESOURCE_MATERIAL,
      },
      {
        id: 'material-released',
        label: 'Released Materials',
        to: `/${RESOURCE_MATERIAL_RELEASED}`,
        resource: RESOURCE_MATERIAL_RELEASED,
      },
      {
        id: 'po_requests_list',
        label: 'PO Requests',
        to: `/${RESOURCE_MATERIAL_PURCHASE_ORDER}`,
        resource: RESOURCE_MATERIAL_PURCHASE_ORDER,
      },
    ],
  },
  {
    id: 'cert',
    name: 'certificates',
    label: 'Certificates',
    icon: (
      <SvgIcon>
        <CertificatesIcon />
      </SvgIcon>
    ),
    items: [
      {
        id: 'cert-details',
        label: 'Certificate Details',
        to: `/${RESOURCE_CERT_DETAIL}`,
        resource: RESOURCE_CERT_DETAIL,
      },
      {
        id: 'smart-cert-detail',
        label: 'Smart Certificates',
        to: `/${RESOURCE_SMART_CERT_DETAIL}`,
        resource: RESOURCE_SMART_CERT_DETAIL,
      },
      {
        id: 'cert-template',
        label: 'Templates',
        to: `/${RESOURCE_CERT_TEMPLATE}`,
        resource: RESOURCE_CERT_TEMPLATE,
      },
      {
        id: 'cert-gating',
        label: 'Gating',
        to: `/${RESOURCE_CERT_GATING}`,
        resource: RESOURCE_CERT_GATING,
      },
    ],
  },
  {
    id: 'report',
    name: 'report',
    label: 'Report',
    icon: (
      <SvgIcon>
        <ReportsIcon />
      </SvgIcon>
    ),
    to: `/${RESOURCE_REPORT}`,
  },
  {
    id: 'diagrams_menu',
    name: 'diagrams_menu',
    label: 'Diagrams',
    icon: (
      <SvgIcon>
        <DiagramsIcon />
      </SvgIcon>
    ),
    items: [
      {
        id: 'diagram_list',
        label: 'Diagram List',
        to: `/${RESOURCE_LUCID_DIAGRAM}`,
        resource: RESOURCE_LUCID_DIAGRAM,
      },
    ],
  },
  {
    id: 'admin_menu',
    name: 'admin_menu',
    label: 'Admin',
    icon: (
      <SvgIcon>
        <AdminIcon />
      </SvgIcon>
    ),
    items: [
      {
        id: 'audit',
        label: 'Audit',
        to: `/${RESOURCE_AUDIT}`,
        resource: RESOURCE_AUDIT,
      },
      {
        id: 'attachments',
        label: 'Attachments',
        to: `/${RESOURCE_ATTACHMENT}`,
        resource: RESOURCE_ATTACHMENT,
        leastSecurityLevel: securityLevel.fieldAdmin,
      },
      {
        id: 'imports',
        label: 'Imports',
        to: `/${RESOURCE_IMPORT}`,
        resource: RESOURCE_IMPORT,
      },
      {
        id: 'qr_generation',
        label: 'QR Code Generate',
        to: `/${CUSTOM_ROUTE_QR_CODE_GENERATION}`,
        resource: CUSTOM_ROUTE_QR_CODE_GENERATION,
      },
      {
        id: 'books_menu',
        name: 'books_menu',
        label: 'Books',
        items: [
          {
            id: 'books',
            label: 'Books',
            to: `/${RESOURCE_BOOK}`,
            resource: RESOURCE_BOOK,
          },
          {
            id: 'bookgen',
            label: 'Book Generate',
            to: `/${CUSTOM_ROUTE_BOOK_GENERATION}`,
            resource: CUSTOM_ROUTE_BOOK_GENERATION,
          },
          {
            id: 'printed_books',
            label: 'Printed Books',
            to: `/${RESOURCE_PRINTED_BOOK}`,
            resource: RESOURCE_PRINTED_BOOK,
          },
        ],
      },
      {
        id: 'support_menu',
        name: 'support_menu',
        label: 'Support Tables',
        items: [
          {
            id: 'activity_type',
            label: 'Activity Type',
            to: `/${RESOURCE_ACTIVITY_TYPE}`,
            resource: RESOURCE_ACTIVITY_TYPE,
          },
          {
            id: 'activity_phase',
            label: 'Activity Phase',
            to: `/${RESOURCE_ACTIVITY_PHASE}`,
            resource: RESOURCE_ACTIVITY_PHASE,
          },
          {
            id: 'attachment_types',
            label: 'Book Section',
            to: `/${RESOURCE_ATTACHMENT_TYPE}`,
            resource: RESOURCE_ATTACHMENT_TYPE,
          },
          {
            id: 'contacts',
            label: 'Contacts',
            to: `/${RESOURCE_CONTACT}`,
            resource: RESOURCE_CONTACT,
          },
          {
            id: 'dictionary',
            label: 'Dictionaries',
            to: `/${RESOURCE_DICTIONARY}`,
            resource: RESOURCE_DICTIONARY,
          },
          {
            id: 'disciplines',
            label: 'Disciplines',
            to: `/${RESOURCE_DISCIPLINE}`,
            resource: RESOURCE_DISCIPLINE,
          },
          {
            id: 'discipline_tests',
            label: 'Discipline Tests',
            to: `/${RESOURCE_DISCIPLINE_TEST}`,
            resource: RESOURCE_DISCIPLINE_TEST,
          },
          {
            id: 'drawing_types',
            label: 'Drawing Types',
            to: `/${RESOURCE_DRAWING_TYPE}`,
            resource: RESOURCE_DRAWING_TYPE,
          },
          {
            id: 'drawing_disciplines',
            label: 'Drawing Disciplines',
            to: `/${RESOURCE_DRAWING_DISCIPLINE}`,
            resource: RESOURCE_DRAWING_DISCIPLINE,
          },
          {
            id: 'groups',
            label: 'Groups',
            to: `/${RESOURCE_GROUP}`,
            resource: RESOURCE_GROUP,
          },
          {
            id: 'locations',
            label: 'Locations',
            to: `/${RESOURCE_LOCATION}`,
            resource: RESOURCE_LOCATION,
          },
          {
            id: 'material_types',
            label: 'Material Types',
            to: `/${RESOURCE_MATERIAL_TYPE}`,
            resource: RESOURCE_MATERIAL_TYPE,
          },
          {
            id: 'modules',
            label: 'Modules',
            to: `/${RESOURCE_MODULE}`,
            resource: RESOURCE_MODULE,
          },
          {
            id: 'positions',
            label: 'Positions',
            to: `/${RESOURCE_POSITION}`,
            resource: RESOURCE_POSITION,
          },
          {
            id: 'punch_categories',
            label: 'Punch Categories',
            to: `/${RESOURCE_PUNCH_CATEGORY}`,
            resource: RESOURCE_PUNCH_CATEGORY,
          },
          {
            id: 'punch_priorities',
            label: 'Punch Priorities',
            to: `/${RESOURCE_PUNCH_PRIORITY}`,
            resource: RESOURCE_PUNCH_PRIORITY,
          },
          {
            id: 'storages',
            label: 'Storages',
            to: `/${RESOURCE_STORAGE}`,
            resource: RESOURCE_STORAGE,
          },
          {
            id: 'test_equipment_type',
            label: 'Test Equipment Types',
            to: `/${RESOURCE_TEST_EQUIPMENT_TYPE}`,
            resource: RESOURCE_TEST_EQUIPMENT_TYPE,
          },
          {
            id: 'test_types',
            label: 'Test Types',
            to: `/${RESOURCE_TEST_TYPE}`,
            resource: RESOURCE_TEST_TYPE,
          },
          {
            id: 'vendors',
            label: 'Vendors',
            to: `/${RESOURCE_VENDOR}`,
            resource: RESOURCE_VENDOR,
          },
          {
            id: 'work_packs',
            label: 'Work Packs',
            to: `/${RESOURCE_WORK_PACK}`,
            resource: RESOURCE_WORK_PACK,
          },
        ],
      },
    ],
  },
  {
    id: 'global_menu',
    name: 'global_menu',
    label: 'Global Settings',
    icon: (
      <SvgIcon>
        <AdminIcon />
      </SvgIcon>
    ),
    leastSecurityLevel: Math.max(
      securityLevel.fieldAdmin,
      securityLevel.clientAdmin
    ),
    items: [
      {
        id: 'configuration',
        label: 'Configuration',
        to: `/${RESOURCE_CONFIGURATION}`,
        resource: RESOURCE_CONFIGURATION,
      },
      {
        id: 'users',
        label: 'Users',
        to: `/${RESOURCE_USER}`,
        resource: RESOURCE_USER,
      },
      {
        id: 'user_groups',
        label: 'User Groups',
        to: `/${RESOURCE_USER_GROUP}`,
        resource: RESOURCE_USER_GROUP,
      },
      {
        id: 'divisions',
        label: 'Divisions',
        to: `/${RESOURCE_DIVISION}`,
        resource: RESOURCE_DIVISION,
      },
      {
        id: 'structure_menu',
        name: 'structure_menu',
        label: 'Project Sectors',
        items: [
          {
            id: 'assets',
            label: 'Assets',
            to: `/${RESOURCE_STRUCTURE_ASSET}`,
            resource: RESOURCE_STRUCTURE_ASSET,
          },
          {
            id: 'asset_groups',
            label: 'Asset Groups',
            to: `/${RESOURCE_STRUCTURE_ASSET_GROUP}`,
            resource: RESOURCE_STRUCTURE_ASSET_GROUP,
          },
          {
            id: 'regions',
            label: 'Regions',
            to: `/${RESOURCE_STRUCTURE_REGION}`,
            resource: RESOURCE_STRUCTURE_REGION,
          },
        ],
      },
      {
        id: 'projects',
        label: 'Projects',
        to: `/${RESOURCE_PROJECT}`,
        resource: RESOURCE_PROJECT,
      },
      {
        id: 'security_menu',
        name: 'security',
        label: 'Security',
        items: [
          {
            id: 'security_roles',
            label: 'Roles',
            to: `/${RESOURCE_SECURITY_ROLE}`,
            resource: RESOURCE_SECURITY_ROLE,
          },
          {
            id: 'security_role_permissions',
            label: 'Role Permissions',
            to: `/${RESOURCE_SECURITY_ROLE_PERMISSION}`,
            resource: RESOURCE_SECURITY_ROLE_PERMISSION,
          },
          {
            id: 'security_groups',
            label: 'Groups',
            to: `/${RESOURCE_SECURITY_GROUP}`,
            resource: RESOURCE_SECURITY_GROUP,
          },
        ],
      },
    ],
  },
];

export const MENU_ITEM_PREFIX = 'RaMenuItem';

export interface MenuItemProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  menuItems?: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  menuItem?: any;
  menuName?: string;
}
