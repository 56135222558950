import React, { ReactElement } from 'react';
import commonStyles from '../../common/commonStyles';
import ComponentInput from '../../common/ComponentInput';
import { activityCategory } from '../../common/constants';
import SubsystemInput from '../../common/SubsystemInput';
import SystemInput from '../../common/SystemInput';
import TextInput from '../../common/TextInput';

interface EntityInputProps {
  category: string;
  isRequired: boolean;
}

const EntityInput: React.FC<EntityInputProps> = ({
  category,
  isRequired,
}): ReactElement => {
  const categoryInputProps = {
    sx: commonStyles.flexBoxItem,
    label: 'Category Name',
    isRequired,
  };

  switch (category) {
    case activityCategory.system:
      return <SystemInput {...categoryInputProps} />;
    case activityCategory.subSystem:
      return <SubsystemInput {...categoryInputProps} />;
    case activityCategory.component:
      return <ComponentInput {...categoryInputProps} />;
    default:
      return (
        <TextInput
          name="entity"
          source="entity"
          label="Entity"
          disabled
          sx={commonStyles.flexBoxItem}
        />
      );
  }
};

export default EntityInput;
