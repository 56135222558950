import { SxProps, Theme } from '@mui/material/styles';
import React, { Fragment, ReactElement, useCallback } from 'react';
import { Dayjs } from 'dayjs';
import CustomDatePicker from './CustomDatePicker';

interface DateRangeFilterProps {
  label: string;
  source: string;
  dateFrom: { [key: string]: Dayjs };
  dateTo: { [key: string]: Dayjs };
  handleDateFromChange: (value: Dayjs, type: string) => void;
  handleDateToChange: (value: Dayjs, type: string) => void;
  alwaysOn?: boolean;
  sx?: SxProps<Theme>;
  fullWidth?: boolean;
}

const DateRangeFilter: React.FC<DateRangeFilterProps> = ({
  label,
  source,
  dateFrom,
  dateTo,
  handleDateFromChange,
  handleDateToChange,
  sx,
  fullWidth,
}): ReactElement => {
  const dateFromChange = useCallback(
    (value) => handleDateFromChange(value, source),
    [handleDateFromChange, source]
  );
  const dateToChange = useCallback(
    (value) => handleDateToChange(value, source),
    [handleDateToChange, source]
  );

  return (
    <Fragment>
      <CustomDatePicker
        label={`${label} From`}
        value={dateFrom[source] || null}
        onChange={dateFromChange}
        maxDate={dateTo[source]}
        fullWidth={fullWidth}
        sx={{ ...sx, mb: 3 }}
      />
      <CustomDatePicker
        label={`${label} To`}
        value={dateTo[source] || null}
        onChange={dateToChange}
        minDate={dateFrom[source]}
        fullWidth={fullWidth}
        sx={sx}
      />
    </Fragment>
  );
};

DateRangeFilter.defaultProps = { alwaysOn: true };

export default DateRangeFilter;
