import React, { ReactElement } from 'react';
import {
  Filter,
  FilterProps,
  usePermissions,
  useResourceContext,
} from 'react-admin';
import { hasFieldShowAccess } from '../../utils/UtilityFunctions';

interface FilterWithCheckAccessProps extends FilterProps {
  children: ReactElement[];
}

const FilterWithCheckAccess: React.FC<FilterWithCheckAccessProps> = ({
  children,
  ...rest
}): ReactElement => {
  const resource = useResourceContext();
  const { permissions } = usePermissions();

  const filteredChildren = children.filter((child) =>
    hasFieldShowAccess(permissions, `${resource}.${child?.props?.source}`)
  );

  return <Filter {...rest}>{filteredChildren}</Filter>;
};

export default FilterWithCheckAccess;
