import React, { useCallback } from 'react';
import { Button, useGetRecordId, useRefresh, useRedirect } from 'react-admin';
import Box from '@mui/material/Box';
import { TextFieldPropsWithOptionalSource } from '../../common/types';
import commonStyles from '../../common/commonStyles';
import useChangeProject from '../hooks/useChangeProject';

const ColumnActions: React.FC<
  TextFieldPropsWithOptionalSource & { handleClose: () => void }
> = ({ handleClose }) => {
  const refresh = useRefresh();
  const redirect = useRedirect();
  const recordId = Number(useGetRecordId());
  const changeProject = useChangeProject();

  const handleSelect = useCallback(async () => {
    await changeProject(recordId);
    handleClose();
    refresh();
    redirect('/');
  }, [recordId, changeProject, handleClose, refresh, redirect]);

  return (
    <Box sx={commonStyles.columnActionsBox}>
      <Button
        onClick={handleSelect}
        label="Select"
        className="projectSelectButton"
      />
    </Box>
  );
};

export default ColumnActions;
