import React from 'react';
import { Helmet } from 'react-helmet';
import { environment } from './provider/api';

const defaultSrc = [
  'localhost:4000',
  '127.0.0.1:4000',
  'api.tracker-check-dev.trackersuite.co',
  'cognito-idp.eu-west-1.amazonaws.com',
  'rev1-dev-v2.auth.eu-west-1.amazoncognito.com',
  '7eshjneyrj.execute-api.eu-west-1.amazonaws.com',
  '*.s3.eu-west-1.amazonaws.com',
  '*.s3.us-east-2.amazonaws.com',
  '*.zipy.ai',
  'tracker-technologies-test.chargebee.com',
  '*.walkme.com',
  '*.powerbi.com',
  'wabi-us-east-a-primary-redirect.analysis.windows.net',
  'c0eb45978d7b4afe9f461b69744068e4.pbidedicated.windows.net',
  'wss://realtime.ably.io',
  'rest.ably.io',
  'internet-up.ably-realtime.com',
  'dc.services.visualstudio.com',
  'newassets.hcaptcha.com',
  'api2.hcaptcha.com',
  'js.hcaptcha.com',
  'js.chargebee.com',
  'maps.googleapis.com',
  'api-gw.metadata.io',
  'id.rlcdn.com',
  'lucid.app',
  '*.lucid.app',
  'wss://event.us.lucid.app',
  '*.rev1energy.com',
];

const scriptSrc = [
  'cdn.walkme.com',
  'app.powerbi.com',
  'content.powerapps.com',
  'code.jquery.com',
  'kendo.cdn.telerik.com',
  'dgkxwewtzsnml.cloudfront.net',
  'js1.chargebee.com',
  'static-assets.lucidchart.com',
];

const styleSrc = [
  '7eshjneyrj.execute-api.eu-west-1.amazonaws.com',
  'fonts.googleapis.com',
  'kendo.cdn.telerik.com',
  'cdn.walkme.com',
  'content.powerapps.com',
  'js.chargebee.com',
  'js1.chargebee.com',
  'd2jxbtsa1l6d79.cloudfront.net',
  'cdn.syncfusion.com',
];

const fontSrc = [
  '7eshjneyrj.execute-api.eu-west-1.amazonaws.com',
  'fonts.gstatic.com',
  'kendo.cdn.telerik.com',
  'content.powerapps.com',
  'cdn-cashy-static-assets.lucidchart.com',
];

const imgSrc = [
  '7eshjneyrj.execute-api.eu-west-1.amazonaws.com',
  '*.s3.eu-west-1.amazonaws.com',
  '*.s3.us-east-2.amazonaws.com',
  'ec.walkme.com',
  'content.powerapps.com',
  'app.powerbi.com',
  'wabi-us-east-a-primary-redirect.analysis.windows.net',
  'maps.gstatic.com',
  's3.walkmeusercontent.com',
  'thumbs.lucid.app',
];

const content = `default-src 'self' ${defaultSrc.join(' ')}; script-src 'self' 'unsafe-inline' 'unsafe-eval' ${scriptSrc.join(' ')}; style-src 'self' 'unsafe-inline' ${styleSrc.join(' ')}; font-src 'self' data: ${fontSrc.join(' ')}; img-src 'self' data: blob: ${imgSrc.join(' ')};`;

const HeaderMeta = () => {
  return (
    <Helmet>
      {environment === 'local' && (
        <meta httpEquiv="Content-Security-Policy" content={content} />
      )}
    </Helmet>
  );
};

export default HeaderMeta;
