export const getEntityType = (record) => {
  if (record.systemId) {
    return 'SYSTEM';
  }
  if (record.subsystemId) {
    return 'SUBSYSTEM';
  }
  if (record.componentId) {
    return 'COMPONENT';
  }
};
