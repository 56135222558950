import React, { ReactElement, useCallback, useState } from 'react';
import { useCreate, useCreateSuggestionContext, useNotify } from 'react-admin';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  TextField,
} from '@mui/material';
import { getContext, UserContext } from '../../../provider/userContext';
import { getErrorMessage } from '../../../utils/UtilityFunctions';
import { RESOURCE_CONTACT } from '../../constants';
import useCurrentProjectSettings from '../hooks/useCurrentProjectSettings';
import useInvalidateResourceQueryCache from '../hooks/useInvalidateResourceQueryCache';
import { ContactCategories } from './constants';
import { ContactType } from './ContactInput';

interface ContactCreateDialogProps {
  listOfContacts: ContactType[];
  category: string;
  initValue?: string;
}

const ContactCreateDialog: React.FC<ContactCreateDialogProps> = ({
  listOfContacts,
  category,
  initValue,
}): ReactElement => {
  const notify = useNotify();
  const ctx: UserContext = getContext();
  const {
    currentProjectSettings: { isPresMobile },
  } = useCurrentProjectSettings();
  const { onCancel, onCreate } = useCreateSuggestionContext();
  const [value, setValue] = useState(initValue || '');
  const [validationError, setValidationError] = useState('');
  const [create] = useCreate();

  const invalidateQueries = useInvalidateResourceQueryCache(
    RESOURCE_CONTACT,
    true
  );

  const handleTextValueChange = useCallback(
    (event) => {
      const { value } = event.target;

      if (
        listOfContacts?.find(
          (item) => item.nickname.toLowerCase() === value.toLowerCase().trim()
        )
      ) {
        setValidationError('Contact already exists');
      } else if (value.trim().length === 0) {
        setValidationError('Required');
      } else {
        setValidationError('');
      }

      setValue(value);
    },
    [listOfContacts]
  );

  const handleSubmit = useCallback(
    (event) => {
      event.preventDefault();

      if (value.trim().length === 0) {
        setValidationError('Required');
      } else {
        create(
          RESOURCE_CONTACT,
          {
            data: {
              nickname: value.trim(),
              projectId: ctx?.projectId,
              category,
              ...(isPresMobile &&
                category === ContactCategories.preservationEmpWorkGrp && {
                  isMobileUser: true,
                }),
            },
          },
          {
            onSuccess: (data) => {
              setValue('');
              onCreate(data);
              invalidateQueries();
            },
            onError: (error) => {
              notify(getErrorMessage(error), {
                type: 'error',
                undoable: false,
              });
            },
          }
        );
      }
    },
    [
      category,
      create,
      ctx?.projectId,
      invalidateQueries,
      isPresMobile,
      notify,
      onCreate,
      value,
    ]
  );

  return (
    <Dialog open onClose={onCancel}>
      <form>
        <DialogContent>
          <TextField
            label="New Contact"
            value={value}
            onChange={handleTextValueChange}
            autoFocus
            error={!!validationError}
            helperText={validationError}
            required={true}
          />
        </DialogContent>
        <DialogActions>
          <Button disabled={!!validationError} onClick={handleSubmit}>
            Save
          </Button>
          <Button onClick={onCancel}>Cancel</Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default ContactCreateDialog;
