import { useRecordContext, useResourceContext } from 'react-admin';
import get from 'lodash/get';
import {
  RESOURCE_ACTIVITY_TYPE,
  RESOURCE_BOOK,
  RESOURCE_COMPONENT,
  RESOURCE_CONTACT,
  RESOURCE_DISCIPLINE,
  RESOURCE_DISCIPLINE_TEST,
  RESOURCE_DIVISION,
  RESOURCE_DRAWING_DISCIPLINE,
  RESOURCE_DRAWING_TYPE,
  RESOURCE_EQUIPMENT_TYPE,
  RESOURCE_GROUP,
  RESOURCE_LOCATION,
  RESOURCE_MODULE,
  RESOURCE_POSITION,
  RESOURCE_PROJECT,
  RESOURCE_PUNCH,
  RESOURCE_PUNCH_CATEGORY,
  RESOURCE_PUNCH_PRIORITY,
  RESOURCE_SECURITY_GROUP,
  RESOURCE_SECURITY_ROLE,
  RESOURCE_STRUCTURE_ASSET,
  RESOURCE_STRUCTURE_ASSET_GROUP,
  RESOURCE_STRUCTURE_REGION,
  RESOURCE_SUBSYSTEM,
  RESOURCE_SYSTEM,
  RESOURCE_TEST_TYPE,
  RESOURCE_USER_GROUP,
  RESOURCE_VENDOR,
  RESOURCE_WORK_PACK,
  RESOURCE_TEST,
  RESOURCE_MOC,
  RESOURCE_MOC_TYPE,
  RESOURCE_DRAWING,
  RESOURCE_CERT_TEMPLATE,
  RESOURCE_CERT_GATING,
  RESOURCE_DRAWING_PREFIX,
  RESOURCE_REPORT,
  RESOURCE_USER,
  RESOURCE_ACTIVITY_PHASE,
  RESOURCE_BOOK_SECTION,
  RESOURCE_SMART_TAG_ITR,
  RESOURCE_SMART_TAG_ITR_REVIEW,
  RESOURCE_SMART_PUNCH_REVIEW,
  RESOURCE_COMPONENT_TEST,
  RESOURCE_PRESERVATION_ITEM,
  RESOURCE_PRESERVATION_ITEM_ACTIVITY,
  RESOURCE_PRESERVATION_SERIES,
  RESOURCE_COMMENT,
  RESOURCE_SUBSYSTEM_DRAWING,
  RESOURCE_ATTACHMENT,
  RESOURCE_CERT_DETAIL,
  RESOURCE_COMPONENT_DRAWING,
  RESOURCE_TEST_POSITION,
  RESOURCE_EQUIPMENT_TYPE_TEST,
  RESOURCE_CERT_GATING_TEST,
  RESOURCE_CERT_GATING_PUNCH,
  RESOURCE_CERT_GATING_PRED_CERT,
  RESOURCE_GROUP_CONTACT,
  RESOURCE_DRAWING_SUBSYSTEM,
  RESOURCE_DRAWING_COMPONENT,
  RESOURCE_SECURITY_ROLE_PERMISSION,
  RESOURCE_ATTACHMENT_TYPE,
  RESOURCE_LUCID_DIAGRAM,
  RESOURCE_SMART_PRESERVATION_ITEM_ACTIVITY,
  RESOURCE_MATERIAL_TYPE,
  RESOURCE_STORAGE,
  RESOURCE_MATERIAL,
  RESOURCE_MATERIAL_COMPONENT,
  RESOURCE_MATERIAL_PURCHASE_ORDER,
  RESOURCE_MATERIAL_RELEASED,
  RESOURCE_MATERIAL_STORAGE,
  RESOURCE_MATERIAL_VENDOR,
  RESOURCE_MATERIAL_RELEASED_MATERIAL_STORAGE,
  RESOURCE_MATERIAL_PURCHASE_ORDER_MATERIAL,
  RESOURCE_PUNCH_TEMPLATE,
  RESOURCE_ACTIVITY,
} from '../../constants';

export const fieldFromResource = {
  [RESOURCE_ACTIVITY_PHASE]: ['phase'],
  [RESOURCE_ACTIVITY_TYPE]: ['activityType'],
  [RESOURCE_PROJECT]: ['project'],
  [RESOURCE_MODULE]: ['moduleName'],
  [RESOURCE_LOCATION]: ['location'],
  [RESOURCE_DISCIPLINE]: ['disciplineCode'],
  [RESOURCE_SYSTEM]: ['systemName'],
  [RESOURCE_WORK_PACK]: ['workPack'],
  [RESOURCE_PUNCH_CATEGORY]: ['category'],
  [RESOURCE_PUNCH_PRIORITY]: ['priority'],
  [RESOURCE_DIVISION]: ['divisionName'],
  [RESOURCE_STRUCTURE_ASSET]: ['asset'],
  [RESOURCE_GROUP]: ['groupName'],
  [RESOURCE_STRUCTURE_REGION]: ['region'],
  [RESOURCE_SUBSYSTEM]: ['subsystem'],
  [RESOURCE_STRUCTURE_ASSET_GROUP]: ['assetGroup'],
  [RESOURCE_TEST_TYPE]: ['testType'],
  [RESOURCE_DRAWING_TYPE]: ['drawingType'],
  [RESOURCE_SECURITY_ROLE]: ['role'],
  [RESOURCE_SECURITY_GROUP]: ['groupName'],
  [RESOURCE_POSITION]: ['position'],
  [RESOURCE_DRAWING_DISCIPLINE]: ['drawingDiscipline'],
  [RESOURCE_VENDOR]: ['vendor'],
  [RESOURCE_EQUIPMENT_TYPE]: ['equipType'],
  [RESOURCE_USER]: ['id'],
  [RESOURCE_DISCIPLINE_TEST]: ['disciplineTestCode'],
  [RESOURCE_USER_GROUP]: ['userGroup'],
  [RESOURCE_COMPONENT_TEST]: [
    'component.tag',
    'test.testFormNo',
    'location.location',
  ],
  [RESOURCE_CONTACT]: ['nickname'],
  [RESOURCE_BOOK]: ['title'],
  [RESOURCE_BOOK_SECTION]: ['section'],
  [RESOURCE_TEST]: ['testFormNo'],
  [RESOURCE_SMART_PUNCH_REVIEW]: ['subsystem.subsystem', 'id'],
  [RESOURCE_PUNCH]: ['punchNo'],
  [RESOURCE_MOC]: ['mocNo'],
  [RESOURCE_MOC_TYPE]: ['mocType'],
  [RESOURCE_DRAWING]: ['drawingName'],
  [RESOURCE_CERT_TEMPLATE]: ['certName'],
  [RESOURCE_CERT_GATING]: ['certName'],
  [RESOURCE_DRAWING_PREFIX]: ['prefix'],
  [RESOURCE_PRESERVATION_ITEM]: [
    'component.tag',
    'test.testFormNo',
    'sequence',
  ],
  [RESOURCE_PRESERVATION_ITEM_ACTIVITY]: [
    'component.tag',
    'test.testFormNo',
    'location.location',
    'sequence',
    'dueDate',
  ],
  [RESOURCE_SMART_PRESERVATION_ITEM_ACTIVITY]: [
    'component.tag',
    'test.testFormNo',
    'location.location',
    'sequence',
    'dueDate',
  ],
  [RESOURCE_PRESERVATION_SERIES]: [
    'preservationItem.component.tag',
    'preservationItem.test.testFormNo',
    'preservationItem.sequence',
  ],
  [RESOURCE_REPORT]: ['reportName'],
  [RESOURCE_SMART_TAG_ITR]: [
    'component.tag',
    'test.testFormNo',
    'location.location',
  ],
  [RESOURCE_SMART_TAG_ITR_REVIEW]: [
    'component.tag',
    'test.testFormNo',
    'location.location',
  ],
  [RESOURCE_COMMENT]: ['comment'],
  [RESOURCE_COMPONENT]: ['tag'],
  [RESOURCE_SUBSYSTEM_DRAWING]: ['drawing.drawingName'],
  [RESOURCE_DRAWING_SUBSYSTEM]: ['subsystem.subsystem'],
  [RESOURCE_ATTACHMENT]: ['fileName'],
  [RESOURCE_CERT_DETAIL]: ['certTemplate.certName'],
  [RESOURCE_COMPONENT_DRAWING]: ['drawing.drawingName'],
  [RESOURCE_DRAWING_COMPONENT]: ['component.tag'],
  [RESOURCE_TEST_POSITION]: ['position.position'],
  [RESOURCE_EQUIPMENT_TYPE_TEST]: ['test.testFormNo'],
  [RESOURCE_CERT_GATING_TEST]: ['testType.testType'],
  [RESOURCE_CERT_GATING_PUNCH]: ['punchPriority.priority'],
  [RESOURCE_CERT_GATING_PRED_CERT]: ['predCertTemplate.certName'],
  [RESOURCE_GROUP_CONTACT]: ['contact.nickname'],
  [RESOURCE_SECURITY_ROLE_PERMISSION]: [
    'role.role',
    'permissionType',
    'resourceName',
  ],
  [RESOURCE_ATTACHMENT_TYPE]: ['subtype'],
  [RESOURCE_LUCID_DIAGRAM]: ['title'],
  [RESOURCE_MATERIAL_TYPE]: ['type'],
  [RESOURCE_STORAGE]: ['storage'],
  [RESOURCE_MATERIAL_COMPONENT]: ['component.tag'],
  [RESOURCE_MATERIAL]: ['material'],
  [RESOURCE_MATERIAL_STORAGE]: ['storage.storage'],
  [RESOURCE_MATERIAL_VENDOR]: ['vendor.vendor'],
  [RESOURCE_MATERIAL_RELEASED]: ['materialReleased'],
  [RESOURCE_MATERIAL_RELEASED_MATERIAL_STORAGE]: ['material.material'],
  [RESOURCE_MATERIAL_PURCHASE_ORDER]: ['orderNo'],
  [RESOURCE_MATERIAL_PURCHASE_ORDER_MATERIAL]: ['material.material'],
  [RESOURCE_PUNCH_TEMPLATE]: ['templateName'],
  [RESOURCE_ACTIVITY]: ['activity'],
};

const usePageShortInfo = (): string => {
  const resource = useResourceContext();
  const record = useRecordContext();
  if (!record || !resource) return '';
  const infoField = fieldFromResource[resource];

  let infoText = '';
  if (infoField) {
    infoText = infoField.reduce((acc, curr) => {
      const currentFieldValue = get(record, curr);
      if (currentFieldValue) {
        acc += acc ? '/' + currentFieldValue : currentFieldValue;
      }
      return acc;
    }, '');
  } else {
    infoText = `#${record.id}`;
  }

  return infoText.length >= 100 ? `${infoText.slice(0, 100)} ...` : infoText;
};

export default usePageShortInfo;
