import React, { ReactElement } from 'react';
import { AutocompleteInput, SelectArrayInput } from 'react-admin';
import { useWatch } from 'react-hook-form';
import useResourceChoices from '../common/hooks/useResourceChoices';
import sideFilterStyles from '../common/sideFilterStyles';
import commonStyles from '../common/commonStyles';
import { formatRoleAction } from './helpers';
import {
  actionChoices,
  actionFieldChoices,
  customRouteResources,
} from './constants';

interface ActionInputProps {
  alwaysOn?: boolean;
  isSidebarFilter?: boolean;
  source?: string;
  label?: string;
}

const ActionInput: React.FC<ActionInputProps> = ({
  alwaysOn,
  isSidebarFilter,
  source,
  label,
}): ReactElement => {
  const resourceName = useWatch({ name: 'resourceName' });
  const resourceChoices = useResourceChoices();
  const allResourcesChoices = [...resourceChoices, ...customRouteResources];
  const isResource = allResourcesChoices.some((resourceChoice) =>
    resourceName?.includes(resourceChoice.id)
  );
  const choices =
    isResource || !!isSidebarFilter ? actionChoices : actionFieldChoices;

  return !!isSidebarFilter ? (
    <AutocompleteInput
      source={source}
      label={label}
      name="action_inc_any"
      multiple
      choices={choices}
      sx={sideFilterStyles.sideFiltersSelectInput}
      alwaysOn={!!alwaysOn}
    />
  ) : (
    <SelectArrayInput
      source={source}
      label={label}
      choices={choices}
      format={(action) => formatRoleAction(action)}
      sx={[
        commonStyles.flexBoxLongItem,
        {
          '& label[data-shrink="false"]': {
            transform: 'translate(14px, 9px) scale(1)',
          },
        },
      ]}
    />
  );
};

ActionInput.defaultProps = { source: 'action', label: 'Action' };

export default ActionInput;
