import React, { ReactElement, useCallback } from 'react';
import { activityCategory, commentType } from '../common/constants';
import AttachmentFormTab from '../common/AttachmentFormTab';
import {
  RESOURCE_BOOK,
  RESOURCE_COMMENT,
  RESOURCE_SUBSYSTEM,
  RESOURCE_SUBSYSTEM_DRAWING,
} from '../constants';
import { EditPropsWithLocation } from '../common/types';
import CommentFormTab from '../common/CommentFormTab';
import MetaDataInputs from '../common/MetaDataInputs';
import TabbedEditForm from '../common/TabbedEditForm';
import DrawingFormTab from '../common/DrawingFormTab';
import ActivitiesTab from '../common/ActivitiesTab';
import commonStyles from '../common/commonStyles';
import LazyFormTab from '../common/LazyFormTab';
import HistoryTab from '../common/HistoryTab';
import useCanAccess from '../common/hooks/useCanAccess';
import SubSystemForm from './SubSystemForm';

const SubSystemEdit: React.FC<EditPropsWithLocation> = (
  props
): ReactElement => {
  const commentTabTransformFunc = useCallback(
    (data, recordId) => ({
      ...data,
      subsystemId: recordId,
      commentType: commentType.subsystem,
    }),
    []
  );
  const { edit: canEditComment } = useCanAccess(RESOURCE_COMMENT);

  return (
    <TabbedEditForm {...props}>
      <LazyFormTab
        label="SubSystem"
        id="subsystem_tab"
        sx={commonStyles.formTabHeader}
      >
        <SubSystemForm />
        <MetaDataInputs />
      </LazyFormTab>
      <LazyFormTab
        label="Drawings"
        id="drawings_tab"
        sx={commonStyles.formTabHeader}
      >
        <DrawingFormTab
          target="subsystemId"
          reference={RESOURCE_SUBSYSTEM_DRAWING}
        />
      </LazyFormTab>
      <LazyFormTab
        label="Comment"
        id="comments_tab"
        sx={commonStyles.formTabHeader}
      >
        <CommentFormTab
          resource={RESOURCE_COMMENT}
          targetId="subsystem_id"
          commentType={commentType.subsystem}
          transformFunc={commentTabTransformFunc}
          withActions={canEditComment}
        />
      </LazyFormTab>
      <LazyFormTab
        label="Attachment"
        id="attachments_tab"
        sx={commonStyles.formTabHeader}
      >
        <AttachmentFormTab
          targetId="subsystemId"
          resourceName={[RESOURCE_SUBSYSTEM, RESOURCE_BOOK]}
        />
      </LazyFormTab>
      <LazyFormTab
        label="Activities"
        id="activity_tab"
        sx={commonStyles.formTabHeader}
      >
        <ActivitiesTab category={activityCategory.subSystem} />
      </LazyFormTab>
      <HistoryTab tableName="tbl_subsystem" />
    </TabbedEditForm>
  );
};

export default SubSystemEdit;
