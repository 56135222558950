import { useCallback } from 'react';
import {
  getContext,
  updateContext,
  UserContext,
} from '../../../provider/userContext';
import { api } from '../../../provider/api';
import { updateLocalUISettings } from '../../../utils/UtilityFunctions';

const useRefreshCompanyContext = () => {
  return useCallback(async () => {
    const myDetails = await api.user.getMyDetails();

    if (myDetails?.data?.companies) {
      const ctx: UserContext = getContext();
      ctx.fpmsApiKey = myDetails.data.fpmsApiKey;
      ctx.companies = myDetails.data.companies;
      ctx.defaultDivisionId =
        myDetails.data.companies[ctx?.company]?.defaultDivisionId;
      updateContext(ctx);
      const uiSettings = myDetails.data.companies[ctx?.company]?.uiSettings;

      if (uiSettings) {
        updateLocalUISettings(uiSettings);
      }
    }
  }, []);
};

export default useRefreshCompanyContext;
