import React, {
  Fragment,
  useState,
  useEffect,
  useCallback,
  useMemo,
  ReactElement,
} from 'react';
import { Autocomplete, TextField, Typography } from '@mui/material';
import { RESOURCE_CHOICES } from './constants';

const shouldUpdateValue = (
  resource: string | null,
  resourceId: string,
  value: { resource: string | null; resourceId: string }
) => {
  const isNewResource = resource && value?.resource !== resource;
  const isNewResourceId = resourceId && value?.resourceId !== resourceId;
  const isResetFilter = !value && resource && resourceId;

  return (isNewResource && isNewResourceId) || isResetFilter;
};

type AttachmentRevisionPickerProps = {
  alwaysOn?: boolean;
  value?: { resource: string | null; resourceId: string };
  onChange?: (r: string, rId: string) => void;
  source?: string;
};

const AttachmentResourceFilterInput: React.FC<AttachmentRevisionPickerProps> = (
  props
): ReactElement => {
  const { value: propsValue } = props;
  const { onChange } = props;
  const resourceChoices = RESOURCE_CHOICES.sort((a, b) =>
    a.name.localeCompare(b.name)
  );
  const initialResourceOption = useMemo(() => {
    if (propsValue?.resource) {
      return resourceChoices.find(
        (option) => option.id === propsValue.resource
      );
    } else {
      return null;
    }
  }, [propsValue.resource, resourceChoices]);

  const [selectedResourceOption, setSelectedResourceOption] = useState(
    initialResourceOption
  );
  const [resourceId, setResourceId] = useState(propsValue?.resourceId || '');

  useEffect(() => {
    if (selectedResourceOption && resourceId && onChange) {
      onChange(selectedResourceOption.id, resourceId);
    }
  }, [selectedResourceOption, resourceId, onChange]);

  useEffect(() => {
    if (shouldUpdateValue(selectedResourceOption?.id, resourceId, propsValue)) {
      setSelectedResourceOption(null);
      setResourceId('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [propsValue]);

  const handleResourceChange = useCallback(
    (_, value) => {
      if (value === null) {
        setResourceId('');
        if (onChange) {
          onChange(null, '');
        }
      }

      setSelectedResourceOption(value);
    },
    [onChange]
  );

  const handleResourceIdChange = useCallback((event) => {
    setResourceId(event.target.value);
  }, []);

  return (
    <Fragment>
      <Autocomplete
        title="Resource"
        value={selectedResourceOption}
        inputValue={selectedResourceOption?.name || ''}
        getOptionLabel={(option) => option.name}
        options={resourceChoices}
        onChange={handleResourceChange}
        renderInput={(params) => (
          <TextField {...params} label="Resource Name" />
        )}
        sx={{ mb: 3 }}
      />
      <TextField
        value={resourceId}
        label="Resource Id"
        onChange={handleResourceIdChange}
        inputProps={{ type: 'number', min: 1, step: 1 }}
        fullWidth
      />
      <Typography fontSize={12}>
        Use Resource Name and Resource Id filter together
      </Typography>
    </Fragment>
  );
};

AttachmentResourceFilterInput.defaultProps = { source: 'attachmentResource' };

export default AttachmentResourceFilterInput;
