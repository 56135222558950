import React, { ReactElement, useMemo, useState } from 'react';
import './styles.css';
import { NotFound, ResourceContextProvider, usePermissions } from 'react-admin';
import { MAX_RECORD_PER_PAGE } from '../../provider/constants';
import { getLocaleDateFormat, isNotEmpty } from '../../utils/UtilityFunctions';
import useDefaultFilter from '../common/hooks/useDefaultFilter';
import ListWithTitle from '../common/ListWithTitle';
import { RESOURCE_ACTIVITY } from '../constants';
import useCanAccess from '../common/hooks/useCanAccess';
import { GanttApi } from './components/gantt/types';
import GanttChartToolbar from './components/toolbar/GanttChartToolbar';
import GanttChartTasks from './GanttChartTasks';
import useGanttChartView from './useGanttChartView';

const activityFields = [
  'id',
  'activity',
  'plannedStartDate',
  'plannedDate',
  'duration',
  'durationUnit',
  'percentComplete',
  'predecessors.id',
  'predecessors.activityId',
  'predecessors.predecessorId',
  'predecessors.type',
  'predecessors.offset',
  'predecessors.offsetUnit',
  'parentId',
];

const GanttChartView: React.FC = (): ReactElement => {
  const defaultFilter = useDefaultFilter('project');
  const [ganttApi, setGanttApi] = useState<GanttApi>(null);
  const { isLoading: permissionsLoading } = usePermissions();
  const access = useCanAccess();
  const dateFormat = useMemo(() => {
    return getLocaleDateFormat().toLowerCase().replaceAll('m', 'M');
  }, []);

  const {
    setInitialData,
    changes,
    selectedId,
    onCleanState,
    onBulkSave,
    onTaskChange,
    onTaskSelect,
  } = useGanttChartView(ganttApi);

  if (permissionsLoading) {
    return null;
  } else if (!access.list) {
    return <NotFound />;
  }

  return (
    <ListWithTitle
      title="Activies Gantt Chart"
      sx={{ paddingTop: '10px' }}
      filterDefaultValues={defaultFilter}
      pagination={false}
      perPage={MAX_RECORD_PER_PAGE}
      sort={{ field: 'id', order: 'ASC' }}
      storeKey="ganttChart.listParams"
      actions={
        <GanttChartToolbar
          ganttApi={ganttApi}
          selectedId={selectedId}
          containsChanges={isNotEmpty(changes)}
          onCleanState={onCleanState}
          onBulkSave={onBulkSave}
        />
      }
      queryOptions={{
        meta: { includeFields: activityFields },
      }}
    >
      <GanttChartTasks
        onTaskChange={onTaskChange}
        onTaskSelect={onTaskSelect}
        onDataLoaded={setInitialData}
        onInit={setGanttApi}
        allowEdit={access.edit}
        dateFormat={dateFormat}
      />
    </ListWithTitle>
  );
};

export default (): ReactElement => (
  <ResourceContextProvider value={RESOURCE_ACTIVITY}>
    <GanttChartView />
  </ResourceContextProvider>
);
