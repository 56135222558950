import { ValidationErrorMessage } from 'react-admin';
import { Project } from './types';

export const validateLaterThanStartDate = (
  value: string,
  allValues: Project
): ValidationErrorMessage => {
  const date = new Date(value);
  const startDate = new Date(allValues.startDate);
  if (value && startDate > date) {
    return 'Must be later than or equal to the Start Date';
  }
  return '';
};
