import React, { useCallback, ReactElement } from 'react';
import {
  Title,
  useDataProvider,
  useNotify,
  SimpleForm,
  Toolbar,
} from 'react-admin';
import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { getErrorMessage } from '../../utils/UtilityFunctions';
import { setPageTitle } from '../common/helpers/setPageTitle';
import SaveButton from '../common/SaveButton';
import { RESOURCE_USER } from '../constants';
import QRCodeGenerationForm from './QRCodeGenerationForm';

export const QRCodeGeneration: React.FC = (): ReactElement => {
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const title = 'QR Code Generator';
  setPageTitle(title);

  const { mutate: printQRCode } = useMutation({
    mutationFn: (data) => {
      return dataProvider.generateQRCode(RESOURCE_USER, data);
    },
    onSuccess: () => {
      notify('QR Code PDF file was generated!');
    },
    onError: async (error: AxiosError) => {
      let errorMessage = error.message;
      if (error?.response?.data instanceof Blob) {
        errorMessage = await error?.response?.data.text();
        if (error?.response?.data.type === 'application/json') {
          errorMessage = JSON.parse(errorMessage);
        }
      }

      notify(getErrorMessage(errorMessage, 'PDF file generation failed!'), {
        type: 'error',
        undoable: false,
        multiLine: true,
      });
    },
  });

  const handleSubmit = useCallback(
    async (data) => {
      printQRCode(data);
    },
    [printQRCode]
  );

  return (
    <SimpleForm
      onSubmit={handleSubmit}
      toolbar={
        <Toolbar>
          <SaveButton variant="outlined" label="Print QR Code" icon={null} />
        </Toolbar>
      }
    >
      <Title title={title} />
      <QRCodeGenerationForm />
    </SimpleForm>
  );
};
