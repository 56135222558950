import React, { ReactElement } from 'react';
import { TextField, useRecordContext } from 'react-admin';
import {
  RESOURCE_COMPONENT,
  RESOURCE_SUBSYSTEM,
  RESOURCE_SYSTEM,
} from '../../constants';
import SmartLinkField from '../../common/SmartLinkField';
import { getEntityType } from '../helpers';

interface Props {
  label?: string;
  source?: string;
  textSource?: string;
  sortBy?: string;
  sx?: object;
}

const resources = {
  SYSTEM: RESOURCE_SYSTEM,
  SUBSYSTEM: RESOURCE_SUBSYSTEM,
  COMPONENT: RESOURCE_COMPONENT,
};

const entitySource = {
  SYSTEM: 'systemId',
  SUBSYSTEM: 'subsystemId',
  COMPONENT: 'componentId',
};

const entityTextSource = {
  SYSTEM: 'system.systemName',
  SUBSYSTEM: 'subsystem.subsystem',
  COMPONENT: 'component.tag',
};

const CategoryNameField: React.FC<Props> = ({
  label,
  source,
  sx,
  textSource,
}): ReactElement => {
  const record = useRecordContext();
  const entityType = getEntityType(record);

  return (
    <SmartLinkField
      label={label}
      source={entitySource[entityType] || source}
      resource={resources[entityType]}
    >
      <TextField
        sx={sx}
        {...{ source: entityTextSource[entityType] || textSource }}
      />
    </SmartLinkField>
  );
};

CategoryNameField.defaultProps = {
  label: 'Category Name',
  source: 'entity',
  textSource: 'system.systemName',
};

export default CategoryNameField;
