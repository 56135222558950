import { useInput } from 'react-admin';
import { atLeastOneRequired } from '../../../../utils/UtilityFunctions';

const useInputs = (isRequired?: boolean) => {
  const validate = [];
  if (isRequired) {
    validate.push(
      atLeastOneRequired([
        'assignedContactId',
        'assignedUserGroupId',
        'assignedEmail',
      ])
    );
  }

  const {
    field: contactId,
    fieldState: {
      isTouched: contactIdIsTouched,
      invalid: contactIdInvalid,
      error: contactIdError,
    },
    formState: { isSubmitted },
  } = useInput({
    source: 'assignedContactId',
    validate,
  });

  const {
    field: assignedUserGroupId,
    fieldState: {
      isTouched: assignedUserGroupIdIsTouched,
      invalid: assignedUserGroupIdInvalid,
      error: assignedUserGroupIdError,
    },
  } = useInput({
    source: 'assignedUserGroupId',
    validate,
  });

  const {
    field: assignedEmail,
    fieldState: {
      isTouched: assignedEmailIsTouched,
      invalid: assignedEmailInvalid,
      error: assignedEmailError,
    },
  } = useInput({
    source: 'assignedEmail',
    validate,
  });

  return {
    contactId,
    assignedUserGroupId,
    assignedEmail,
    isSubmitted,
    isTouched:
      contactIdIsTouched ||
      assignedUserGroupIdIsTouched ||
      assignedEmailIsTouched,
    invalid:
      contactIdInvalid && assignedUserGroupIdInvalid && assignedEmailInvalid,
    error: contactIdError || assignedUserGroupIdError || assignedEmailError,
  };
};

export default useInputs;
