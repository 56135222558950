import axios from 'axios';
import { getTimeZone } from '../../../utils/UtilityFunctions';
import { CRUD_API_URL, getRequestConfig } from '../../api';

const bulkUpdateDataProvider = {
  bulkUpdate: (resource: string, data: object[]) => {
    return axios.put(
      `${CRUD_API_URL}/${resource}/bulk-update`,
      {
        data,
        timeZone: getTimeZone(),
      },
      getRequestConfig()
    );
  },
};

export default bulkUpdateDataProvider;
