import $ from 'jquery';
import React, { ReactElement, useEffect, useState, useRef } from 'react';
import { useDataProvider, useNotify } from 'react-admin';
import { useMutation } from '@tanstack/react-query';
import { TelerikReportViewer } from '@progress/telerik-react-report-viewer';
import Box from '@mui/material/Box';
import { refreshTokens } from '../../../provider/dataProvider/useDataProvider';
import { getContext, UserContext } from '../../../provider/userContext';
import { BOOK_GEN_API, environment } from '../../../provider/api';
import commonStyles from '../../common/commonStyles';
import { RESOURCE_REPORT } from '../../constants';

interface TelerikReportViewProps {
  projectId: number;
  reportName: string;
}

const getTemplateUrl = () => {
  if (environment === 'prod') {
    return '/telerikReportTemplate-prod.html';
  }
  return '/telerikReportTemplate-dev.html';
};

const TelerikReportView: React.FC<TelerikReportViewProps> = ({
  projectId,
  reportName,
}): ReactElement => {
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const [parameters, setParameters] = useState(null);
  const ctx: UserContext = getContext();
  const [token, setToken] = useState(ctx?.token);
  const interval = useRef<ReturnType<typeof setInterval> | null>(null);

  useEffect(() => {
    setToken(ctx?.token);
  }, [ctx?.token]);

  useEffect(() => {
    refreshTokens().then();
    interval.current = setInterval(() => {
      refreshTokens().then();
    }, 30000);
    return () => {
      clearInterval(interval.current);
    };
  }, []);

  const { mutate: exportTelerikReport } = useMutation({
    mutationFn: async (params: object) => {
      return dataProvider.exportTelerikReport(RESOURCE_REPORT, params);
    },
    onSuccess: (result) => {
      notify(
        `${result} export has been requested. You will receive a notification once it's completed.`,
        {
          type: 'success',
        }
      );
    },
    onError: (error) => {
      notify(error && error['message'] && error['message']['title'], {
        type: 'error',
        undoable: false,
      });
    },
  });

  useEffect(() => {
    $.ajaxPrefilter((_options, _originalOptions, jqXHR) => {
      refreshTokens().then();
      jqXHR.setRequestHeader('Company', ctx?.company);
      jqXHR.setRequestHeader('Authorization', `Bearer ${token}`);
    });
  }, [ctx?.company, token]);

  useEffect(() => {
    $('#trv-main-menu-export-pdf').on('click', () => {
      exportTelerikReport({
        reportName,
        parameters,
        projectId,
        reportType: 'PDF',
      });
    });

    $('#trv-main-menu-export-excel').on('click', () => {
      exportTelerikReport({
        reportName,
        parameters,
        projectId,
        reportType: 'EXCEL',
      });
    });

    $('#reportViewer1').on('mousedown', async () => {
      const newToken = await refreshTokens();
      if (typeof newToken === 'string') {
        setToken(newToken);
      }
    });

    return () => {
      $('#trv-main-menu-export-pdf').off('click');
      $('#trv-main-menu-export-excel').off('click');
      $('#reportViewer1').off('mousedown');
    };
  }, [exportTelerikReport, parameters, projectId, reportName]);

  return (
    <Box sx={commonStyles.telerikReportContainer}>
      <TelerikReportViewer
        serviceUrl={`${BOOK_GEN_API}/report-viewer/api`}
        reportSource={{
          report: reportName,
          parameters: {
            ProjectId: projectId,
          },
        }}
        templateUrl={getTemplateUrl()}
        parameters={{
          editors: { singleSelect: 'COMBO_BOX', multiSelect: 'COMBO_BOX' },
        }}
        viewerContainerStyle={commonStyles.telerikContainer}
        viewMode="INTERACTIVE"
        scaleMode="SPECIFIC"
        scale={1.0}
        enableAccessibility={false}
        parametersAreaVisible={false}
        renderingEnd={(e) => {
          const params = e?.target?.getReportParameters();
          delete params?.recordslimit;
          setParameters(params);
        }}
      />
    </Box>
  );
};

export default TelerikReportView;
