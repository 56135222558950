import React, { ReactElement } from 'react';
import {
  SimpleForm,
  ListButton,
  TextField,
  DateField,
  useRecordContext,
  maxLength,
} from 'react-admin';
import { Card, Box, Typography, CardContent } from '@mui/material';
import { ReactComponent as CancelIcon } from '../../../assets/icons/functionalIcons/Cancel.svg';
import CompletedInput from '../../preservationItemActivity/components/CompletedInput';
import useCurrentProjectSettings from '../../common/hooks/useCurrentProjectSettings';
import { CompletedType } from '../../preservationItemActivity/constants';
import SmartTabWidthButtons from '../../common/SmartTabWidthButtons';
import { SmartPreservationItemActivityType } from '../types';
import useCanAccess from '../../common/hooks/useCanAccess';
import FooterToolbar from '../../common/FooterToolbar';
import LocationField from '../../common/LocationField';
import commonStyles from '../../common/commonStyles';
import {
  getValidateRequiredFunc,
  getValidateRequiredFuncByValue,
} from '../../preservationItemActivity/validators';
import TestField from '../../common/TestField';
import TextInput from '../../common/TextInput';
import { useDictionaryLabelsWithResource } from '../../../utils/UtilityFunctions';
import LabeledWithCheckAccess from '../../common/LabeledWithCheckAccess';
import FragmentWithCheckAccess from '../../common/FragmentWithCheckAccess';
import SignatureTextInput from '../../common/SignatureTextInput';
import PrintNameInput from './PrintNameInput';
import JSONFormTab from './JSONFormTab';
import { CurrentDateInput } from './CurrentDateInput';

interface SmartPreservationItemActivityFormProps {
  onCancel?: () => void;
  setPendingPunchTitle?: (title: string | null) => void;
  setPendingPunchCreateDialogCloseDisabled: (value: boolean) => void;
}

const SmartPreservationItemActivityForm: React.FC<
  SmartPreservationItemActivityFormProps
> = ({
  onCancel,
  setPendingPunchTitle,
  setPendingPunchCreateDialogCloseDisabled,
}): ReactElement => {
  const { labels } = useDictionaryLabelsWithResource();
  const canAccessList = useCanAccess();
  const record = useRecordContext<SmartPreservationItemActivityType>();
  const isSimpleForm = record && !!record.storagePreservation;
  const {
    currentProjectSettings: { presAutomation },
  } = useCurrentProjectSettings();

  return (
    <SimpleForm
      toolbar={
        <FooterToolbar autoSave={true} onCancel={onCancel}>
          <ListButton label="Cancel" icon={<CancelIcon />} onClick={onCancel} />
        </FooterToolbar>
      }
    >
      <Box sx={{ ...commonStyles.columnFlexBox, padding: '0 16px 30px' }}>
        <SmartTabWidthButtons />
        <LabeledWithCheckAccess label={labels['componentId'] || 'Tag'}>
          <TextField source="component.tag" />
        </LabeledWithCheckAccess>
        <LabeledWithCheckAccess label="Tag Description">
          <TextField source="component.tagDescription" />
        </LabeledWithCheckAccess>
        <LabeledWithCheckAccess label={labels['testId'] || 'Test'}>
          <TestField source="testId" textSource="test.testFormNo" />
        </LabeledWithCheckAccess>
        <LabeledWithCheckAccess label="Test Description">
          <TextField source="test.testName" />
        </LabeledWithCheckAccess>
        <LabeledWithCheckAccess label={labels['dueDate'] || 'Due Date'}>
          <DateField source="dueDate" />
        </LabeledWithCheckAccess>
        <LabeledWithCheckAccess label={labels['locationId'] || 'Location'}>
          <LocationField />
        </LabeledWithCheckAccess>
        <LabeledWithCheckAccess
          label={labels['storagePreservation'] || 'Storage Preservation'}
        >
          <TextField source="storagePreservation" />
        </LabeledWithCheckAccess>
        <LabeledWithCheckAccess label="Review Comments">
          <TextInput source="reviewComments" label="" multiline fullWidth />
        </LabeledWithCheckAccess>
      </Box>

      {!isSimpleForm && (
        <JSONFormTab
          setPendingPunchTitle={setPendingPunchTitle}
          setPendingPunchCreateDialogCloseDisabled={
            setPendingPunchCreateDialogCloseDisabled
          }
        />
      )}

      <Card sx={{ marginBottom: '20px', width: '100%' }}>
        <Typography variant="h5" sx={{ margin: '20px' }}>
          {`Status${isSimpleForm ? ', Comments and Signatures' : ''}`}
        </Typography>
        <CardContent>
          <FragmentWithCheckAccess>
            <CompletedInput
              source="completed"
              label={labels['completed'] || 'Status'}
              sx={commonStyles.flexBoxFullWidthItem}
            />
          </FragmentWithCheckAccess>
          {isSimpleForm && (
            <FragmentWithCheckAccess>
              <TextInput
                source="comments"
                label="Comments"
                sx={{ ...commonStyles.flexBoxFullWidthItem, flexGrow: 1 }}
                fullWidth
                multiline
                disabled={!canAccessList.edit}
              />
              <SignatureTextInput
                source="inspector"
                label={labels['inspector'] || 'Inspector: Signature'}
                sx={commonStyles.flexBoxFullWidthItem}
                fullWidth
                validate={[
                  getValidateRequiredFuncByValue(
                    'completed',
                    CompletedType.YES
                  ),
                ]}
              />
              <PrintNameInput
                source="inspectorPrintName"
                label={labels['inspectorPrintName'] || 'Inspector: Print Name'}
                sx={commonStyles.flexBoxFullWidthItem}
                validate={[
                  getValidateRequiredFuncByValue(
                    'completed',
                    CompletedType.YES
                  ),
                  maxLength(100),
                ]}
              />
              <CurrentDateInput
                source="signOffDate"
                label={labels['signOffDate'] || 'Sign Off Date'}
                sx={commonStyles.flexBoxFullWidthItem}
                validate={[
                  getValidateRequiredFuncByValue(
                    'completed',
                    CompletedType.YES
                  ),
                ]}
              />
              <TextInput
                source="inspectorCompany"
                label={labels['inspectorCompany'] || 'Inspector: Company'}
                sx={commonStyles.flexBoxFullWidthItem}
                validate={[
                  getValidateRequiredFuncByValue(
                    'completed',
                    CompletedType.YES
                  ),
                  maxLength(100),
                ]}
              />
              <SignatureTextInput
                source="verifiedBy"
                label={labels['verifiedBy'] || 'Verified By: Signature'}
                sx={commonStyles.flexBoxFullWidthItem}
                validate={[
                  getValidateRequiredFunc('verifiedDate', 'Verified By: Date'),
                  presAutomation === 'YES' &&
                    getValidateRequiredFuncByValue(
                      'completed',
                      CompletedType.YES
                    ),
                ]}
              />
              <PrintNameInput
                source="verifiedPrintName"
                label={labels['verifiedPrintName'] || 'Verified By: Print Name'}
                sx={commonStyles.flexBoxFullWidthItem}
                validate={[
                  maxLength(100),
                  presAutomation === 'YES' &&
                    getValidateRequiredFuncByValue(
                      'completed',
                      CompletedType.YES
                    ),
                ]}
              />
              <CurrentDateInput
                source="verifiedDate"
                label={labels['verifiedDate'] || 'Verified By: Date'}
                sx={commonStyles.flexBoxFullWidthItem}
                validate={[
                  getValidateRequiredFunc(
                    'verifiedBy',
                    'Verified By: Signature'
                  ),
                  presAutomation === 'YES' &&
                    getValidateRequiredFuncByValue(
                      'completed',
                      CompletedType.YES
                    ),
                ]}
              />
              <TextInput
                source="verifiedCompany"
                label={labels['verifiedCompany'] || 'Verified By: Company'}
                sx={commonStyles.flexBoxFullWidthItem}
                validate={[
                  maxLength(100),
                  presAutomation === 'YES' &&
                    getValidateRequiredFuncByValue(
                      'completed',
                      CompletedType.YES
                    ),
                ]}
              />
            </FragmentWithCheckAccess>
          )}
        </CardContent>
      </Card>
    </SimpleForm>
  );
};

export default SmartPreservationItemActivityForm;
