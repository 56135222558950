interface FilterParams {
  filter?: FilterType;
}

type FilterType = Record<string, string | number>;

export const processRequestParams = <T>(params: FilterParams): T => {
  const { filter, ...rest } = params;

  return { ...rest, filter: processFilter(filter) } as T;
};

export const processFilter = (filter?: FilterType): FilterType => {
  const processedFilter = {};
  Object.keys(filter || {}).forEach((key) => {
    const value = filter[key] === 'NULL' ? null : filter[key];

    if (key.includes('->')) {
      // Hack around dot issue for filters: https://github.com/marmelab/react-admin/issues/2102
      processedFilter[`${key.replaceAll('->', '.')}`] = value;
    } else {
      processedFilter[key] = value;
    }
  });

  return processedFilter;
};

export const parseJwt = (token) => {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split('')
      .map((c) => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2))
      .join('')
  );

  return JSON.parse(jsonPayload);
};
