import React, { ReactElement } from 'react';
import useAuditTableName from '../../common/hooks/useAuditTableName';
import { Audit } from '../types';

interface RestoreButtonConfirmContentProps {
  audit: Audit[];
  showClarification: boolean;
}

const RestoreButtonConfirmContent: React.FC<
  RestoreButtonConfirmContentProps
> = ({ audit, showClarification }): ReactElement => {
  const tableNameFormatter = useAuditTableName();
  return (
    <div>
      <p>
        This action will restore the following entities to their state prior to
        the update or deletion:
      </p>
      <ul>
        {audit.map((item, index) => (
          <li key={index}>
            {tableNameFormatter(item.tableName)} [Id: {item.recordId}]
          </li>
        ))}
      </ul>
      {showClarification && (
        <p style={{ fontSize: 12 }}>
          Audit rows with the action CREATE, with deleted to trash attachments,
          or for preservation tables will be excluded from the restoration
          process. <br />
          In the case of multiple audit rows related to the same entity record
          selected for restoration, only the most recent one will be used.
        </p>
      )}
    </div>
  );
};

export default RestoreButtonConfirmContent;
