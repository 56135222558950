import React, { ReactElement } from 'react';
import { Card, CardContent } from '@mui/material';
import { Button, Filter } from 'react-admin';
import isEmpty from 'lodash/isEmpty';
import ActivityCertGatingItrStatusInput from '../../common/ActivityCertGatingItrStatusInput';
import ActivityCertGatingPredCertStatusInput from '../../common/ActivityCertGatingPredCertStatusInput';
import ActivityCertGatingPunchStatusInput from '../../common/ActivityCertGatingPunchStatusInput';
import ActivityConstraintInput from '../../common/ActivityConstraintInput';
import ActivityPhaseInput from '../../common/ActivityPhaseInput';
import ActivityCertGatingStatusOverallInput from '../../common/ActivityCertGatingStatusOverallInput';
import ActivityTypeInput from '../../common/ActivityTypeInput';
import CertTemplateInput from '../../common/CertTemplateInput';
import DateRangeFilter from '../../common/DateRangeFilter';
import DisciplineInput from '../../common/DisciplineInput';
import SystemInput from '../../common/SystemInput';
import useSidebarFilters from '../../common/hooks/useSidebarFilters';
import sideFilterStyles from '../../common/sideFilterStyles';
import { SideFilter } from '../../common/types';
import { RESOURCE_ACTIVITY } from '../../constants';
import TextInput from '../../common/TextInput';
import ComponentInput from '../../common/ComponentInput';
import SubsystemInput from '../../common/SubsystemInput';
import ActivityCategoryInput from '../../common/ActivityCertificate';
import { Accordion } from '../../common/Accordion';
import ActivityInput from './ActivityInput';

const ActivitySideFilters: React.FC<SideFilter> = ({
  defaultFilter,
}): ReactElement => {
  const {
    data,
    isLoading,
    filterValues,
    dateFrom,
    dateTo,
    handleDateFromChange,
    handleDateToChange,
    handleClearFilters,
  } = useSidebarFilters(RESOURCE_ACTIVITY, defaultFilter);

  if (!data || (!data.length && isEmpty(filterValues)) || isLoading) {
    return null;
  }

  return (
    <Card sx={[sideFilterStyles.sideFiltersCard, { width: 455 }]}>
      <CardContent sx={sideFilterStyles.sideFiltersCardContainer}>
        <Filter>
          <TextInput
            source="activity"
            name="activity_inc"
            label="Activity"
            fullWidth
            alwaysOn
          />
          <ActivityCategoryInput multiple fullWidth alwaysOn />
          <TextInput
            source="sequence"
            name="sequence_inc"
            label="Sequence"
            fullWidth
            alwaysOn
          />
          <ActivityPhaseInput multiple fullWidth alwaysOn />
          <DisciplineInput withAllOption multiple fullWidth alwaysOn />
          <ActivityTypeInput multiple fullWidth alwaysOn />
          <SystemInput multiple fullWidth alwaysOn />
          <SubsystemInput multiple fullWidth alwaysOn />
          <ComponentInput multiple fullWidth alwaysOn />
          <TextInput
            source="description"
            name="description_inc"
            label="Description"
            fullWidth
            alwaysOn
          />
          <ActivityInput
            source="predecessors->predecessorId"
            label="Predecessor Activity"
            fullWidth
            alwaysOn
            multiple
          />
          <ActivityConstraintInput multiple fullWidth alwaysOn />
          <CertTemplateInput multiple fullWidth alwaysOn />
          <Accordion alwaysOn label="Planned Date">
            <DateRangeFilter
              fullWidth
              source="plannedStartDate"
              label="Planned Start Date"
              dateFrom={dateFrom}
              dateTo={dateTo}
              handleDateFromChange={handleDateFromChange}
              handleDateToChange={handleDateToChange}
              sx={{ mb: 3 }}
            />
            <DateRangeFilter
              fullWidth
              source="plannedDate"
              label="Planned Completion Date"
              dateFrom={dateFrom}
              dateTo={dateTo}
              handleDateFromChange={handleDateFromChange}
              handleDateToChange={handleDateToChange}
            />
          </Accordion>
          <Accordion alwaysOn label="Actual Date">
            <DateRangeFilter
              fullWidth
              source="actualStartDate"
              label="Actual Start Date"
              dateFrom={dateFrom}
              dateTo={dateTo}
              handleDateFromChange={handleDateFromChange}
              handleDateToChange={handleDateToChange}
              sx={{ mb: 3 }}
            />
            <DateRangeFilter
              fullWidth
              source="actualDate"
              label="Actual Complition Date"
              dateFrom={dateFrom}
              dateTo={dateTo}
              handleDateFromChange={handleDateFromChange}
              handleDateToChange={handleDateToChange}
            />
          </Accordion>
          <Accordion alwaysOn label="Activity Status">
            <ActivityCertGatingStatusOverallInput multiple fullWidth alwaysOn />
            <ActivityCertGatingItrStatusInput multiple fullWidth alwaysOn />
            <ActivityCertGatingPunchStatusInput multiple fullWidth alwaysOn />
            <ActivityCertGatingPredCertStatusInput
              multiple
              fullWidth
              alwaysOn
            />
          </Accordion>
          <DateRangeFilter
            fullWidth
            label="Updated"
            source="updatedAt"
            dateFrom={dateFrom}
            dateTo={dateTo}
            handleDateFromChange={handleDateFromChange}
            handleDateToChange={handleDateToChange}
          />
          <TextInput
            label="Updated By"
            name="updatedBy_inc"
            source="updatedBy"
            fullWidth
            alwaysOn
          />
          <TextInput
            label="Updated By Name"
            name="updatedByName_inc"
            source="updatedByName"
            fullWidth
            alwaysOn
          />
        </Filter>
        <Button label="Clear filters" onClick={handleClearFilters} />
      </CardContent>
    </Card>
  );
};

export default ActivitySideFilters;
