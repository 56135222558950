import { UserIdentity } from 'react-admin';
import { api } from '../../api';
import {
  getContext,
  setContext,
  updateContext,
  UserContext,
} from '../../userContext';
import { clearNotificationContext } from '../../notificationContext';
import {
  sleep,
  updateDictionaryContext,
} from '../../../utils/UtilityFunctions';
import { AuthProviderWithRefresh } from '../types';
import { parseJwt } from '../../helpers';
import setCtx from './setCtx';

export type TokenAuthProviderOptionsIds = {
  clientId: string;
  hostedUIUrl?: string;
  redirect_uri: string;
  scope: string[];
};

const getUserSessionRefresher = () => {
  let promise = null;
  return (force = false) => {
    if (!promise) {
      promise = new Promise((resolve, reject) => {
        const ctx = getContext();
        if (!ctx.token) {
          reject('No token');
        }
        const tokenPayload = parseJwt(ctx.token);
        const delta = parseInt(tokenPayload.exp) - Date.now() / 1000;
        if (delta < 60 || force) {
          api.token
            .refreshToken()
            .then((result) => {
              const expire = parseInt(result.data.expire);
              updateContext({
                token: result.data.id_token,
                tokenExp: Date.now() + expire,
              });
              resolve(expire);
            })
            .catch((error) => {
              reject(error);
            });
        } else {
          resolve(ctx.tokenExp);
        }
      }).finally(() => {
        promise = null;
      });
    }
    return promise;
  };
};

export const TokenAuthProvider = (
  options: TokenAuthProviderOptionsIds
): AuthProviderWithRefresh => {
  const refreshUserSession = getUserSessionRefresher();

  const redirectToOAuth = async () => {
    setContext(undefined);
    await clearNotificationContext();
    setTimeout(() => {
      window.location.href = `${options.hostedUIUrl}/login?client_id=${
        options.clientId
      }&response_type=code&scope=${options.scope.join(
        '+'
      )}&redirect_uri=${options.redirect_uri}`;
    }, 100);
  };

  return {
    refreshUserSession: () => {
      return refreshUserSession();
    },
    async login(): Promise<void> {
      await redirectToOAuth();
      return Promise.resolve();
    },

    // called when the user clicks on the logout button
    async logout() {
      return api.token.logout().then(() => {
        setContext(undefined);
        clearNotificationContext();
      });
    },
    // called when the API returns an error
    async checkError({ status }) {
      if (status === 401 || status === 403) {
        return Promise.reject({ message: 'Unauthorized' });
      }
      return Promise.resolve();
    },
    // called when the user navigates to a new location, to check for authentication
    checkAuth() {
      return new Promise<void>(async (resolve, reject) => {
        refreshUserSession()
          .then(() => resolve())
          .catch(() => {
            redirectToOAuth();
            reject();
          });
      });
    },
    // called when the user navigates to a new location, to check for permissions / roles
    async getPermissions() {
      return new Promise((resolve, reject) => {
        try {
          const ctx: UserContext = getContext();
          if (!ctx) {
            return reject({ status: 401, message: 'No Context' });
          }
          refreshUserSession()
            .then(() => {
              const companyName = ctx?.company;
              const projectId = ctx?.projectId;
              const effectivePermissions =
                ctx?.companies?.[companyName]?.projects?.[projectId]
                  ?.effectivePermissions || [];
              resolve(effectivePermissions);
            })
            .catch((error) => {
              redirectToOAuth();
              reject(error);
            });
        } catch (e) {
          return reject('Could not get user permissions!');
        }
      });
    },

    async getIdentity() {
      return new Promise<UserIdentity>((resolve, reject) => {
        const ctx: UserContext = getContext();
        if (!ctx) {
          return reject({ status: 401, message: 'No Context' });
        }
        refreshUserSession()
          .then(() => {
            if (ctx?.id && ctx.fullName) {
              resolve({
                id: ctx.id,
                fullName: ctx.fullName,
                email: ctx.id,
              });
            } else {
              reject();
            }
          })
          .catch(() => reject());
      });
    },

    async handleCallback() {
      const urlParams = new URLSearchParams(
        window.location.search || window.location.hash.substr(1)
      );
      // const expire = urlParams.get('expire');
      const error = urlParams.get('error');
      const token = urlParams.get('id_token');

      if (error) {
        return Promise.reject({
          logoutOnFailure: false,
          message: error,
        });
      }

      if (token == null) {
        return Promise.reject({
          logoutOnFailure: false,
          message: 'Failed to handle login callback.',
        });
      }

      updateContext({
        token,
        // tokenExp: Date.now() + parseInt(expire),
      });

      try {
        const response = await api.user.getMyDetails();
        setCtx(response.data);
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log(e);
        await sleep(5000);
        return Promise.reject({
          logoutOnFailure: false,
          message: "Couldn't get /user/my/details!",
        });
      }

      await updateDictionaryContext();

      return Promise.resolve();
    },
  };
};
