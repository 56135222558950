import { Fragment, ReactElement, useCallback, useState } from 'react';
import { IconButton, styled, SxProps, Theme } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import { Confirm } from 'ra-ui-materialui';

type SignatureFieldProps = {
  value: string;
  label: string;
  onConfirm: React.Dispatch<React.SetStateAction<string>>;
  sx?: SxProps<Theme>;
  disabled?: boolean;
};

const StyledContainer = styled('div')(({ theme }) => ({
  color: theme.palette.error.main,
  border: '1px solid',
  borderColor: 'rgba(0, 0, 0, 0.23)',
  borderRadius: '4px',
  width: '100%',
  position: 'relative',

  '&:hover': {
    borderColor: '#241f1f',
  },
}));

const StyledLabel = styled('span')(() => ({
  color: '#241f1f',
  position: 'absolute',
  top: '-8px',
  left: '8px',
  background: '#fff',
  padding: '0 12px 0 6px',
  lineHeight: '1',
  fontSize: '1rem',
  transformOrigin: 'top left',
  scale: '0.75',
  fontFamily:
    'Hind, -apple-system, BlinkMacSystemFont, "Segoe UI", Arial, sans-serif',
}));

const StyledClearButton = styled(IconButton)(() => ({
  position: 'absolute',
  right: '0px',
  left: 'auto',
}));

const StyledImage = styled('img')(({}) => ({
  width: '100%',
  height: 'auto',
  maxWidth: '280px',
}));

const CONFIRM_TITLE = 'Are you sure you want to delete this signature?';
const CONFIRM_CONTENT = `You won't be able to take this action back.`;

const SignatureField = ({
  value,
  label,
  onConfirm,
  sx,
  disabled,
}: SignatureFieldProps): ReactElement => {
  const [isOpenDialog, setIsOpenDialog] = useState(false);

  const handleDelete = useCallback(async () => {
    onConfirm('');
    setIsOpenDialog(false);
  }, [onConfirm]);

  const handleDialogOpen = useCallback((event) => {
    event.stopPropagation();

    setIsOpenDialog(true);
  }, []);

  const handleDialogClose = useCallback(() => setIsOpenDialog(false), []);

  return (
    <Fragment>
      <StyledContainer sx={sx}>
        <StyledLabel>{label}</StyledLabel>
        <StyledClearButton
          aria-label="Clear input"
          onClick={handleDialogOpen}
          disabled={disabled}
        >
          <ClearIcon />
        </StyledClearButton>
        <StyledImage src={value} />
      </StyledContainer>
      <Confirm
        isOpen={isOpenDialog}
        title={CONFIRM_TITLE}
        content={CONFIRM_CONTENT}
        onConfirm={handleDelete}
        onClose={handleDialogClose}
      />
    </Fragment>
  );
};

export default SignatureField;
