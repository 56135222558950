import React, { Fragment, ReactElement } from 'react';
import { usePermissions, useResourceContext } from 'react-admin';
import {
  hasEditOrShowFieldAccess,
  hasFieldEditAccess,
} from '../../utils/UtilityFunctions';

interface FragmentWithCheckAccessProps {
  children: ReactElement | ReactElement[];
}

const FragmentWithCheckAccess: React.FC<FragmentWithCheckAccessProps> = ({
  children,
  ...rest
}): ReactElement => {
  const resource = useResourceContext();
  const { permissions } = usePermissions();

  const filteredChildren = React.Children.map(children, (child) => {
    const source = `${resource}.${child.props?.source}`;

    if (!source) {
      return child;
    }

    if (!hasEditOrShowFieldAccess(permissions, source)) {
      return null;
    }

    const updatedProps = {};
    const hasEditAccess = hasFieldEditAccess(permissions, source);

    ['disabled', 'readOnly'].forEach((prop) => {
      if (child.props.hasOwnProperty(prop)) {
        updatedProps[prop] = !hasEditAccess;
      }
    });

    return React.cloneElement(child, updatedProps);
  });

  return <Fragment {...rest}>{filteredChildren}</Fragment>;
};

export default FragmentWithCheckAccess;
