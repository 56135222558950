import { TextField } from '@mui/material';
import React, { ReactElement } from 'react';
import useDebounce from '../../../common/hooks/useDebounce';

interface Props {
  onSearch: (value: string) => void;
}

const SearchField: React.FC<Props> = ({ onSearch }): ReactElement => {
  const debounce = useDebounce();
  return (
    <TextField
      style={{ width: '340px' }}
      label="Search"
      id="search"
      name="search"
      onChange={(ev) => {
        debounce(() => {
          onSearch(ev.target.value);
        });
      }}
    />
  );
};

export default SearchField;
