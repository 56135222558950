import React, { useMemo, ReactElement, useState } from 'react';
import { DateInputProps, ValidationError } from 'react-admin';
import dayjs, { Dayjs } from 'dayjs';
import { DatePicker } from '@mui/x-date-pickers';
import { SxProps, Theme } from '@mui/material/styles';
import { getLocaleDateFormat } from '../../utils/UtilityFunctions';
import { stringToDate } from './helpers/dateFilter';
import { datePickerErrors } from './validators';

type DateValue = Dayjs | null;

type CustomDatePickerProps = Omit<DateInputProps, 'source'> & {
  label: string;
  value: DateValue;
  error?: boolean;
  helperText?: string;
  onChange: (value) => void;
  maxDate?: DateValue;
  minDate?: DateValue;
  disabled?: boolean;
  sx?: SxProps<Theme>;
  fullWidth?: boolean;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
};

const CustomDatePicker: React.FC<CustomDatePickerProps> = ({
  label,
  value,
  maxDate,
  minDate,
  onChange,
  error,
  helperText,
  disabled = false,
  isRequired,
  onClick,
  sx,
  fullWidth,
}): ReactElement => {
  const localeDateFormat = useMemo(getLocaleDateFormat, []);
  const [localError, setLocalError] = useState(null);

  const handleChange = (value, context) => {
    if (context?.validationError) {
      setLocalError(
        datePickerErrors({ error: context?.validationError, maxDate, minDate })
      );
    } else {
      setLocalError(null);
    }

    if (onChange) {
      onChange(value);
    }
  };

  const handlePaste = (event) => {
    event.preventDefault();
    const clipboardData = event?.clipboardData;
    const pastedText = clipboardData?.getData('Text');

    if (pastedText) {
      const parsedDate = stringToDate(pastedText, localeDateFormat);

      if (onChange) {
        onChange(dayjs(parsedDate));
      }
    }
  };

  return (
    <DatePicker
      label={isRequired ? label + '*' : label}
      slotProps={{
        field: {
          shouldRespectLeadingZeros: true,
          clearable: !disabled && true,
        },
        textField: {
          helperText: (
            <ValidationError error={helperText || localError || ''} />
          ),
          error: error || localError,
          placeholder: localeDateFormat,
          onPaste: handlePaste,
          fullWidth: fullWidth,
          onClick,
        },
      }}
      value={value ? dayjs(value) : value}
      format={localeDateFormat}
      onChange={handleChange}
      maxDate={maxDate}
      minDate={minDate}
      disabled={disabled}
      sx={sx}
    />
  );
};

CustomDatePicker.defaultProps = {
  // Max Date limit for Date input component for today() + 10Years
  maxDate: dayjs().add(10, 'y'),
};

export default CustomDatePicker;
