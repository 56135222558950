import { useCallback } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import {
  getContext,
  updateContext,
  UserContext,
} from '../../../provider/userContext';
import {
  updateDictionaryContext,
  updateLocalUISettings,
} from '../../../utils/UtilityFunctions';
import {
  RESOURCE_PROJECT,
  RESOURCE_STRUCTURE_ASSET,
  RESOURCE_STRUCTURE_ASSET_GROUP,
  RESOURCE_STRUCTURE_REGION,
} from '../../constants';

const useChangeCompany = () => {
  const ctx: UserContext = getContext();
  const queryClient = useQueryClient();

  return useCallback(
    async (newCompany: string) => {
      ctx.company = newCompany;
      ctx.defaultDivisionId = ctx?.companies[newCompany]?.defaultDivisionId;
      updateContext(ctx);
      await updateDictionaryContext();

      const uiSettings = ctx?.companies[newCompany]?.uiSettings;

      if (uiSettings) {
        updateLocalUISettings(uiSettings, true);
      } else {
        updateLocalUISettings('{}', true);
      }

      await Promise.all([
        queryClient.invalidateQueries({
          queryKey: [RESOURCE_PROJECT, 'getList'],
        }),
        queryClient.invalidateQueries({
          queryKey: [RESOURCE_STRUCTURE_ASSET, 'getList'],
        }),
        queryClient.invalidateQueries({
          queryKey: [RESOURCE_STRUCTURE_ASSET_GROUP, 'getList'],
        }),
        queryClient.invalidateQueries({
          queryKey: [RESOURCE_STRUCTURE_REGION, 'getList'],
        }),
      ]);
    },
    [ctx, queryClient]
  );
};

export default useChangeCompany;
