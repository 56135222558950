import { SxProps, Theme } from '@mui/material/styles';
import React, { ReactElement } from 'react';
import BaseBox from '@mui/material/Box';
import CustomDateInput from '../common/CustomDateInput';
import TextInput from './TextInput';
import commonStyles from './commonStyles';
import BoxWithCheckAccess from './BoxWithCheckAccess';

interface MetaDataInputsProps {
  source?: string;
  itemSx?: SxProps<Theme>;
  withCheckAccess?: boolean;
}

const MetaDataInputs: React.FC<MetaDataInputsProps> = ({
  source,
  itemSx,
  withCheckAccess = false,
}): ReactElement => {
  const Box = withCheckAccess ? BoxWithCheckAccess : BaseBox;

  return (
    <Box sx={commonStyles.flexBoxWithWrap}>
      <CustomDateInput
        source={`${source ? source + '.' : ''}updatedAt`}
        label="Updated At"
        sx={itemSx}
        disabled
      />
      <TextInput
        source={`${source ? source + '.' : ''}updatedBy`}
        label="Updated By"
        sx={itemSx}
        disabled
      />
      <TextInput
        source={`${source ? source + '.' : ''}updatedByName`}
        label="Updated By Name"
        sx={itemSx}
        disabled
      />
    </Box>
  );
};

MetaDataInputs.defaultProps = {
  source: '',
  itemSx: commonStyles.flexBoxItem,
};

export default MetaDataInputs;
