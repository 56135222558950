import { CRUD_URL, environment } from '../api';
import { TokenAuthProvider } from './providers/tokenAuthProvider';
import { AuthProviderWithRefresh } from './types';

export let authProvider: AuthProviderWithRefresh = undefined;

export const getAuthProvider = (
  getForceCognitoProvider?: boolean
): AuthProviderWithRefresh => {
  if (getForceCognitoProvider) {
    return getCognitoAuthProvider();
  }

  if (!authProvider) {
    authProvider = getCognitoAuthProvider();
  }

  return authProvider;
};

const getCognitoAuthProvider = (): AuthProviderWithRefresh => {
  let authProvider: AuthProviderWithRefresh = undefined;
  switch (environment) {
    case 'local':
      authProvider = TokenAuthProvider({
        clientId: '6vg5gb2dushi3iu3acvvp01eqv',
        hostedUIUrl: 'https://auth-v2.tracker-check-dev.trackersuite.co',
        redirect_uri: 'http://localhost:4000/token/exchange-code',
        scope: ['openid', 'email', 'profile'],
      });
      break;
    case 'dev':
      authProvider = TokenAuthProvider({
        clientId: '6vg5gb2dushi3iu3acvvp01eqv',
        hostedUIUrl: 'https://auth-v2.tracker-check-dev.trackersuite.co',
        redirect_uri: `${CRUD_URL}/token/exchange-code`,
        scope: ['openid', 'email', 'profile'],
      });
      break;
    case 'prod':
      authProvider = TokenAuthProvider({
        clientId: '6f1vdnuftu8fmqchgthppol556',
        hostedUIUrl: 'https://auth-v2.tracker-check.trackersuite.co',
        redirect_uri: `${CRUD_URL}/token/exchange-code`,
        scope: ['openid', 'email', 'profile'],
      });
      break;
    default:
      throw `Unknown environment:${environment}`;
  }

  return authProvider;
};
