import React, { ReactElement, useMemo, useState, useEffect } from 'react';
import { useGetOne, email } from 'react-admin';
import { useFormContext } from 'react-hook-form';
import { Box, Stack } from '@mui/material';
import dayjs from 'dayjs';
import { getContext, UserContext } from '../../provider/userContext';
import { required } from '../../utils/UtilityFunctions';
import CustomDateInput from '../common/CustomDateInput';
import useDebounce from '../common/hooks/useDebounce';
import ProjectInput from '../common/ProjectInput';
import commonStyles from '../common/commonStyles';
import TextInput from '../common/TextInput';
import { RESOURCE_USER } from '../constants';

const QRCodeGenerationForm: React.FC = (): ReactElement => {
  const { watch, setError, clearErrors, resetField } = useFormContext();
  const debounce = useDebounce(1500);
  const userEmail = watch('email');
  const ctx: UserContext = getContext();
  const [projectIds, setProjectIds] = useState([]);
  const [userId, setUserId] = useState(false);
  const projectInputRequestParams = useMemo(
    () => ({
      filter: {
        divisionId: ctx?.defaultDivisionId,
        isActive: true,
        id: projectIds,
      },
      sort: { field: 'project', order: 'ASC' as const },
    }),
    [ctx?.defaultDivisionId, projectIds]
  );
  const { data: user } = useGetOne(
    RESOURCE_USER,
    { id: userId },
    {
      enabled: !!userId,
      retry: false,
      onError: (error) => {
        setProjectIds([]);
        setError('email', {
          type: '',
          message: error?.message,
        });
      },
      onSuccess: () => {
        clearErrors('email');
        if (user?.companies?.[ctx.company]?.projectIds) {
          setProjectIds(user.companies[ctx.company].projectIds);
        } else {
          setProjectIds([]);
          setError('email', {
            type: '',
            message: `User ${user} doesn't have projects`,
          });
        }
      },
    }
  );

  useEffect(() => {
    resetField('projectId');
    if (!userEmail) {
      clearErrors('email');
    }

    debounce(() => {
      if (userEmail && !email()(userEmail)) {
        setUserId(userEmail);
      } else {
        setUserId(false);
      }
    });
  }, [userEmail, debounce, resetField, clearErrors]);

  return (
    <Box sx={{ ...commonStyles.flexBox, marginTop: '16px' }}>
      <Stack alignItems="flex-start">
        <Box sx={commonStyles.flexBox}>
          <TextInput
            label="Mobile user email"
            source="email"
            validate={[required(), email()]}
            sx={commonStyles.flexBoxItem}
          />
          <TextInput
            label="Mobile user password"
            source="password"
            type="password"
            validate={[required()]}
            sx={commonStyles.flexBoxItem}
          />
        </Box>
        <Box sx={commonStyles.flexBox}>
          <ProjectInput
            label="Select Project"
            source="projectId"
            isRequired
            requestParams={projectInputRequestParams}
            disabled={!userEmail}
            sx={commonStyles.flexBoxLongItem}
          />
        </Box>
        <Box sx={commonStyles.flexBox}>
          <TextInput
            label="First Name"
            source="firstName"
            sx={commonStyles.flexBoxItem}
          />
          <TextInput
            label="Last Name"
            source="lastName"
            sx={commonStyles.flexBoxItem}
          />
        </Box>
        <Box sx={commonStyles.flexBox}>
          <TextInput
            label="Company Name"
            source="companyName"
            sx={commonStyles.flexBoxItem}
          />
          <CustomDateInput
            label="Valid Until"
            source="validUntil"
            sx={commonStyles.flexBoxItem}
            minDate={dayjs()}
          />
        </Box>
      </Stack>
    </Box>
  );
};

export default QRCodeGenerationForm;
