import axios from 'axios';
import { getContext, UserContext } from './userContext';

export const fpmsLink =
  'https://bmc-fpms.rev1energy.com/FPMS_BMC/DataExchange_Testing.a5w';

type environmentType = 'local' | 'dev' | 'prod';
export const environment: environmentType = setEnvironment();

export function setEnvironment() {
  if (document.URL.includes('//tracker-check.')) {
    return 'prod';
  } else if (
    document.URL.includes('//localhost') ||
    document.URL.includes('//127.')
  ) {
    return 'local';
  } else {
    return 'dev';
  }
}

function getCRUDURL() {
  switch (environment) {
    case 'local':
      return 'http://localhost:4000';
    case 'dev':
      return 'https://api.tracker-check-dev.trackersuite.co';
    case 'prod':
      return 'https://api.tracker-check.trackersuite.co';
    default:
      throw `Unknown environment:${environment}`;
  }
}

function getCRUDAPIURL() {
  switch (environment) {
    case 'local':
      return 'http://localhost:4000/v1';
    case 'dev':
      return 'https://api.tracker-check-dev.trackersuite.co/v1';
    case 'prod':
      return 'https://api.tracker-check.trackersuite.co/v1';
    default:
      throw `Unknown environment:${environment}`;
  }
}

function getBookGenAPIURL() {
  switch (environment) {
    case 'local':
    case 'dev':
      return 'https://7eshjneyrj.execute-api.eu-west-1.amazonaws.com';
    case 'prod':
      return 'https://uu0ogkd29b.execute-api.us-east-2.amazonaws.com';
    default:
      throw `Unknown environment:${environment}`;
  }
}

function getUIURL() {
  switch (environment) {
    case 'local':
      return 'http://localhost:3000';
    case 'dev':
      return 'https://tracker-check-dev.trackersuite.co';
    case 'prod':
      return 'https://tracker-check.trackersuite.co';
    default:
      throw `Unknown environment:${environment}`;
  }
}

export const CRUD_URL = getCRUDURL();
export const CRUD_API_URL = getCRUDAPIURL();
export const BOOK_GEN_API = getBookGenAPIURL();
export const UI_URL = getUIURL();

export enum ContentType {
  json = 'application/json',
  formData = 'multipart/form-data',
  textPlan = 'text/plain',
}

export const getHeaders = () => {
  const ctx: UserContext = getContext();
  const headers = getRequestConfig();

  return {
    ...headers.headers,
    Accept: 'application/json',
    Division: ctx?.defaultDivisionId ? '' + ctx?.defaultDivisionId : '0',
    'Cache-Control': 'no-cache, no-store, must-revalidate',
    Pragma: 'no-cache',
    Expires: '0',
    Scope: 'web',
  };
};

export const getRequestConfig = (
  contentType: ContentType = ContentType.json
) => {
  const ctx: UserContext = getContext();
  const headers = {
    'Content-Type': contentType,
    Company: ctx?.company,
  };

  if (ctx?.projectId) {
    headers['Project'] = ctx.projectId.toString();
  }

  return {
    headers: {
      ...headers,
      Authorization: ctx?.token,
    },
  };
};

export const api = {
  user: {
    getMyDetails: () =>
      axios.get(`${CRUD_API_URL}/user/my/details`, getRequestConfig()),
  },
  token: {
    refreshToken: () =>
      axios.get(`${CRUD_URL}/token/refresh`, {
        ...getRequestConfig(),
        withCredentials: true,
      }),
    logout: () =>
      axios.get(`${CRUD_URL}/token/logout`, {
        ...getRequestConfig(),
        withCredentials: true,
      }),
  },
  dictionary: {
    getJson: (divisionId) =>
      axios.get(
        `${CRUD_API_URL}/dictionary/get-by-division/${divisionId}`,
        getRequestConfig()
      ),
  },
};
