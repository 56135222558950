import React, { isValidElement, ReactElement } from 'react';
import { Box, BoxProps } from '@mui/material';
import { usePermissions, useResourceContext } from 'react-admin';
import {
  hasEditOrShowFieldAccess,
  hasFieldEditAccess,
} from '../../utils/UtilityFunctions';

interface BoxWithCheckAccessProps extends BoxProps {
  children: ReactElement | ReactElement[];
}

const BoxWithCheckAccess: React.FC<BoxWithCheckAccessProps> = ({
  children,
  ...rest
}): ReactElement => {
  const resource = useResourceContext();
  const { permissions } = usePermissions();

  const filteredChildren = React.Children.map(children, (child) => {
    if (!isValidElement(child)) {
      return null;
    }

    const source = `${resource}.${(child as ReactElement)?.props?.source}`;

    if (!source || !hasEditOrShowFieldAccess(permissions, source)) {
      return null;
    }

    const readOnly = !hasFieldEditAccess(permissions, source);

    return React.cloneElement(child as ReactElement, { readOnly });
  });

  return <Box {...rest}>{filteredChildren}</Box>;
};

export default BoxWithCheckAccess;
