import React, { Fragment, ReactElement, useCallback, useState } from 'react';
import { BulkUpdateButton } from 'react-admin';
import { CircularProgress, Box } from '@mui/material';
import useInvalidateResourceQueryCache from './hooks/useInvalidateResourceQueryCache';
import useCanAccess from './hooks/useCanAccess';
import EditTitle from './EditTitle';

interface BulkActionButtonsProps {
  data: object;
  label: string;
  icon: ReactElement;
  resource: string;
  onUpdate?: () => void;
  confirmation?: string;
  disableAccessCheck?: boolean;
  resourceAction?: string;
}

const CustomBulkUpdateButton: React.FC<BulkActionButtonsProps> = ({
  data,
  label,
  icon,
  resource,
  onUpdate,
  confirmation,
  disableAccessCheck,
  resourceAction,
}): ReactElement => {
  const canAccessList = useCanAccess();
  const [isLoading, setIsLoading] = useState(false);
  const invalidateQueries = useInvalidateResourceQueryCache(resource, true);

  const clearCache = useCallback(async () => {
    await invalidateQueries();

    if (onUpdate) {
      onUpdate();
    }
  }, [invalidateQueries, onUpdate]);

  if (!canAccessList.edit && !disableAccessCheck) return;

  return (
    <BulkUpdateButton
      data={data}
      confirmTitle={
        <Fragment>
          Update <EditTitle />
        </Fragment>
      }
      confirmContent={
        <Fragment>
          {confirmation || (
            <Fragment>
              Are you sure you want to update this <EditTitle />?
            </Fragment>
          )}
          {isLoading && (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                marginTop: '20px',
              }}
            >
              <CircularProgress size={24} thickness={3} color="inherit" />
            </Box>
          )}
        </Fragment>
      }
      label={label}
      icon={icon}
      mutationMode="pessimistic"
      mutationOptions={{
        onSettled: async () => {
          setIsLoading(false);
          await clearCache();
        },
        onMutate: () => setIsLoading(true),
        ...(resourceAction && {
          meta: {
            action: resourceAction,
          },
        }),
      }}
    />
  );
};

export default CustomBulkUpdateButton;
