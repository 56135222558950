import React, { ReactElement } from 'react';
import { maxLength } from 'react-admin';
import Box from '@mui/material/Box';
import DisciplineInput from '../common/DisciplineInput';
import HistoryTab from '../common/HistoryTab';
import LazyFormTab from '../common/LazyFormTab';
import MetaDataInputs from '../common/MetaDataInputs';
import TabbedEditForm from '../common/TabbedEditForm';
import commonStyles from '../common/commonStyles';
import { validateLessThanValue } from '../common/validators';
import { EditPropsWithLocation } from '../common/types';
import TextInput from '../common/TextInput';
import CustomNumberInput from '../common/CustomNumberInput';
import { required } from '../../utils/UtilityFunctions';
import BoxWithCheckAccess from '../common/BoxWithCheckAccess';
import CurrencyInput from './components/CurrencyInput';
import { Position } from './types';

const PositionEdit: React.FC<EditPropsWithLocation<Position>> = (
  props
): ReactElement => {
  const { disableFieldsWithoutPermissionRules = false, ...rest } = props;

  return (
    <TabbedEditForm {...rest}>
      <LazyFormTab
        label="Position"
        id="position_tab"
        sx={commonStyles.formTabHeader}
      >
        <Box>
          <BoxWithCheckAccess sx={commonStyles.flexBox}>
            <TextInput
              source="position"
              label="Position"
              validate={[required(), maxLength(55)]}
              sx={commonStyles.flexBoxItem}
              readOnly={disableFieldsWithoutPermissionRules}
            />
            <TextInput
              source="description"
              label="Description"
              validate={[maxLength(100)]}
              sx={commonStyles.flexBoxItem}
              fullWidth
              multiline
              readOnly={disableFieldsWithoutPermissionRules}
            />
            <DisciplineInput readOnly={disableFieldsWithoutPermissionRules} />
          </BoxWithCheckAccess>
          <BoxWithCheckAccess sx={commonStyles.flexBox}>
            <CustomNumberInput
              source="billRate"
              label="Bill Rate"
              fractionDigits={2}
              isPositive
              sx={commonStyles.flexBoxItem}
              validate={[validateLessThanValue(1000)]} // billRate - DECIMAL(5,2)
            />
            <CurrencyInput />
          </BoxWithCheckAccess>
          <MetaDataInputs withCheckAccess />
        </Box>
      </LazyFormTab>
      <HistoryTab tableName="tbl_position" />
    </TabbedEditForm>
  );
};
export default PositionEdit;
