import React, { Fragment, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { CircularProgress } from '@mui/material';
import { Confirm } from 'react-admin';
import { getContext } from '../provider/userContext';
import useChangeProject from './project/hooks/useChangeProject';
import useChangeCompany from './project/hooks/useChangeCompany';

const SwitchProject: React.FC = (): React.JSX.Element => {
  const [switchingStatus, setSwitchingStatus] = useState<
    'NONE' | 'DISPLAYED' | 'IN-PROGRESS' | 'DONE'
  >('NONE');
  const context = getContext();
  const [searchParams] = useSearchParams();
  const changeProject = useChangeProject();
  const changeCompany = useChangeCompany();
  const navigate = useNavigate();

  const projectId = +searchParams.get('projectId');
  const company = searchParams.get('company');
  const redirect = searchParams.get('redirect');
  const needProjectChange = context.projectId !== projectId;
  const needCompanyChange = context.company !== company;
  const confirmTitle = `Confirm switch ${needCompanyChange ? 'Company and Project' : 'Project'}`;
  const confirmContent = `You are about to switch ${needCompanyChange ? 'the company and the project' : 'the project'}. All opened tabs will change too once you refresh them. Would you like to proceed?`;

  useEffect(() => {
    if (needProjectChange || needCompanyChange) {
      setSwitchingStatus('DISPLAYED');
    } else {
      document.location.href = redirect;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSwitch = async () => {
    try {
      setSwitchingStatus('IN-PROGRESS');
      await changeCompany(company);
      await changeProject(projectId);
      document.location.href = redirect;
    } finally {
      setSwitchingStatus('DONE');
    }
  };

  const handleCancel = () => {
    navigate('/');
  };

  return switchingStatus === 'DISPLAYED' ||
    switchingStatus === 'IN-PROGRESS' ? (
    <Confirm
      disableEscapeKeyDown
      isOpen={true}
      loading={switchingStatus === 'IN-PROGRESS'}
      title={confirmTitle}
      content={
        switchingStatus === 'IN-PROGRESS' ? (
          <div
            style={{ width: 550, display: 'flex', justifyContent: 'center' }}
          >
            <CircularProgress />
          </div>
        ) : (
          confirmContent
        )
      }
      onConfirm={handleSwitch}
      onClose={handleCancel}
      confirm="Switch"
    />
  ) : (
    <Fragment />
  );
};

export default SwitchProject;
