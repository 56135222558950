import React, { ReactElement, useCallback, useRef } from 'react';
import { useResourceContext, useRedirect } from 'react-admin';
import useInvalidateResourceQueryCache from '../common/hooks/useInvalidateResourceQueryCache';
import { RESOURCE_ATTACHMENT, RESOURCE_PROJECT } from '../constants';
import AttachmentFormTab from '../common/AttachmentFormTab';
import useMoveToTrash from '../common/hooks/useMoveToTrash';
import { FormDataWithFiles } from '../attachment/types';
import { EditPropsWithLocation } from '../common/types';
import { ATTACHMENT_TYPE_ENUMS } from '../common/enums';
import MetaDataInputs from '../common/MetaDataInputs';
import TabbedEditForm from '../common/TabbedEditForm';
import commonStyles from '../common/commonStyles';
import useUpload from '../common/hooks/useUpload';
import LazyFormTab from '../common/LazyFormTab';
import HistoryTab from '../common/HistoryTab';
import useRefreshCompanyContext from '../common/hooks/useRefreshCompanyContext';
import { Project } from './types';
import ProjectUsersTab from './components/ProjectUsersTab';
import ProjectFormProjectTab from './components/ProjectFormProjectTab';
import ProjectFormDetailsTab from './components/ProjectFormDetailsTab';
import ProjectFormItrAutomationTab from './components/ProjectFormItrAutomationTab';
import ProjectFormPresAutomationTab from './components/ProjectFormPresAutomationTab';
import ProjectFormEnforceCertGatingTab from './components/ProjectFormEnforceCertGatingTab';
import ProjectFormInheritCertSignOffsTab from './components/ProjectFormInheritCertSignOffsTab';
import canUserAccessProject from './helpers/canUserAccessProject';

const ProjectEdit: React.FC<EditPropsWithLocation<Project>> = (
  props
): ReactElement => {
  const { upload } = useUpload({});
  const { moveToTrashFile } = useMoveToTrash();
  const redirect = useRedirect();
  const resource = useResourceContext();
  const clearCache = useInvalidateResourceQueryCache(resource, true);
  const refreshCompanyContext = useRefreshCompanyContext();
  const disableSaveSuccess = useRef(false);
  const oldAttachmentId = useRef<number>(null);
  const newAttachment = useRef<FormDataWithFiles>(null);

  const transform = (
    data: Project,
    options: { previousData: Project }
  ): Project => {
    if (data?.attachment?.rawFile) {
      newAttachment.current = {
        files: [data.attachment.rawFile as File],
        attachmentType: ATTACHMENT_TYPE_ENUMS.LOGO,
        projectId: String(data.id),
        refProjectId: String(data.id),
        resourceName: resource,
      };
    }

    if (
      options?.previousData?.attachment &&
      (data?.attachment?.rawFile || !data?.attachment)
    ) {
      oldAttachmentId.current = Number(options.previousData.attachment.id);
    }

    return data;
  };

  const onSuccess = useCallback(
    async (record?) => {
      const isCurrentUserProject = canUserAccessProject(record?.id);

      if (!disableSaveSuccess.current) {
        disableSaveSuccess.current = true;
        if (newAttachment.current) {
          await upload(RESOURCE_ATTACHMENT, newAttachment.current);
          newAttachment.current = null;
        }
        if (oldAttachmentId.current) {
          await moveToTrashFile(RESOURCE_ATTACHMENT, {
            id: oldAttachmentId.current,
          });
          oldAttachmentId.current = null;
        }

        if (isCurrentUserProject) {
          await refreshCompanyContext();
        }

        await clearCache();

        disableSaveSuccess.current = false;
      }
    },
    [clearCache, upload, moveToTrashFile, refreshCompanyContext]
  );

  const onSaveSuccess = useCallback(
    async (record) => {
      await onSuccess(record);
      redirect('list', RESOURCE_PROJECT);
    },
    [onSuccess, redirect]
  );

  return (
    <TabbedEditForm
      {...props}
      transform={transform}
      mutationOptions={{ onSuccess: onSaveSuccess }}
      onAutoSaveSuccess={onSuccess}
    >
      <LazyFormTab
        label="Project"
        id="project_tab"
        sx={commonStyles.formTabHeader}
      >
        <ProjectFormProjectTab />
        <MetaDataInputs />
      </LazyFormTab>
      <LazyFormTab
        label="Details"
        id="details_tab"
        sx={commonStyles.formTabHeader}
      >
        <ProjectFormDetailsTab />
      </LazyFormTab>
      <LazyFormTab
        label="Inherit Cert Sign Offs"
        id="inherit_cert_sigh_offs_tab"
        sx={commonStyles.formTabHeader}
      >
        <ProjectFormInheritCertSignOffsTab />
      </LazyFormTab>
      <LazyFormTab
        label="Enforce Cert Gating"
        id="enforce_cert_gating_tab"
        sx={commonStyles.formTabHeader}
      >
        <ProjectFormEnforceCertGatingTab />
      </LazyFormTab>
      <LazyFormTab
        label="ITR Automation"
        id="itr_automation_tab"
        sx={commonStyles.formTabHeader}
      >
        <ProjectFormItrAutomationTab />
      </LazyFormTab>
      <LazyFormTab
        label="Pres Automation"
        id="pres_automation_tab"
        sx={commonStyles.formTabHeader}
      >
        <ProjectFormPresAutomationTab />
      </LazyFormTab>
      <LazyFormTab label="Users" id="users_tab" sx={commonStyles.formTabHeader}>
        <ProjectUsersTab />
      </LazyFormTab>
      <LazyFormTab
        label="Attachment"
        id="attachment_tab"
        sx={commonStyles.formTabHeader}
      >
        <AttachmentFormTab
          targetId="refProjectId"
          resourceName={RESOURCE_PROJECT}
        />
      </LazyFormTab>
      <HistoryTab tableName="tbl_project" />
    </TabbedEditForm>
  );
};

export default ProjectEdit;
