import React, { ReactElement } from 'react';
import { TabbedShowLayout, TextField } from 'react-admin';
import Box from '@mui/material/Box';
import renderShowMetaDataFields from '../common/helpers/renderShowMetaDataFields';
import ShowComponent, { ShowComponentProps } from '../common/ShowComponent';
import DisciplineField from '../common/DisciplineField';
import commonStyles from '../common/commonStyles';
import AuditListTab from '../audit/AuditListTab';
import LabeledWithCheckAccess from '../common/LabeledWithCheckAccess';
import CurrencyField from './components/CurrencyField';

const PositionShow: React.FC<ShowComponentProps> = ({
  syncWithLocation,
  ...rest
}): ReactElement => (
  <ShowComponent {...rest}>
    <TabbedShowLayout
      sx={commonStyles.bottomMargin}
      syncWithLocation={syncWithLocation}
    >
      <TabbedShowLayout.Tab label="Position">
        <Box>
          <LabeledWithCheckAccess>
            <TextField
              source="position"
              label="Position"
              sx={commonStyles.flexBoxItem}
            />
          </LabeledWithCheckAccess>
          <LabeledWithCheckAccess>
            <TextField
              source="description"
              label="Description"
              sx={commonStyles.flexBoxItem}
            />
          </LabeledWithCheckAccess>
          <LabeledWithCheckAccess sx={commonStyles.flexBoxItem}>
            <DisciplineField source="disciplineId" />
          </LabeledWithCheckAccess>
        </Box>
        <Box>
          <LabeledWithCheckAccess sx={commonStyles.flexBoxItem}>
            <TextField source="billRate" />
          </LabeledWithCheckAccess>
          <LabeledWithCheckAccess sx={commonStyles.flexBoxItem}>
            <CurrencyField />
          </LabeledWithCheckAccess>
        </Box>
        <Box>{renderShowMetaDataFields(true)}</Box>
      </TabbedShowLayout.Tab>
      <TabbedShowLayout.Tab label="History">
        <AuditListTab tableName="tbl_position" />
      </TabbedShowLayout.Tab>
    </TabbedShowLayout>
  </ShowComponent>
);

export default PositionShow;
