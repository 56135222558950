import { Typography } from '@mui/material';
import clsx from 'clsx';
import React, { ReactElement } from 'react';
import { Button, TopToolbar } from 'react-admin';
import { RED } from '../../../../theme/colors';

export interface Props {
  onBulkSave: () => void;
  onUndo: () => void;
  onRedo: () => void;
  onDiscard: () => void;
  visible: boolean;
}

const EditModeToolbar: React.FC<Props> = ({
  onBulkSave,
  onUndo,
  onRedo,
  onDiscard,
  visible,
}): ReactElement => {
  return (
    <TopToolbar
      className={clsx('gantt-chart-edit-toolbar', visible ? 'visible' : '')}
    >
      <Typography align="left" width="100%" color={RED}>
        There are unsaved changes.
      </Typography>
      <Button label="Undo" onClick={onUndo} />
      <Button label="Redo" onClick={onRedo} />
      <Button label="Discard All" onClick={onDiscard} style={{ color: RED }} />
      <Button label="Save" onClick={onBulkSave} />
    </TopToolbar>
  );
};

export default EditModeToolbar;
