import React, { ReactElement, useCallback } from 'react';
import {
  DeleteWithConfirmButton,
  useGetOne,
  useNotify,
  useResourceContext,
} from 'react-admin';

interface Props {
  recordId?: number;
  onSuccess: () => void;
}

const DeleteButton: React.FC<Props> = ({
  recordId,
  onSuccess: onSuccessProps,
}): ReactElement => {
  const resource = useResourceContext();
  const { data: record } = useGetOne(
    resource,
    { id: recordId },
    { enabled: !!recordId }
  );

  const notify = useNotify();
  const onSuccess = useCallback(() => {
    onSuccessProps();
    notify('Element deleted');
  }, [notify, onSuccessProps]);

  if (!recordId) return null;

  return (
    <DeleteWithConfirmButton
      record={record}
      id={record?.id}
      icon={null}
      label="Delete"
      confirmTitle="Delete Activity"
      redirect={null}
      mutationOptions={{ onSuccess }}
    />
  );
};

export default DeleteButton;
