import React, { Fragment, ReactElement } from 'react';
import BulkEditButton from '../../common/BulkEditButton';
import useCanAccess from '../../common/hooks/useCanAccess';
import { RESOURCE_TEST_EQUIPMENT_CHECKOUT } from '../../constants';
import TestEquipmentCheckoutForm from './TestEquipmentCheckoutForm';

const BulkActionButtons: React.FC = (): ReactElement => {
  const canAccessList = useCanAccess();

  if (!canAccessList.edit) return;

  return (
    <Fragment>
      <BulkEditButton
        resource={RESOURCE_TEST_EQUIPMENT_CHECKOUT}
        form={<TestEquipmentCheckoutForm bulkEditMode />}
      />
    </Fragment>
  );
};

export default BulkActionButtons;
