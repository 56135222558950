import { RowForm } from '@react-admin/ra-editable-datagrid';
import React, { ReactElement, useCallback, useState } from 'react';
import { DateField, maxLength, useRecordContext } from 'react-admin';
import {
  required,
  useDictionaryLabelsWithResource,
} from '../../../../../utils/UtilityFunctions';
import ComponentField from '../../../../common/ComponentField';
import { ContactCategories } from '../../../../common/contactInput/constants';
import ContactInput from '../../../../common/contactInput/ContactInput';
import CustomDateInput from '../../../../common/CustomDateInput';
import commonStyles from '../../../../common/commonStyles';
import CompletedInput from '../../../../preservationItemActivity/components/CompletedInput';
import { CompletedType } from '../../../../preservationItemActivity/constants';
import { FormRowProps } from '../../../types';
import FileNameField from '../components/FileNameField';

const PreservationFormRow: React.FC<FormRowProps> = ({
  data,
}): ReactElement => {
  const { labels } = useDictionaryLabelsWithResource();
  const record = useRecordContext();

  const [isRequired, setIsRequired] = useState<boolean>(
    record?.completed === CompletedType.YES
  );

  const handleCompletedInputChange = useCallback((event) => {
    if (event?.target?.value === CompletedType.YES) {
      setIsRequired(true);
    } else {
      setIsRequired(false);
    }
  }, []);

  return (
    <RowForm>
      <FileNameField data={data} />
      <ContactInput
        source="inspector"
        label={labels['inspector'] || 'Inspector: Signature'}
        sx={commonStyles.rowFormInput}
        validate={[maxLength(75), isRequired && required()]}
        category={ContactCategories.preservationEmpWorkGrp}
      />
      <CustomDateInput
        source="signOffDate"
        label={labels['signOffDate'] || 'Sign Off Date'}
        sx={commonStyles.rowFormInput}
        isRequired={isRequired}
      />
      <CompletedInput
        source="completed"
        label={labels['completed'] || 'Status'}
        sx={commonStyles.rowFormInput}
        onChange={handleCompletedInputChange}
      />
      <ComponentField
        source="component.id"
        label={labels['tagNo'] || 'Tag No'}
      />
      <DateField source="dueDate" label={labels['dueDate'] || 'Due Date'} />
      <DateField source="lateDate" label={labels['lateDate'] || 'Late Date'} />
    </RowForm>
  );
};

export default PreservationFormRow;
