import React, { Fragment, ReactElement, useCallback } from 'react';
import { GanttApi } from '../gantt/types';
import EditModeToolbar from './EditModeToolbar';
import ViewModeToolbar from './ViewModeToolbar';

interface Props {
  ganttApi: GanttApi;
  containsChanges: boolean;
  selectedId: number;
  onCleanState: () => void;
  onBulkSave: () => void;
}

const GanttChartToolbar: React.FC<Props> = ({
  ganttApi,
  containsChanges,
  selectedId,
  onCleanState,
  onBulkSave,
}): ReactElement => {
  const onExportToPdf = useCallback(() => {
    ganttApi.pdfExport();
  }, [ganttApi]);

  const onSearch = useCallback(
    (value: string) => {
      ganttApi.search(value);
    },
    [ganttApi]
  );

  const onUndo = useCallback(() => {
    ganttApi.undo();

    if (!ganttApi.containsChanges()) {
      onCleanState();
    }
  }, [ganttApi, onCleanState]);

  const onRedo = useCallback(() => {
    ganttApi.redo();
  }, [ganttApi]);

  const onDiscard = useCallback(() => {
    ganttApi.discardAll();

    if (!ganttApi.containsChanges()) {
      onCleanState();
    }
  }, [ganttApi, onCleanState]);

  return (
    <Fragment>
      <ViewModeToolbar
        selectedId={selectedId}
        onExportToPdf={onExportToPdf}
        onSearch={onSearch}
        onSelectedSuccess={onCleanState}
      />
      <EditModeToolbar
        visible={containsChanges}
        onUndo={onUndo}
        onRedo={onRedo}
        onDiscard={onDiscard}
        onBulkSave={onBulkSave}
      />
    </Fragment>
  );
};

export default GanttChartToolbar;
