import { GanttModel } from '@syncfusion/ej2-gantt/src/gantt/base/gantt-model';
import {
  Edit,
  Filter,
  PdfExport,
  PdfExportProperties,
  RowDD,
  Selection,
  UndoRedo,
} from '@syncfusion/ej2-react-gantt';

// Key lives here https://www.syncfusion.com/account/claim-license-key
export const LICENSE_KEY =
  'Ngo9BigBOggjHTQxAR8/V1NMaF5cXmBCf1FpRmJGdld5fUVHYVZUTXxaS00DNHVRdkdmWX5ccXRUQmdcVUd0WUo=';

// All properties are described here https://helpej2.syncfusion.com/react/documentation/api/gantt/#properties
export const ganttChartProps: Partial<GanttModel> = {
  /*
  'taskFields' - instruction for the ej2 'GanttComponent' where to get required values from the objects
  that are represent tasks (see './types.ts#Task') and are passed to the component via 'dataSource' prop
  */
  taskFields: {
    id: 'id',
    name: 'name',
    startDate: 'startDate',
    endDate: 'endDate',
    duration: 'duration',
    durationUnit: 'durationUnit',
    progress: 'progress',
    dependency: 'dependency',
    parentID: 'parentID',
  },

  labelSettings: {
    rightLabel: 'name',
    taskLabel: 'progress',
  },
  splitterSettings: {
    columnIndex: 4,
  },
  gridLines: 'Horizontal',
  allowSelection: true,
  allowPdfExport: true,
  allowFiltering: true,
  treeColumnIndex: 1,
  autoFocusTasks: true,
  searchSettings: {
    fields: ['name'],
    ignoreCase: true,
  },
};

// this is just an edit specific props extraction
export const ganttChartEditProps: Partial<GanttModel> = {
  editSettings: {
    allowEditing: true,
    allowTaskbarEditing: true,
  },
  allowRowDragAndDrop: true,
  enableUndoRedo: true,
  undoRedoActions: ['Edit', 'RowDragAndDrop'],
  undoRedoStepsCount: 50,
};

export const ganttChartServices = [
  Selection,
  Edit,
  PdfExport,
  Filter,
  UndoRedo,
  RowDD,
];

export const exportProps: PdfExportProperties = {
  enableFooter: false,
  enableHeader: false,
  pageOrientation: 'Landscape',
  pageSize: 'A1',
  fitToWidthSettings: {
    isFitToWidth: true,
  },
};
